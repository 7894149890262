import { FC, PropsWithChildren } from 'react';

/**
 * 与えられたinterface(type)のうち一部のみをPartialにするやつ。
 * <Message, 'id' | 'poster'> のように使う。
 */
export type PartiallyPartial<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

/**
 * ```
 * const Direction = stringToEnum([
 *  'North', 'South', 'East', 'West'
 * ]);
 *
 * keyofでDirectionのキーを抽出して
 * typeofでUnion Typeを生成する
 * type Direction = keyof typeof Direction;
 * let direction: Direction;
 *
 * direction = Direction.North;
 * direction = 'North'; // Works!!
 * direction = 'AnythingElse'; // Error
 * ```
 * @param o enumにしたいstring配列
 */
export const stringToEnum = <T extends string>(
  o: readonly T[],
): { [K in T]: K } => {
  return o.reduce((accumulator, currentValue) => {
    accumulator[currentValue] = currentValue;

    return accumulator;
  }, Object.create(null));
};

// Custom Type for a React functional component with props AND CHILDREN
export type FCC<P = {}> = FC<PropsWithChildren<P>>;
