import { Configuration } from '@ct-sdk/configuration';
import {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
} from 'axios';
import { nanoid } from 'nanoid';

import { myAuth } from '@client/helpers/myAuth';

import { TokenError } from './customErrors/TokenError';

export type ResponseAPI<Body = unknown, Header = unknown> =
  | {
      isSuccess: true;
      body: Body; // これbodyがないとき、body: undefinedって指定しないといけないので不便。なにか他に方法ないのか。
      header: Header;
    }
  | {
      isSuccess: false;
      error: string;
      notify: '<=500' | '<=400' | '401' | 'token' | 'network' | 'unknown';
    };

const isAxiosError = (error: any): error is AxiosError => {
  return !!error.isAxiosError;
};

/**
 * axiosのエラーハンドリングとデフォルトの設定をやるヘルパー関数。
 * @param request 引数1requestIdは自動生成id。引数2defaultConfigはbaseURLとアクセストークンの設定
 * @param defaultErrorText
 * @returns
 * @example
 * // axios単体で使う
 * requestAxios(
 *   () =>
 *     axios.post<LineTokenResponse>(
 *       'https://api.line.me/oauth2/v2.1/token',
 *       new URLSearchParams({
 *         grant_type: 'authorization_code',
 *         code,
 *         client_id: process.env.NX_LINE_CLIENT_ID || '',
 *         client_secret: process.env.NX_LINE_AUTH_SECRET || '',
 *         redirect_uri: `${location.protocol}//${location.host}/line-callback`,
 *       }).toString(),
 *       {
 *         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
 *       },
 *     ),
 *   'LINEのトークン取得に失敗しました!',
 * );
 *
 * // openapi generator の sdkと一緒に使う
 * const kasesApi = new CasesApi();
 *
 * requestAxios(
 *  (requestId, defaultConfig) =>
 *    kasesApi.kaseControllerSearch(
 *      requestId,
 *      query.keyword ?? undefined,
 *      query.participantRoles ?? undefined,
 *      query.statuses ?? undefined,
 *      query.offset ?? undefined,
 *      query.limit ?? undefined,
 *      defaultConfig,
 *    ),
 *  'ケース一覧の取得に失敗しました!',
 *);
 */
export const requestAxios = async <T = unknown>(
  request: (
    requestId: string,
    defaultConfig: AxiosRequestConfig,
  ) => Promise<AxiosResponse<T>>,
  defaultErrorText: string,
  isSkipToSetToken?: boolean,
): Promise<ResponseAPI<T, Record<string, string>>> => {
  try {
    const accessToken = isSkipToSetToken ? '' : await myAuth.getAccessToken();
    const defaultRequestId = nanoid();
    const defaultConfig = {
      headers: isSkipToSetToken
        ? undefined
        : {
            Authorization: `Bearer ${accessToken}`,
          },
      // 残念ながら url が絶対パスでaxios.requestすると basePathが無視されるみたい。
      // openapi generator で生成されたSDKはurlに絶対パスを渡すため、この設定は無視される。
      // baseURLの代わりにbasePathをconfigurationから渡すこと。
      baseURL: process.env.NX_API_URL,
    };

    const res = await request(defaultRequestId, defaultConfig);
    return {
      isSuccess: true,
      body: res.data,
      header: res.headers,
    };
  } catch (err) {
    console.error(err);
    if (isAxiosError(err)) {
      if (err.response) {
        if (err.response.status >= 500) {
          return {
            isSuccess: false,
            error: `${defaultErrorText} サーバーエラーです。しばらく経ってからもう一度試してみてください。`,
            notify: '<=500',
          };
        }

        if (err.response.status === 401) {
          return {
            isSuccess: false,
            error: `セッションエラーです。ログインし直してください。`,
            notify: '401',
          };
        }

        if (err.response.status >= 400) {
          return {
            isSuccess: false,
            error:
              err.response.data?.message ??
              `${defaultErrorText} リクエストエラーです。`,
            notify: '<=400',
          };
        }
      } else if (err.request) {
        return {
          isSuccess: false,
          error: `${defaultErrorText} リクエストがタイムアウトしました。`,
          notify: '<=400',
        };
      } else {
        return {
          isSuccess: false,
          error: `${defaultErrorText} 原因不明のエラーが発生しました。`,
          notify: 'unknown',
        };
      }
    }

    if (err instanceof TokenError) {
      return {
        isSuccess: false,
        error: 'セッションの期限切れです。ログインし直してください。',
        notify: 'token',
      };
    }

    return {
      isSuccess: false,
      error: `${defaultErrorText} ネットワークエラーです。`,
      notify: 'network',
    };
  }
};

export const openApiGeneratorConfiguration = new Configuration({
  basePath: process.env.NX_API_URL,
});
