
import { Route } from 'react-router-dom';

type PublicRouteProps = {
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  path?: string | string[]; // どのルートでもない場合 NoRouteだとpathはundefined
};

// ログインが不要なRoute
export const PublicRoute: React.FC<PublicRouteProps> = ({
  component: ReactComponent,
  ...rest
}) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={(props) => <ReactComponent {...props} />} />;
};
