import { Comment as Branch, PointComment as Point } from '@ct-sdk/api';

import { stringToEnum } from '@client/helpers/typeutil';

export type {
  TrunkComment as Trunk,
  PointComment as Point,
  HideComment,
  ModelFile as FileInfo,
  Reaction,
  Comment as Branch,
  Comment,
  RawComment,
  CloseComment,
  ApplyComment,
  DirectMessage,
  KaseMember,
  CurrentUser,
  DmUser,
  Account,
  UserAdminQuery as PrivateUser,
  NewUser,
  NewKase,
  KaseDescription as Description,
  EvaluatedKase as KaseNecessary,
  KaseChallengerQuery as KaseQueryByChallenger,
  KaseAdminQuery as KaseQueryByAdmin,
  KaseDetail,
  KaseClose,
  NewKaseClose,
  MePerformance,
  MeEvaluation,
  Reflection as MeReflection,
  Question,
  EvaluationMemo,
  CiteComment,
  EvaluationThemeMemo as ThemeMemo,
  OwnerEvaluation,
  Notification,
  CommentCreatedAt as CommentCreate,
  PaymentCurrent as PaymentCurrentInformation,
  PaymentHistory,
} from '@ct-sdk/api';

/* ---------------------------------- コメント ---------------------------------- */
// export type Trunk = components['schemas']['TrunkComment'];
// export type Point = components['schemas']['PointComment'];
// export type PointDto = components['schemas']['PointComment'];
// export type HideComment = components['schemas']['HideComment'];
// export type HideCommentDto = components['schemas']['HideComment'];
// export type FileInfo = components['schemas']['File'];
// export type Reaction = components['schemas']['Reaction'];
// export type Branch = components['schemas']['Comment'];
// export type Comment = components['schemas']['Comment'];
// export type RawComment = components['schemas']['RawComment'];

// export type CloseComment = components['schemas']['CloseComment'];
// export type ApplyComment = components['schemas']['ApplyComment'];

/* ------ DM ----- */
// export type DirectMessage = components['schemas']['DirectMessage'];

/* ------ ユーザー -------- */

// export type KaseMember = components['schemas']['KaseMember'];
// export type CurrentUser = components['schemas']['CurrentUser'];
// export type DmUser = components['schemas']['DmUser'];
// export type Account = components['schemas']['Account'];
// export type PrivateUser = components['schemas']['UserAdminQuery'];
// export type NewUser = components['schemas']['NewUser'];

export const UserStatusList2 = [
  'INIT',
  'ONBOARDING',
  'SUSPENDING',
  'ACTIVE',
  'LEFT',
] as const;
export const UserStatus2 = stringToEnum(UserStatusList2);
export type UserStatus2 = keyof typeof UserStatus2;

export const AccountPermissionList = [
  'CHALLENGER',
  'SENIOR_CHALLENGER',
  'ADMIN',
] as const;
export const AccountPermission = stringToEnum(AccountPermissionList);
export type AccountPermission = keyof typeof AccountPermission;

export const ReviewStatusList = [
  'UNDER_REVIEW',
  'APPROVED',
  'REJECTED',
] as const;
export const ReviewStatus = stringToEnum(ReviewStatusList);
export type ReviewStatus = keyof typeof ReviewStatus;

/* ---------------------------------- ケース ---------------------------------- */
// export type NewKase = components['schemas']['NewKase'];
// export type Description = components['schemas']['KaseDescription'];
// export type KaseNecessary = components['schemas']['EvaluatedKase'];
// export type KaseQueryByChallenger =
//   components['schemas']['KaseChallengerQuery'];
// export type KaseQueryByAdmin = components['schemas']['KaseAdminQuery'];
// export type KaseDetail = components['schemas']['KaseDetail'];
// export type KaseClose = components['schemas']['KaseClose'];
// export type NewKaseClose = components['schemas']['NewKaseClose'];

export const KaseStatusList = [
  'DRAFT',
  'APPLYING',
  'OPEN',
  'CLOSE_APPLYING',
  'CLOSED',
  'COOL_DOWN',
  'LANTER_WANTED',
  'OWNER_GONE',
  'PENDING',
] as const;
export const KaseStatus = stringToEnum(KaseStatusList);
export type KaseStatus = keyof typeof KaseStatus;

export const ParticipantRoleList = [
  'OWNER',
  'SUPPORTER',
  'WATCHER',
  'LANTER',
  'MANAGER',
  'GUEST',
] as const;
export const ParticipantRole = stringToEnum(ParticipantRoleList);
export type ParticipantRole = keyof typeof ParticipantRole;

/* ---------------------------------- Me ---------------------------------- */
// export type MePerformance = components['schemas']['MePerformance'];
// export type MeEvaluation = components['schemas']['MeEvaluation'];
// export type MeReflection = components['schemas']['Reflection'];
// export type Question = components['schemas']['Question'];
/* ---------------------------------- オーナー評価 ---------------------------------- */
// export type EvaluationMemo = components['schemas']['EvaluationMemo'];
// export type CiteComment = components['schemas']['CiteComment'];
// export type ThemeMemo = components['schemas']['EvaluationThemeMemo'];
// export type OwnerEvaluation = components['schemas']['OwnerEvaluation'];
export const EvaluationTheme = stringToEnum([
  'PCentered',
  'TBased',
  'CBased',
  'Icf',
  'TalCycle',
]);
export type EvaluationTheme = keyof typeof EvaluationTheme;

/* ---------------------------------- 通知 ---------------------------------- */
// export type Notification = components['schemas']['Notification'];

/* ---------------------------------- お問い合わせ ---------------------------------- */
export const ContactUsType = stringToEnum([
  'ABOUT_SERVICE',
  'OTHERS',
  'CANCEL_MEMBERSHIP',
  'CONSULTATION',
]);
export type ContactUsType = keyof typeof ContactUsType;

/* -------- チュートリアル ------- */
export type TutorialOrdinaryComment = Branch & {
  type?: 'normal';
};

export type TutorialInputComment = Branch & {
  type: 'input';
  withPoints: Point[];
};

export type TutorialOutputComment = Branch & {
  type: 'output';
};

export type TutorialChoices = {
  first: {
    label: string;
    appearComment: TutorialOrdinaryComment;
  };
  second: {
    label: string;
    appearComment: TutorialOrdinaryComment;
  };
};

export type TutorialChoiceComment = Branch & {
  type: 'choice';
  choices: TutorialChoices;
};

export type TutorialGlossaryComment = {
  type: 'glossary';
  glossaryId: number;
  trunkCreatedAt: string;
  createdAt: string;
};

export type TutorialGlossary = {
  id: number;
  word: string;
  explain: string;
};

export type TutorialEndComment = {
  type: 'end';
  trunkCreatedAt: string;
  createdAt: string;
};

export type TutorialBranch =
  | TutorialOrdinaryComment
  | TutorialChoiceComment
  | TutorialGlossaryComment
  | TutorialEndComment
  | TutorialInputComment
  | TutorialOutputComment;

export type TutorialTrunk =
  | (TutorialOrdinaryComment & { type?: 'normal'; branch: TutorialBranch[] })
  | Exclude<TutorialBranch, TutorialOrdinaryComment>;

export type PendingFile = {
  rawFile: File;
  id: string;
  dataUrl: string;
  height: number;
  width: number;
  orientation?: number;
  // id: number;
};

export type PresignedUrl = {
  url: string;
  key: string;
};

export interface DraftFile {
  file: PendingFile;
  orientation?: number;
  dataUrl: string;
  width: number;
  height: number;
}

// export type CommentCreate = components['schemas']['CommentCreatedAt'];

/* ---------------------------------- 支払い情報 ---------------------------------- */
// export type PaymentCurrentInformation = components['schemas']['PaymentCurrent'];
// export type PaymentHistory = components['schemas']['PaymentHistory'];

/* ------------------------ */
/*         フロント独自の型         */
/* ------------------------ */
export type KaseAllowActions = {
  unreads: boolean;
  favorites: boolean;
  detail: boolean;
  members: boolean;
  watch: boolean;
  manage: boolean;
  close: boolean;
  evaluationMemoList: boolean;
  ownerEvaluation: boolean;
};

export type CommentAllowFunctions = {
  reply: boolean;
  edit: boolean;
  delete: boolean;
  stamp: boolean;
  favorite: boolean;
  adminLike: boolean;
  good: boolean;
  think: boolean;
  backGood: boolean;
  backThink: boolean;
  evaluationMemo: boolean;
  hideComment: boolean;
  quote: boolean;
};

export type PaymentIntentStatus =
  | 'canceled'
  | 'processing'
  | 'requires_action'
  | 'requires_capture'
  | 'requires_confirmation'
  | 'requires_payment_method'
  | 'succeeded';
