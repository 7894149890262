/* eslint-disable class-methods-use-this */
import { AccountsApi } from '@ct-sdk/api';

import {
  ResponseAPI,
  openApiGeneratorConfiguration,
  requestAxios,
} from '@client/helpers/axiosHandler';
import { MeEvaluation, MeReflection } from '@client/helpers/types';

import { CurrentUser } from '../types';

const accountsApi = new AccountsApi(openApiGeneratorConfiguration);

export class MeRequest {
  static fetch(): Promise<ResponseAPI<CurrentUser>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.meControllerShowCurrentUser(requestId, defaultConfig),
      'ユーザー情報の取得に失敗しました！!',
    );
  }

  static fetchEvaluations(): Promise<ResponseAPI<MeEvaluation>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.meControllerIndexEvaluations(requestId, defaultConfig),
      'オーナー評価取得に失敗しました!',
    );
  }

  static fetchReflection(): Promise<ResponseAPI<MeReflection | null>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.meControllerShowReflection(requestId, defaultConfig),
      '振り返りの取得に失敗しました!',
    );
  }

  /**
   * @deprecated 現状pending
   * @param args
   * @returns
   */
  static saveReflection(args: {
    reflectionId: number;
    kaseId: number;
    notShownOnNextLogin: boolean;
    questions: {
      questionId: number;
      answer: string;
    }[];
  }): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.meControllerCreateReflectionAnswer(
          args,
          requestId,
          defaultConfig,
        ),
      '振り返りの保存に失敗しました!',
    );
  }
}
