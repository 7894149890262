import { History } from 'history';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

export const useHistoryListenEffect = (history: History<unknown>): void => {
  useEffect(() => {
    // NOTE: service workerをページを移動するたびにupdateチェックする。
    // https://stackoverflow.com/questions/51748241/checking-for-service-worker-updates-in-a-single-page-app
    // https://github.com/rahuls360/testing-sw/blob/master/src/App.js
    history.listen(() => {
      // check for sw updates on page change
      if(navigator.serviceWorker){
        navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => registrations.forEach((reg) => reg.update()));
      }
    });

    // Initialize google analytics page view tracking
    history.listen((thisLocation) => {
      if (process.env.NX_USE_GOOGLE_ANALYTICS === 'true') {
        ReactGA.set({ page: thisLocation.pathname }); // Update the user's current pagea
        ReactGA.pageview(thisLocation.pathname); // Record a pageview for the given page
      }
    });
  }, [history]);
};
