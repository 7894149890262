import { VFC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ReviewStatus, UserStatus2 } from '@client/helpers/types';

type PrivateRouteProps = {
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  authenticated: boolean;
  userStatus: UserStatus2;
  path: string | string[];
  locationPath?: string;
  reviewStatus?: ReviewStatus;
}; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * 役割
 * - ステータスがinvalidであれば、該当のページへリダイレクト
 * - ログインしてなければログインページヘリダイレクト
 */
export const PrivateRoute: VFC<PrivateRouteProps> = ({
  component: ReactComponent,
  authenticated,
  userStatus,
  exact,
  path,
  reviewStatus,
}) => {
  if (path !== '/signUp' && userStatus === 'INIT') {
    return <Redirect to="/signUp" />;
  }
  if (path !== '/suspending' && userStatus === 'SUSPENDING') {
    return <Redirect to="/suspending" />;
  }
  if (reviewStatus == ReviewStatus.REJECTED) {
    return <Redirect to="/rejected" />;
  }
  if (
    path !== '/tutorial-mypage' &&
    path !== '/tutorial' &&
    path !== '/register-card' &&
    path !== '/dm/:id' &&
    userStatus === 'ONBOARDING'
  ) {
    return <Redirect to="/tutorial-mypage" />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) =>
        authenticated ? (
          <ReactComponent />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};
