import { Logger } from '@aws-amplify/core';
import axios from 'axios';

import { TokenError } from '@client/helpers/customErrors/TokenError';

export const ACCESS_TOKEN_KEY = 'club-trape-access-token';
export const REFRESH_TOKEN_KEY = 'club-trape-refresh-token';

const logger = new Logger('MyAuth');

type Token = {
  exp: number;
  userId: number;
  googleSub: string | null;
  lineSub: string | null;
  cognitoSub: string | null;
};

type RefreshApiResponse = {
  accessToken: string;
  refreshToken: string;
};

export class MyAuth {
  // TODO: refresh tokenを連続で呼ばないようにする処理。
  // private isRequestingRefreshToken = false;

  // private memoryAccessToken: string | null = null;

  // private memoryRefreshToken: string | null = null;

  // constructor() {}

  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  parseJwt(token: string): Token {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  isTokenExpired(tokenExpireDate: number): boolean {
    const currentTimeSeconds = Math.round(+new Date() / 1000);

    return tokenExpireDate <= currentTimeSeconds;
  }

  async refreshAccessToken(refreshToken: string): Promise<string> {
    // if (isRequestingRefreshToken) return;
    try {
      const res = await axios.post<RefreshApiResponse>(
        `${process.env.NX_API_URL}/user-auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        },
      );

      if (res.status === 200) {
        this.setAccessToken(res.data.accessToken);
        this.setRefreshToken(res.data.refreshToken);

        return res.data.accessToken;
      }
    } catch (error) {
      logger.error(`Cannot refresh access token!`, error);
    }
    throw new TokenError();
  }

  isValidSession(): boolean {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!refreshToken) {
      return false;
    }

    if (this.isTokenExpired(this.parseJwt(refreshToken).exp)) {
      return false;
    }

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return false;
    }

    return true;
  }

  async getRefreshToken(): Promise<string> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!refreshToken) {
      logger.info('RefreshToken is not stored in local storage!');
      throw new TokenError();
    }

    if (this.isTokenExpired(this.parseJwt(refreshToken).exp)) {
      logger.info('RefreshToken is expired!');
      throw new TokenError();
    }

    return refreshToken;
  }

  async getAccessToken(): Promise<string> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (!accessToken) {
      logger.warn('AccessToken is not stored in local storage!');
      throw new TokenError();
    }

    if (this.isTokenExpired(this.parseJwt(accessToken).exp)) {
      const refreshToken = await this.getRefreshToken();

      return this.refreshAccessToken(refreshToken);
    }

    return accessToken;
  }

  setAccessToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  deleteTokens(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
}

export const myAuth = new MyAuth();
