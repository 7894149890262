import { Logger } from '@aws-amplify/core';
import { useCallback, useEffect, useState } from 'react';

import { MeRequest } from '@client/helpers/requests/meRequest';
import { MeReflection } from '@client/helpers/types';

const logger = new Logger('useMeReflectionQuery');

export const useMeReflectionQuery = (): {
  reflection: MeReflection | null;
  isLoading: boolean;
  refetch: () => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [reflection, setReflection] = useState<MeReflection | null>(null);

  const fetch = useCallback(async () => {
    setIsLoading(true);
    const res = await MeRequest.fetchReflection();
    setIsLoading(false);
    if (!res.isSuccess) {
      logger.error(`振り返りの取得に失敗しました!`);

      return;
    }

    setReflection(res.body);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { reflection, isLoading, refetch: fetch };
};
