/* eslint-disable class-methods-use-this */
import { Auth as CognitoAuth } from '@aws-amplify/auth';
import { I18n, Logger } from '@aws-amplify/core';
import { AccountsApi } from '@ct-sdk/api';
import axios from 'axios';

import {
  ResponseAPI,
  openApiGeneratorConfiguration,
  requestAxios,
} from '@client/helpers/axiosHandler';

import { myAuth } from '../myAuth';

const accountsApi = new AccountsApi(openApiGeneratorConfiguration);
const logger = new Logger('AuthRequest');

// @aws-amplify/auth/src/types/CognitoAuth.ts を参照すると、別の
// 型定義でエラーが起きるため、同じものをここに転機して使用する
export enum AuthProvider {
  Cognito = 'cognito',
  Google = 'google',
  Line = 'access.line.me',
}

type LoginAuthResponse = {
  accessToken: string;
  refreshToken: string;
};

// ドキュメント
// https://aws-amplify.github.io/amplify-js/api/classes/authclass.html

export class AuthRequest {
  static async validateUserSession(): Promise<ResponseAPI> {
    try {
      if (myAuth.isValidSession()) {
        return {
          isSuccess: false,
          error: 'セッションが無効です',
          notify: 'token',
        };
      }

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `セッションが無効です。 詳細: リクエストエラー raw: ${String(err)}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || 'セッションが無効です。',
          notify: 'token',
        };
      }

      return {
        isSuccess: false,
        error: 'セッションが無効です。',
        notify: 'token',
      };
    }
  }

  static async signUp({
    sirName,
    givenName,
    email,
    phoneNumber,
    affiliation,
    motivation,
    jobDescription,
    occupation,
    state,
    avatarUrl,
  }: {
    sirName: string;
    givenName: string;
    email: string;
    phoneNumber: string;
    affiliation: string;
    motivation: string;
    jobDescription: string;
    occupation: string;
    state: string;
    password: string;
    avatarUrl: string | null;
  }): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.newUserControllerCreate(
          {
            sirName,
            givenName,
            email,
            phoneNumber,
            affiliation,
            motivation,
            jobDescription,
            occupation,
            state,
            avatarUrl,
          },
          requestId,
          defaultConfig,
        ),
      '登録処理に失敗しました!',
    );
  }

  static async signUpToCognito({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<ResponseAPI<{ userSub: string }>> {
    try {
      const ret = await CognitoAuth.signUp({ password, username: email });
      // console.log('CognitoAuth.signUp');
      // console.log(ret);

      return {
        isSuccess: true,
        body: ret,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `サインアップに失敗しました。 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || 'サインアップに失敗しました。',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'サインアップに失敗しました。',
        notify: '<=400',
      };
    }
  }

  static async confirmSignUpToCognito({
    username,
    code,
  }: {
    username: string;
    code: string;
  }): Promise<ResponseAPI> {
    try {
      await CognitoAuth.confirmSignUp(username, code);

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `メールアドレスの検証に失敗しました。 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error:
            I18n.get(err.message) || 'メールアドレスの検証に失敗しました。',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'メールアドレスの検証に失敗しました。',
        notify: '<=400',
      };
    }
  }

  static async loginWithGoogle(token: string): Promise<ResponseAPI> {
    logger.debug(token);

    const ret = await requestAxios(
      (requestId) =>
        accountsApi.userAuthControllerLoginWithGoogle(
          {
            sub: '',
          },
          requestId,
          {
            baseURL: process.env.NX_API_URL,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      'Google認証に失敗しました!',
      true,
    );

    if (ret.isSuccess) {
      myAuth.setAccessToken(ret.body.accessToken);
      myAuth.setRefreshToken(ret.body.refreshToken);
    }
    return ret;
  }

  static async connectWithGoogle(sub: string): Promise<ResponseAPI> {
    const ret = await requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.userAuthControllerConnectWithGoogle(
          {
            sub,
          },
          requestId,
          defaultConfig,
        ),
      'Googleのログイン連携に失敗しました!',
    );

    return ret;
  }

  static async loginWithLine(token: string): Promise<ResponseAPI> {
    logger.debug(token);

    const ret = await requestAxios(
      (requestId) =>
        accountsApi.userAuthControllerLoginWithLine(
          {
            sub: '',
          },
          requestId,
          {
            baseURL: process.env.NX_API_URL,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      'LINE検証に失敗しました!',
      true,
    );

    if (ret.isSuccess) {
      myAuth.setAccessToken(ret.body.accessToken);
      myAuth.setRefreshToken(ret.body.refreshToken);
    }
    return ret;
  }

  static async connectWithLine(sub: string): Promise<ResponseAPI> {
    const ret = await requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.userAuthControllerConnectWithLine(
          {
            sub,
          },
          requestId,
          defaultConfig,
        ),
      'LINEのログイン連携に失敗しました!',
    );

    return ret;
  }

  static async loginWithCognito(token: string): Promise<ResponseAPI> {
    logger.debug(token);

    const ret = await requestAxios(
      (requestId) =>
        accountsApi.userAuthControllerLoginWithCognito(
          {
            sub: '',
          },
          requestId,
          {
            baseURL: process.env.NX_API_URL,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
      'メール検証に失敗しました!',
      true,
    );

    if (ret.isSuccess) {
      myAuth.setAccessToken(ret.body.accessToken);
      myAuth.setRefreshToken(ret.body.refreshToken);
    }
    return ret;
  }

  static async connectWithCognito(sub: string): Promise<ResponseAPI> {
    const ret = await requestAxios(
      (requestId, defaultConfig) =>
        accountsApi.userAuthControllerConnectWithCognito(
          {
            sub,
          },
          requestId,
          defaultConfig,
        ),
      'ID/PASSのログイン連携に失敗しました!',
    );

    return ret;
  }

  /**
   * 開発サーバーがNODE_ENV=developのときのみ有効なAPI。問答無用でtoken返してくれる。
   * @param token
   * @returns
   */
  static async loginWithE2E(sub: string): Promise<ResponseAPI> {
    const ret = await requestAxios(
      (requestId) =>
        accountsApi.userAuthControllerLoginWithE2E(
          {
            sub,
          },
          requestId,
          {
            // baseURL: process.env.NX_API_URL, // これ結局内部でハードコーディングされてるbasePathで書き換えられる
            headers: {
              Authorization: `Bearer ${process.env.NX_LOGIN_WITH_E2E_TOKEN}`,
            },
            url: 'hoge',
          },
        ),
      'E2E検証に失敗しました!',
      true,
    );

    if (ret.isSuccess) {
      myAuth.setAccessToken(ret.body.accessToken);
      myAuth.setRefreshToken(ret.body.refreshToken);
    }
    return ret;
  }

  static async signIn({
    userName,
    password,
  }: {
    userName: string;
    password: string;
  }): Promise<ResponseAPI<{ challenge?: 'NEW_PASSWORD_REQUIRED' }>> {
    try {
      const data = await CognitoAuth.signIn(userName, password);

      // signInの返り値
      // 1. MFA認証が必要 challengeName MFA_REQUIRED or SMS_MFA
      // 2. 新しいパスワードが必要 challengeName NEW_PASSWORD_REQUIRED
      // 3. その他 認証ユーザー
      // NOTE: ２段階認証は設定していないのでMFA_REQUIREDとSMS_MFAがくるのはありえないはず

      if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.info('Login success but new password required!');
        // TODO: 新規パスワード作成要求

        return {
          isSuccess: true,
          body: {
            challenge: 'NEW_PASSWORD_REQUIRED',
          },
          header: undefined,
        };
      }

      if (
        data.challengeName === 'MFA_REQUIRED' ||
        data.challengeName === 'SMS_MFA'
      ) {
        logger.warn('２段階認証が必要な設定になっています');
      }

      logger.info('Login success!');

      return {
        isSuccess: true,
        body: {
          challenge: undefined,
        },
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `サインインに失敗しました！ 詳細: リクエストエラー raw: ${String(err)}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || 'サインインに失敗しました！',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'サインインに失敗しました！',
        notify: '<=400',
      };
    }
  }

  static async logout(onResetCurrentUser: () => void): Promise<ResponseAPI> {
    try {
      await CognitoAuth.signOut();
      onResetCurrentUser();
      myAuth.deleteTokens();

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `ログアウトに失敗しました！ 詳細: リクエストエラー raw: ${String(err)}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || 'ログアウトに失敗しました！',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'ログアウトに失敗しました！',
        notify: '<=400',
      };
    }
  }

  static async setNewPassword({
    userName,
    password,
    newPassword,
  }: {
    userName: string;
    password: string;
    newPassword: string;
  }): Promise<ResponseAPI> {
    try {
      const user = await CognitoAuth.signIn(userName, password);

      // completeNewPasswordの返り値
      // 1. MFA認証が必要な場合 SMS_MFA
      // 2. その他 認証済みユーザー
      const data = await CognitoAuth.completeNewPassword(user, newPassword, {});

      if (
        data.challengeName === 'MFA_REQUIRED' ||
        data.challengeName === 'SMS_MFA'
      ) {
        logger.warn('２段階認証が必要な設定になっています');
      }

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `新規パスワードの設定に失敗しました 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || '新規パスワードの設定に失敗しました',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: '新規パスワードの設定に失敗しました',
        notify: '<=400',
      };
    }
  }

  static async forgotPassword({
    userName,
  }: {
    userName: string;
  }): Promise<ResponseAPI> {
    try {
      await CognitoAuth.forgotPassword(userName);

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `リセットコードの送信に失敗しました！ 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error:
            I18n.get(err.message) || 'リセットコードの送信に失敗しました！',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'リセットコードの送信に失敗しました！',
        notify: '<=400',
      };
    }
  }

  static async confirmForgotPassword({
    userName,
    code,
    newPassword,
  }: {
    userName: string;
    code: string;
    newPassword: string;
  }): Promise<ResponseAPI> {
    try {
      await CognitoAuth.forgotPasswordSubmit(userName, code, newPassword);

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `パスワードリセットに失敗しました！ 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error: I18n.get(err.message) || 'パスワードリセットに失敗しました！',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'パスワードリセットに失敗しました！',
        notify: '<=400',
      };
    }
  }

  static async changeEmail({ email }: { email: string }): Promise<ResponseAPI> {
    try {
      const user = await CognitoAuth.currentAuthenticatedUser();
      // NOTE: デフォルトの挙動だと検証する前にメールアドレスを変更してしまうので、
      // その対応としてcustome:verified_emailに古いメアドを指定する。詳しくは以下のリンクを参照。
      // https://zenn.dev/dove/articles/78ecf08b51ee0c
      await CognitoAuth.updateUserAttributes(user, {
        email,
        'custom:verified_email': user.attributes.email,
      });

      return {
        isSuccess: true,
        body: undefined,
        header: undefined,
      };
    } catch (err) {
      logger.error(
        `メールアドレス更新リクエストに失敗しました。 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        return {
          isSuccess: false,
          error:
            I18n.get(err.message) ||
            'メールアドレス更新リクエストに失敗しました。',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'メールアドレス更新リクエストに失敗しました。',
        notify: '<=400',
      };
    }
  }

  static async verifyEmail({
    code,
    email,
  }: {
    code: string;
    email: string;
  }): Promise<ResponseAPI> {
    try {
      const user = await CognitoAuth.currentAuthenticatedUser();
      const result = await CognitoAuth.verifyUserAttributeSubmit(
        user,
        'email',
        code,
      );
      if (result === 'SUCCESS') {
        // NOTE: デフォルトの挙動だと検証する前にメールアドレスを変更してしまうので、
        // その対応としてcustome:verified_emailに古いメアドを指定する。詳しくは以下のリンクを参照。
        // https://zenn.dev/dove/articles/78ecf08b51ee0c

        await CognitoAuth.updateUserAttributes(user, {
          email, // ここと
          'custom:verified_email': email, // ここに新しいメールアドレスをいれる。
        });

        // リクエストに成功した。
        return {
          isSuccess: true,
          body: undefined,
          header: undefined,
        };
      }

      return {
        isSuccess: false,
        error: 'メールアドレス変更に失敗しました。 担当者にご連絡ください。',
        notify: '<=400',
      };
    } catch (err) {
      logger.error(
        `メールアドレス変更に失敗しました。 詳細: リクエストエラー raw: ${String(
          err,
        )}`,
      );

      if (err instanceof Error) {
        // cognitoが検証コードを発行してしまわないように、故意にエラーを発生させている。
        // そのエラーであれば無視する。
        // この設計あんまり良くないけど、いまのところcognitoの問題が解決するまでこれでいく。
        if (
          err.message ===
          'CustomMessage failed with error SKIP_VERIFICATION_CODE.'
        ) {
          return {
            isSuccess: true,
            body: undefined,
            header: undefined,
          };
        }

        return {
          isSuccess: false,
          error:
            I18n.get(err.message) ||
            'メールアドレス変更に失敗しました。 担当者にご連絡ください。',
          notify: '<=400',
        };
      }

      return {
        isSuccess: false,
        error: 'メールアドレス変更に失敗しました。 担当者にご連絡ください。',
        notify: '<=400',
      };
    }
  }
}
