/* eslint-disable class-methods-use-this */
import { EventsApi } from '@ct-sdk/api';

import {
  ResponseAPI,
  openApiGeneratorConfiguration,
  requestAxios,
} from '@client/helpers/axiosHandler';

const eventsApi = new EventsApi(openApiGeneratorConfiguration);

export class EventRequest {
  static finishTutorial(): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        eventsApi.frontEventControllerFinishTutorial(requestId, defaultConfig),
      'メッセージ投稿に失敗しました!',
    );
  }

  static seeDmEvent(): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        eventsApi.frontEventControllerSeeDm(requestId, defaultConfig),
      'DM閲覧イベントの記録に失敗しました。!',
    );
  }

  static seeDmForAdminEvent({
    userId,
  }: {
    userId: number;
  }): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        eventsApi.frontEventControllerSeeDmForAdmin(
          userId,
          requestId,
          defaultConfig,
        ),
      'DM閲覧イベントの記録に失敗しました!',
    );
  }

  static readNotifications(): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        eventsApi.frontEventControllerReadNotifications(
          requestId,
          defaultConfig,
        ),
      '通知カウンタのリセットに失敗しました!',
    );
  }
}
