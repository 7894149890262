
import { Spinner } from 'reactstrap';

import { FadeIn } from '@client/components/designSystems//animation/FadeIn/FadeIn';

import classes from './Loading.module.scss';

export const Loading: React.FC = () => {
  return (
    <FadeIn className={classes.Loading}>
      <div className="d-flex align-items-center">
        <Spinner type="grow" />
      </div>
    </FadeIn>
  );
};
