import React, {
  ErrorInfo,
  PropsWithChildren,
  PureComponent,
  ReactNode,
} from 'react';
import { Alert } from 'reactstrap';

type StatusMessages = { [status: number]: string };
type Props = { statusMessages?: StatusMessages };
type State = { hasError: boolean; error: Error | null };
export class ErrorBoundary extends PureComponent<
  PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  // 非同期のエラーキャッチ。
  // componentDidMount() {
  //   window.addEventListener('unhandledrejection', this.onUnhandledRejection);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
  // }

  // onUnhandledRejection = (event: PromiseRejectionEvent) => {
  //   event.promise.catch((error) => {
  //     this.setState(ErrorBoundary.getDerivedStateFromError(error));
  //   });
  // };

  static getDerivedStateFromError = (error: Error): State => ({
    hasError: true,
    error,
  });

  componentDidCatch = (error: Error, info: ErrorInfo): void => {
    console.error(error, info); // eslint-disable-line no-console
  };

  render = (): ReactNode => {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <Alert color="danger">
          サーバエラーです。画面をリロードしても改善されない場合は担当者までご連絡ください。
          <br /> エラー詳細メッセージ: {error?.message || 'なし'}
        </Alert>
      );
    }

    return children;
  };
}
