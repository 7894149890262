import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { QuoteState } from '@client/store/quote/types';

export const initialState: QuoteState = {
  ready: false,
  from: '',
  content: '',
  commentId: '',
  point: '',
  trunkCreatedAt: '',
};
const quoteSlice = createSlice({
  name: '@@quote',
  initialState,
  reducers: {
    updateQuote: (state, action: PayloadAction<QuoteState>) => {
      return action.payload;
    },
  },
});

export const { updateQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
