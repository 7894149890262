import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Media } from 'reactstrap';

import logo from '@client/assets/img/brand/ClubTRAPE_logo.png';
import { getCurrentUser } from '@client/store/currentUser/selector';

import classes from './Prototype.module.scss';

if (process.env.NX_LOGROCKET === 'on') {
  // TODO: 環境変数化
  LogRocket.init('5axmmy/club-trape');
  setupLogRocketReact(LogRocket);
}

type PrototypeProps = React.HTMLAttributes<HTMLDivElement>;

export const Prototype: React.FC<PrototypeProps> = ({ children }) => {
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser.id !== 0) {
      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        id: currentUser.id,
        name: currentUser.fullName,
      });
    }
  }, [currentUser]);

  return (
    <div className={classes.Prototype}>
      <div className={classes.Navbar}>
        <Container>
          <nav className={classes.Nav}>
            <div>
              <span className="mr-1">
                <Link className={classes.Brand} to="/">
                  <img src={logo} alt="logo" width="30" height="30" />
                </Link>
              </span>
              <Media className="ml-1 d-none d-lg-inline">
                <span className="mb-0 text-sm font-weight-bold">
                  プロトタイプ
                </span>
              </Media>
            </div>
            <div>
              <Link to="/prototypes">
                <Button size="sm" color="secondary">
                  プロトタイプ一覧へ戻る
                </Button>
              </Link>
              <Link to="/" className="ml-3 mt-1">
                <Button size="sm" color="secondary">
                  ケース一覧へ戻る
                </Button>
              </Link>
            </div>
          </nav>
        </Container>
      </div>
      <div>{children}</div>
    </div>
  );
};
