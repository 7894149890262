/* eslint-disable class-methods-use-this */
import {
  CasesApi,
  NewKaseCreateRequestBody,
  NewKaseUpdateRequestBody,
} from '@ct-sdk/api';

import {
  ResponseAPI,
  openApiGeneratorConfiguration,
  requestAxios,
} from '@client/helpers/axiosHandler';
import {
  EvaluationMemo,
  KaseDetail,
  KaseQueryByChallenger,
  OwnerEvaluation,
  ParticipantRole,
} from '@client/helpers/types';

const kasesApi = new CasesApi(openApiGeneratorConfiguration);

export class KaseRequest {
  static async search(query: {
    participantRoles?: Exclude<ParticipantRole, 'GUEST'>[];
    keyword: string | null;
    statuses:
      | (
          | 'DRAFT'
          | 'APPLYING'
          | 'LANTER_WANTED'
          | 'OPEN'
          | 'CLOSE_APPLYING'
          | 'PENDING'
          | 'CLOSED'
          | 'COOL_DOWN'
          | 'OWNER_GONE'
        )[]
      | null;
    offset: number | null;
    limit: number | null;
  }): Promise<ResponseAPI<KaseQueryByChallenger[], { total: number }>> {
    const res = await requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseControllerSearch(
          requestId,
          query.keyword ?? undefined,
          query.participantRoles ?? undefined,
          query.statuses ?? undefined,
          query.offset?.toString() ?? undefined,
          query.limit?.toString() ?? undefined,
          defaultConfig,
        ),
      'ケース一覧の取得に失敗しました!',
    );

    if (!res.isSuccess) return res;

    return {
      isSuccess: true,
      body: res.body,
      header: {
        total: Number.parseInt(res.header['x-total-count'], 10),
      },
    };
  }

  static fetchKaseUnreadCounts({ kaseIds }: { kaseIds: number[] }) {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseUnreadCountControllerGetKaseUnreadCounts(
          kaseIds.map((kaseId) => kaseId.toString()),
          requestId,
          defaultConfig,
        ),
      'ケースの未読情報取得に失敗しました!',
    );
  }

  static fetchKaseDetail({
    kaseId,
  }: {
    kaseId: number;
  }): Promise<ResponseAPI<KaseDetail>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseControllerShow(kaseId, requestId, defaultConfig),
      'ケース情報の取得に失敗しました!',
    );
  }

  static fetchKaseDetailCc(): Promise<ResponseAPI<KaseDetail>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseControllerShowCc(requestId, defaultConfig),
      'ケース情報の取得に失敗しました!',
    );
  }
  static fetchKaseDetailSaseboCommunity(): Promise<ResponseAPI<KaseDetail>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseControllerShowSaseboCommunity(requestId, defaultConfig),
      'ケース情報の取得に失敗しました!',
    );
  }
  static fetchKaseDetailDevelopClub(): Promise<ResponseAPI<KaseDetail>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.kaseControllerShowDevelopClub(requestId, defaultConfig),
      'ケース情報の取得に失敗しました!',
    );
  }

  static apply(body: NewKaseCreateRequestBody): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.newKaseControllerCreate(body, requestId, defaultConfig),
      'ケース申請に失敗しました！!',
    );
  }

  static applyAgain(
    body: NewKaseUpdateRequestBody & { kaseId: number },
  ): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.newKaseControllerUpdate(
          body.kaseId,
          body,
          requestId,
          defaultConfig,
        ),
      'ケース申請に失敗しました!',
    );
  }

  static participate({
    kaseId,
    type,
  }: {
    kaseId: number;
    type: Exclude<ParticipantRole, 'GUEST' | 'OWNER'>;
  }): Promise<ResponseAPI> {
    const notifyComment: {
      [key in Exclude<ParticipantRole, 'GUEST' | 'OWNER'>]: string;
    } = {
      WATCHER: 'ウォッチ',
      SUPPORTER: 'サポーター',
      LANTER: 'ランター',
      MANAGER: 'マネージャー',
    };

    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.participantListsControllerRegister(
          kaseId,
          { type },
          requestId,
          defaultConfig,
        ),
      `${notifyComment[type]}登録に失敗しました！`,
    );
  }

  static updateOwnerEvaluations({
    kaseId,
    ownerEvaluations,
  }: {
    kaseId: number;
    ownerEvaluations: {
      Icf: {
        item1: boolean;
        item2: boolean;
        item3: boolean;
      };
      CBased: {
        item1: boolean;
        item2: boolean;
        item3: boolean;
      };
      PCentered: {
        item1: boolean;
        item2: boolean;
        item3: boolean;
      };
      TBased: {
        item1: boolean;
        item2: boolean;
        item3: boolean;
      };
      TalCycle: {
        item1: boolean;
        item2: boolean;
        item3: boolean;
      };
    };
  }): Promise<ResponseAPI> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.ownerEvaluationsControllerCreate(
          kaseId,
          ownerEvaluations,
          requestId,
          defaultConfig,
        ),
      'オーナー評価の更新に失敗しました！',
    );
  }

  static fetchOwnerEvaluations({
    kaseId,
  }: {
    kaseId: number;
  }): Promise<ResponseAPI<OwnerEvaluation | null>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.ownerEvaluationsControllerIndex(
          kaseId,
          requestId,
          defaultConfig,
        ),
      'オーナー評価情報の取得に失敗しました！',
    );
  }

  static fetchAllEvaluationMemos({
    kaseId,
  }: {
    kaseId: number;
  }): Promise<ResponseAPI<EvaluationMemo[]>> {
    return requestAxios(
      (requestId, defaultConfig) =>
        kasesApi.evaluationMemoControllerIndex(
          kaseId,
          requestId,
          defaultConfig,
        ),
      '評価メモ一覧の取得に失敗しました！',
    );
  }
}
