
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import legoData from '@client/assets/lotties/legoloading.json';
import { FadeIn } from '@client/components/designSystems/animation/FadeIn/FadeIn';
import { getLoading } from '@client/store/loadingOverlay/selector';

import classes from './LoadingOverlay.module.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LoadingOverlay: React.FC = () => {
  const { texts, showLoadingProgress } = useSelector(getLoading);
  if (!showLoadingProgress) return null;

  return (
    <FadeIn className={classes.overlay}>
      <div>
        {texts.map((display) => (
          <div
            className="d-flex align-items-center"
            key={display.id.toString()}
          >
            <h1 className="text-white">{display.text}</h1>
            <div className="m-0">
              <Lottie options={defaultOptions} height={120} width={120} />
            </div>
          </div>
        ))}
      </div>
    </FadeIn>
  );
};
