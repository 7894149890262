/* eslint-disable class-methods-use-this */
import { NotificationsApi } from '@ct-sdk/api';

import {
  ResponseAPI,
  openApiGeneratorConfiguration,
  requestAxios,
} from '@client/helpers/axiosHandler';
import { Notification } from '@client/helpers/types';

const notificationsApi = new NotificationsApi(openApiGeneratorConfiguration);
export class NotificationRequest {
  static fetch(): Promise<
    ResponseAPI<{ notifications: Notification[]; newCount: number }>
  > {
    return requestAxios(
      (requestId, defaultConfig) =>
        notificationsApi.notificationControllerIndex(requestId, defaultConfig),
      '通知取得に失敗しました!',
    );
  }
}
