/* eslint-disable jsx-a11y/label-has-associated-control */
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import { ReflectionPrompt } from '@client/components/recipes/kaseIndex/ReflectionPrompt/ReflectionPrompt';
import { useKaseQuery } from '@client/helpers/hooks/useKaseQuery';
import { useMeReflectionQuery } from '@client/helpers/hooks/useMeReflectionQuery';
import { KaseStatus, ParticipantRole } from '@client/helpers/types';

import { Prototype } from './Prototype';
import classes from './Reflection.module.scss';

type KaseIndexParams = {
  myrole?: Exclude<ParticipantRole, 'GUEST'>;
  open?: string;
  keyword?: string;
  offset?: string;
  limit?: string;
  currentPage?: string;
};

const convertParamsStringToNumber = (param?: string): number | undefined => {
  if (!param) return undefined;

  const paramNum = Number.parseInt(param, 10);
  if (Number.isNaN(paramNum)) return undefined;

  return paramNum;
};

const defaultStatusesWhenUndefined = (param?: string): KaseStatus[] => {
  if (param && param === 'false') {
    return ['CLOSED', 'OWNER_GONE'];
  }

  return ['OPEN', 'CLOSE_APPLYING', 'COOL_DOWN'];
};

/**
 * 役割:
 * - ケースを検索できる。
 * - ケース一覧を表示する。 -> KaseListへ委譲
 * - ケースを選択すると、ケース画面に移動する。 -> KaseListへ委譲
 * - 振り返り表示
 */
export const PrototypeReflection: React.FC = () => {
  /* ------------------------ */
  /*            共通            */
  /* ------------------------ */
  const location = useLocation();

  /* ------------------------ */
  /*   ケース一覧表示&ケース検索   */
  /* ------------------------ */
  // NOTE: 検索は基本的に
  // 1.urlにpush
  // 2.urlの変更を検知してurlからparamsを取得しfetch
  // という流れで行われる。
  // この方針をとった理由としては、検索条件を内部stateではなくurlに保持することによって、リンクでの共有が可能になるから。
  const PAGE_UNIT = 20;

  const params = queryString.parse(location.search) as KaseIndexParams;

  const { kases } = useKaseQuery({
    myRole: params.myrole,
    statuses: defaultStatusesWhenUndefined(params.open),
    keyword: params.keyword,
    currentPage: convertParamsStringToNumber(params.currentPage),
    pageUnit: PAGE_UNIT,
  });

  // URLが変更されると(つまり検索条件が変更すると)、ページの最初までスクロール
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [kases]);

  /* ------------------------ */
  /*  振り返り表示  */
  /* ------------------------ */

  const { reflection, refetch } = useMeReflectionQuery();
  const onSavedReflection = () => {
    refetch();
  };

  return (
    <Prototype>
      <Container>
        <h2>振り返り機能</h2>
        <div className={classes.FloatTrappy}>
          <ReflectionPrompt
            reflection={reflection}
            onSend={onSavedReflection}
          />
        </div>
      </Container>
    </Prototype>
  );
};
