import classnames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';

import trappy from '@client/assets/img/brand/trappy.png';

import classes from './Trappy.module.scss';

export type TrappyProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: 'large';
};

export const Trappy: React.FC<PropsWithChildren<TrappyProps>> = ({
  className = '',
  size,
  children,
}) => {
  const cx = classnames.bind(classes);

  if (children) {
    return (
      <div className="d-flex mt-4 justify-center align-items-center">
        <img
          src={trappy}
          className={cx('Trappy', { large: size === 'large' }, className)}
          alt="trappy"
        />
        <div className={classes.Balloon}>{children}</div>
      </div>
    );
  }
  return (
    <img
      src={trappy}
      className={cx('Trappy', { large: size == 'large' }, className)}
      alt="trappy"
    />
  );
};
