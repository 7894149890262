import store from '@client/helpers/configureAppStore';
import { createRoot } from 'react-dom/client';
// google analytics
import App from './App';
import './index.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const container = document.getElementById('root');
if(!container) throw new Error('root tag is not found!');
const root = createRoot(container);
root.render(<App store={store} />);
