import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import notify from '@client/helpers/notify';
import { KaseRequest } from '@client/helpers/requests/kaseRequest';
import {
  KaseQueryByChallenger,
  KaseStatus,
  ParticipantRole,
} from '@client/helpers/types';

import { notifyRequestError } from '../notifyRequestError';
import { useLogout } from './useLogout';

type UseKaseQueryArgs = {
  pageUnit?: number;
  myRole?: Exclude<ParticipantRole, 'GUEST'>;
  statuses?: KaseStatus[];
  keyword?: string;
  currentPage?: number;
};

export const useKaseQuery = ({
  pageUnit = 20,
  statuses = ['OPEN', 'CLOSE_APPLYING', 'COOL_DOWN'],
  currentPage = 1,
  myRole,
  keyword,
}: UseKaseQueryArgs): {
  kases: (KaseQueryByChallenger & { unreadCount?: number })[];
  total: number;
  isLoading: boolean;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [kases, setKases] = useState<KaseQueryByChallenger[]>([]);
  const [total, setTotal] = useState(0);
  const logout = useLogout();

  // NOTE: なぜuseEffectではなくuseDeepCompareEffectを使うのか
  // useEffectの第２引数の依存配列は浅い比較しか行わないため、配列を依存させると(今回はstatuses)無限ループになる。なぜなら配列はオブジェクト同様に浅いコピーだと一致しないため。
  // オブジェクトであればプロパティを具体的に書くことでループを回避できるが、配列の場合だとそれが難しいため、今回深い比較ができるライブラリを使用することにした。
  useDeepCompareEffect(() => {
    const internalOffset = (currentPage - 1) * pageUnit;
    const internalLimit = pageUnit;

    setIsLoading(true);

    const participantRoles: Exclude<ParticipantRole, 'GUEST'>[] = [];

    if (myRole) {
      participantRoles.push(myRole);
    }

    KaseRequest.search({
      statuses,
      participantRoles,
      keyword: keyword ?? null,
      offset: internalOffset,
      limit: internalLimit,
    }).then((res) => {
      setIsLoading(false);
      if (!res.isSuccess) {
        notifyRequestError({ status: res.notify, message: res.error, logout });

        return;
      }

      setTotal(res.header.total);
      setKases(res.body);

      KaseRequest.fetchKaseUnreadCounts({
        kaseIds: res.body.map((kase) => kase.id),
      }).then((unreadRes) => {
        if (!unreadRes.isSuccess) {
          return;
        }

        setKases((kases) =>
          kases.map((kase) => ({
            ...kase,
            unreadCount:
              unreadRes.body.find((item) => item.kaseId === kase.id)
                ?.unreadCount ?? 0,
          })),
        );
      });
    });
  }, [currentPage, keyword, myRole, statuses, pageUnit]);

  return { kases, total, isLoading };
};
