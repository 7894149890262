/* eslint-disable react/no-danger */
import { Parser } from 'simple-text-parser';
import urlRegex from 'url-regex';

import classes from './TextRenderer.module.scss';

type TextRendererProps = { text: string };

const parser = new Parser();
const re = urlRegex();

parser.addRule(re, (url) => {
  return `<a class="text-info" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
});

parser.addRule(/(^|\s)(>|＞)(.*)($|(\n))/, (_1, b, _2, d) => {
  return `${b}<div class=${classes.CiteBlock}>${d}</div>`;
});

parser.addRule(/(^|\s)(\*|-|・)(.*)($|(\n))/, (_1, b, _2, d) => {
  return `${b}<ul><li>${d}</li></ul>`;
});

export const TextRenderer: React.FC<TextRendererProps> = ({ text }) => {
  return (
    <span
      className={classes.TextRenderer}
      dangerouslySetInnerHTML={{
        __html: parser.render(text),
      }}
    />
  );
};
