import { ReactNode, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

import { getCurrentUser } from '@client/store/currentUser/selector';
import { reset as resetAction } from '@client/store/currentUser/slice';

import { AuthRequest } from './requests/authRequest';

/**
 * react toastify. invoke alert message at display top right
 * ex)
 * notify({ status: 'success', message: message });
 * @param {String} status One of ['success', 'error']
 * @param {String} message notify message
 */
const notify = ({
  status,
  message,
  renderCustom,
  preventDuplicateId,
}: {
  status: 'success' | 'error' | 'custom';
  message: string;
  renderCustom?: (onClose: () => void) => ReactNode;
  preventDuplicateId?: string;
}): void => {
  switch (status) {
    case 'success':
      toast.success(message, {
        id: preventDuplicateId,
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
      break;
    case 'error':
      toast.error(
        (t) => (
          <span>
            <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
            <Button
              color="primary"
              className="ml-2 mr-0"
              size="sm"
              role="presentation"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              閉じる
            </Button>
          </span>
        ),
        {
          id: preventDuplicateId,
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive',
          },
        },
      );
      break;
    case 'custom':
      toast.success(message, {
        duration: Infinity,
        id: preventDuplicateId,
      });
      break;
    default:
      throw new Error('Notify Error: Given status is not supported.');
  }
};

export default notify;
