import { AnyAction, combineReducers } from 'redux';

import trunkCommentReducer, {
  initialState as commentInitialState,
} from './comment/slice';
import { CommentsState } from './comment/types';
import userReducer, {
  initialState as userInitialState,
} from './currentUser/slice';
import { UserState } from './currentUser/types';
import kaseReducer, { initialState as kaseInitialState } from './kase/slice';
import { KaseState } from './kase/types';
import loadingOverlayReducer, {
  initialState as loadingOverlayInitialState,
} from './loadingOverlay/slice';
import { LoadingOverlayState } from './loadingOverlay/types';
import notificationReducer, {
  initialState as notificationInitialState,
} from './notification/slice';
import { NotificationState } from './notification/types';
import quoteReducer, { initialState as quoteInitialState } from './quote/slice';
import { QuoteState } from './quote/types';

export interface ApplicationState {
  kase: KaseState;
  currentUser: UserState;
  comments: CommentsState;
  loading: LoadingOverlayState;
  notification: NotificationState;
  quote: QuoteState;
}

export const applicationInitialState: ApplicationState = {
  kase: kaseInitialState,
  currentUser: userInitialState,
  comments: commentInitialState,
  loading: loadingOverlayInitialState,
  notification: notificationInitialState,
  quote: quoteInitialState,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createRootReducer = () => {
  const appReducer = combineReducers({
    kase: kaseReducer,
    currentUser: userReducer,
    comments: trunkCommentReducer,
    loading: loadingOverlayReducer,
    notification: notificationReducer,
    quote: quoteReducer,
  });

  const rootReducer = (
    state: ApplicationState | undefined,
    action: AnyAction,
  ) => {
    // action typeでUNAUTH_USERが来たときはstoreをリセットする。

    return appReducer(state, action);
  };

  return rootReducer;
};
