import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import nico from '@client/assets/img/brand/nico.png';
import {
  AccountPermission,
  KaseDetail,
  KaseStatus,
  NewKaseClose,
  ParticipantRole,
} from '@client/helpers/types';

import { KaseState } from './types';

export const initialState: KaseState = {
  detail: {
    id: 0,
    description: {
      challenge: '',
      background1: '',
      background2: '',
      background3: '',
      background4: '',
      background5: '',
    },
    title: '',
    draftTitle: '#0',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    creator: {
      id: 0,
      fullName: '',
      accountPermission: AccountPermission.CHALLENGER,
      occupation: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      avatarUrl: nico,
    },
    isRecruiting: false,
    supporters: [],
    status: KaseStatus.OPEN,
    supporterMax: 5,
    participantRole: ParticipantRole.GUEST,
    close: {
      id: 0,
      reason: '',
      learn: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      alertForLanter: false,
      alertForManager: false,
      alertForOwner: false,
    },
    recruitExpiryDate: null,
    lanter: {
      id: 0,
      fullName: '',
      avatarUrl: '',
      occupation: '',
      accountPermission: 'SENIOR_CHALLENGER',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    },
  },
};

export interface MyKnownError {
  code?: string;
  message: string;
  detail?: string;
}

const kaseSlice = createSlice({
  name: '@@kase',
  initialState,
  reducers: {
    resetKaseDetail: () => {
      return initialState;
    },

    setKaseDetail: (state, action: PayloadAction<KaseDetail>) => {
      state.detail = {
        ...action.payload,
      };

      return state;
    },
    participateAs: (state, action: PayloadAction<ParticipantRole>) => {
      state.detail.participantRole = action.payload;

      return state;
    },
    updateClose: (state, action: PayloadAction<NewKaseClose>) => {
      state.detail.close = action.payload;

      return state;
    },
  },
});

export const { resetKaseDetail, setKaseDetail, participateAs, updateClose } =
  kaseSlice.actions;

export default kaseSlice.reducer;
