import { Logger } from '@aws-amplify/core';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';

import nicoImage from '@client/assets/img/brand/nico.png';
import { AppDispatch } from '@client/helpers/configureAppStore';
import notify from '@client/helpers/notify';
import { notifyRequestError } from '@client/helpers/notifyRequestError';
import { MeRequest } from '@client/helpers/requests/meRequest';
import {
  AccountPermission,
  CurrentUser,
  UserStatus2,
} from '@client/helpers/types';

import { UserState } from './types';

const logger = new Logger('user/slice');

export const currentUserInitial: CurrentUser = {
  id: 0,
  fullName: '',
  avatarUrl: nicoImage,
  occupation: '',
  status: UserStatus2.ACTIVE,
  accountPermission: AccountPermission.CHALLENGER,
  dmId: 0,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  dmAlert: false,
  reviewStatus: 'UNDER_REVIEW',
  isRetryingPayment: false,
  applyCommentAlert: false,
  onboarding: null,
  interviewedAt: null,
};

export const initialState: UserState = {
  data: currentUserInitial,
};

interface MyKnownError {
  code?: string;
  message: string;
  detail?: string;
}

export const fetchCurrentUserInfo = createAsyncThunk<
  // Return type of the payload creator
  CurrentUser,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    dispatch: AppDispatch;
    rejectValue: MyKnownError;
  }
>('@@user/fetchCurrentUserInfo', async (_: void, thunkAPI) => {
  try {
    const res = await MeRequest.fetch();

    if (!res.isSuccess) {
      notifyRequestError({ status: res.notify, message: res.error });
      logger.error(res.error);

      return thunkAPI.rejectWithValue({
        message: res.error,
      });
    }

    if (process.env.NX_USE_GOOGLE_ANALYTICS === 'true') {
      ReactGA.set({
        userId: res.body.id,
        dimension2: res.body.id,
      });
    }

    return res.body;
  } catch (err) {
    return thunkAPI.rejectWithValue({
      message: '現在のユーザー情報の取得に失敗しました。',
    });
  }
});

const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {
    makeDmAlertFalse: (state) => {
      state.data.dmAlert = false;

      return state;
    },
    makeApplyCommentAlertFalse: (state) => {
      state.data.applyCommentAlert = false;

      return state;
    },
    updateStatus: (state, action: PayloadAction<UserStatus2>) => {
      state.data.status = action.payload;

      return state;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserInfo.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
      };

      return state;
    });
  },
});

export const {
  makeDmAlertFalse,
  updateStatus,
  makeApplyCommentAlertFalse,
  reset,
} = userSlice.actions;

export default userSlice.reducer;
