import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppDispatch } from '@client/helpers/configureAppStore';
import { myAuth } from '@client/helpers/myAuth';
import { AuthRequest } from '@client/helpers/requests/authRequest';
import { fetchCurrentUserInfo } from '@client/store/currentUser/slice';

export const useAuthentication = (
  pathname: string,
  ignoreValidatePaths: (pathName: string) => boolean,
): {
  authenticated: boolean;
} => {
  const dispatch = useAppDispatch();

  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    const validateAuthCheck = async () => {
      if (!myAuth.isValidSession()) {
        setAuthenticated(false);

        return;
      }

      setAuthenticated(true);
      dispatch(fetchCurrentUserInfo());
    };

    if (!ignoreValidatePaths(pathname)) {
      validateAuthCheck();
    }

    // NOTE: デフォルトをtrueにしないと、ログインする際に2回ログインしないと移動できなくなる。これはvalidateUserSessionとログイン後のリダイレクトにタイムラグがあるため、authenticatedがtrueになる前にリダイレクト処理が実行されるから。
    if (pathname === '/login') {
      setAuthenticated(true);
    }
  }, [dispatch, pathname, ignoreValidatePaths]);

  return { authenticated };
};
