/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, useCallback } from 'react';
import { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { lazily } from 'react-lazily';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';

import { Loading } from '@client/components/designSystems/Loading/Loading';
import { LoadingOverlay } from '@client/components/designSystems/LoadingOverlay/LoadingOverlay';
import { PrivateRoute } from '@client/components/recipes/routes/PrivateRoute/PrivateRoute';
import { PublicRoute } from '@client/components/recipes/routes/PublicRoute/PublicRoute';
import { useAuthentication } from '@client/helpers/hooks/useAuthentication';
import { useClearLoadingOverlay } from '@client/helpers/hooks/useClearLoadingOverlay';
import { useFetchNotification } from '@client/helpers/hooks/useFetchNotification';
import { useHistoryListenEffect } from '@client/helpers/hooks/useHistoryListenEffect';
import { getCurrentUser } from '@client/store/currentUser/selector';
import { PrototypeReflection } from '@client/views/Prototype/Reflection';

// React lazyはdefault export のみに対応。lazilyでnamed exportに対応する。
// 残念ながらprettier import orderプラグインがReact.lazyには対応していないため、ここは手動で入れ替え。
const { Account } = lazily(() => import('@client/views/Account/Account'));
const { Admin } = lazily(() => import('@client/views/Admin/Admin'));
const { ForgotPassword } = lazily(
  () => import('@client/views/AuthForgotPassword/ForgotPassword'),
);
const { Login } = lazily(() => import('@client/views/AuthLogin/Login'));
const { SignUpConfirm } = lazily(
  () => import('@client/views/AuthSignUpConfirm/SignUpConfirm'),
);
const { SignUp } = lazily(() => import('@client/views/AuthSignUp/SignUp'));
const { Suspending } = lazily(
  () => import('@client/views/AuthSuspending/Suspending'),
);
const { Rejected } = lazily(
  () => import('@client/views/AuthRejected/Rejected'),
);
const { Thanks } = lazily(() => import('@client/views/AuthThanks/Thanks'));
const { ChangeEmailCallback } = lazily(
  () => import('@client/views/ChangeEmailCallback/ChangeEmailCallback'),
);
const { Dm } = lazily(() => import('@client/views/Dm/Dm'));
const { KaseApplicationManagement } = lazily(
  () =>
    import('@client/views/KaseApplicationManagement/KaseApplicationManagement'),
);
const { KaseCloseApply } = lazily(
  () => import('@client/views/KaseCloseApply/KaseCloseApply'),
);
const { KaseIndex } = lazily(() => import('@client/views/KaseIndex/KaseIndex'));
const { OwnerEvaluation } = lazily(
  () => import('@client/views/KaseOwnerEvaluation/OwnerEvaluation'),
);
const { KasePage } = lazily(() => import('@client/views/Kase/Kase'));
const { Lanter } = lazily(() => import('@client/views/Lanter/Lanter'));
const { MyPage } = lazily(() => import('@client/views/MyPage/MyPage'));
const { NotFound } = lazily(() => import('@client/views/NotFound/NotFound'));
const { ChangePaymentCard } = lazily(
  () => import('@client/views/PaymentChangePaymentCard/ChangePaymentCard'),
);
const { RegisterCard } = lazily(
  () => import('@client/views/PaymentRegisterCard/RegisterCard'),
);
const { TutorialMyPage } = lazily(
  () => import('@client/views/TutorialMyPage/TutorialMyPage'),
);
const { Tutorial } = lazily(() => import('@client/views/Tutorial/Tutorial'));
const { PrototypeIndex } = lazily(
  () => import('@client/views/PrototypeIndex/PrototypeIndex'),
);
const { LineCallback } = lazily(
  () => import('@client/views/AuthExternal/LineCallback/LineCallback'),
);
const { SignUpSelect } = lazily(
  () => import('@client/views/AuthSignUpSelect/SignUpSelect'),
);

export const Routes: FC = () => {
  const currentUser = useSelector(getCurrentUser);

  const history = useHistory();
  const location = useLocation<{ publishableKey: string }>();

  // useCallbackしないとuseFetchNotificationが無限ループする。
  const ignoreValidatePaths = useCallback((pathName: string) => {
    const noLoginPath = [
      '/login',
      '/loading',
      '/thanks',
      '/signUp',
      '/forgotPassword',
      '/line-callback',
    ];

    return noLoginPath.some((path) => pathName.startsWith(path));
  }, []);

  useClearLoadingOverlay(location.pathname);

  const { authenticated } = useAuthentication(
    location.pathname,
    ignoreValidatePaths,
  );

  useFetchNotification(location.pathname, ignoreValidatePaths, authenticated);

  useHistoryListenEffect(history);

  return (
    <>
      {/*
         ルートノーティフィケーション
      */}
      <Toaster position="top-right" />
      {/*
        NOTE: ルートオーバーレイは非同期処理分岐ありだといつ止めるのかが難しい。また、オーバーレイ時にユーザー操作が一切効かなくなるのでユーザーに負担をかける。ユーザーに操作されたくないとき以外は、基本的にコンポーネント内でスケルトンの導入やエラーハンドリングを考える。
      */}
      <LoadingOverlay />

      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute
            exact
            path={['/signUp', '/sign-up']}
            component={SignUp}
          />
          <PublicRoute
            exact
            path={['/forgotPassword', '/forgot-password']}
            component={ForgotPassword}
          />
          <PrivateRoute
            exact
            path="/mypage"
            component={MyPage}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/account"
            component={Account}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/admin"
            component={Admin}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/lanter"
            component={Lanter}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/cases/:id/owner-evaluation"
            component={OwnerEvaluation}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/"
            component={KaseIndex}
            authenticated={authenticated}
            locationPath={location.search}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/cases/:id/close-apply"
            component={KaseCloseApply}
            authenticated={authenticated}
            locationPath={location.pathname}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/cases/:id"
            component={KasePage}
            authenticated={authenticated}
            locationPath={location.pathname}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/dm/:id"
            component={Dm}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/case-application-management"
            component={KaseApplicationManagement}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PublicRoute exact path="/thanks" component={Thanks} />
          <PrivateRoute
            exact
            path={['/callback', '/change-email-callback']} // TODO: 最終的にcallbackは名前があいまいすぎるのでなくしていきたい
            component={ChangeEmailCallback}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            exact
            path="/suspending"
            component={Suspending}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PublicRoute exact path="/rejected" component={Rejected} />
          <PublicRoute path="/signUp/confirm" component={SignUpConfirm} />
          <PrivateRoute
            path="/tutorial-mypage"
            component={TutorialMyPage}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/tutorial"
            component={Tutorial}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/register-card"
            component={RegisterCard}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/change-card"
            component={ChangePaymentCard}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/prototype-reflection"
            component={PrototypeReflection}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PrivateRoute
            path="/prototypes"
            component={PrototypeIndex}
            authenticated={authenticated}
            userStatus={currentUser.status}
            reviewStatus={currentUser.reviewStatus}
          />
          <PublicRoute path="/line-callback" component={LineCallback} />
          <PublicRoute path="/signUp-select" component={SignUpSelect} />
          <PublicRoute path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
};
