/* tslint:disable */

/* eslint-disable */

/**
 * ClubTRAPE
 * ClubTRAPEのAPIリファレンス
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios';

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
} from './base';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common';
import { Configuration } from './configuration';

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  jobDescription: string;
  /**
   *
   * @type {number}
   * @memberof Account
   */
  wbdLv: number;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  isPaymentByCreditCard: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  isRetryingPayment: boolean;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface AccountUpdateRequestBody
 */
export interface AccountUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  sirName: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  givenName: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  state: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  motivation: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  affiliation: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  occupation: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  jobDescription: string | null;
  /**
   *
   * @type {string}
   * @memberof AccountUpdateRequestBody
   */
  accountPermission: string | null;
}
/**
 *
 * @export
 * @interface ApplyComment
 */
export interface ApplyComment {
  /**
   *
   * @type {string}
   * @memberof ApplyComment
   */
  createdAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof ApplyComment
   */
  poster: KaseMember;
  /**
   *
   * @type {string}
   * @memberof ApplyComment
   */
  content: string;
}
/**
 *
 * @export
 * @interface ApplyCommentCreatedAt
 */
export interface ApplyCommentCreatedAt {
  /**
   *
   * @type {string}
   * @memberof ApplyCommentCreatedAt
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface CiteComment
 */
export interface CiteComment {
  /**
   *
   * @type {CiteCommentUser}
   * @memberof CiteComment
   */
  poster: CiteCommentUser;
  /**
   *
   * @type {string}
   * @memberof CiteComment
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CiteComment
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CiteComment
   */
  branchCreatedAt: string;
}
/**
 *
 * @export
 * @interface CiteCommentUser
 */
export interface CiteCommentUser {
  /**
   *
   * @type {string}
   * @memberof CiteCommentUser
   */
  avatarUrl: string;
  /**
   *
   * @type {number}
   * @memberof CiteCommentUser
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof CiteCommentUser
   */
  userName: string;
}
/**
 *
 * @export
 * @interface CloseComment
 */
export interface CloseComment {
  /**
   *
   * @type {string}
   * @memberof CloseComment
   */
  createdAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof CloseComment
   */
  poster: KaseMember;
  /**
   *
   * @type {string}
   * @memberof CloseComment
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof CloseComment
   */
  isHideComment: boolean;
}
/**
 *
 * @export
 * @interface CloseCommentCreatedAt
 */
export interface CloseCommentCreatedAt {
  /**
   *
   * @type {string}
   * @memberof CloseCommentCreatedAt
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  content: string;
  /**
   *
   * @type {KaseMember}
   * @memberof Comment
   */
  poster: KaseMember;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  kaseId: number;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  isAdminLike: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  isUnread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  isFavorite: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  hasEvaluationMemo: boolean;
  /**
   *
   * @type {Array<any>}
   * @memberof Comment
   */
  files: Array<any>;
  /**
   *
   * @type {Array<PointComment>}
   * @memberof Comment
   */
  points: Array<PointComment>;
  /**
   *
   * @type {Array<HideComment>}
   * @memberof Comment
   */
  hideComments: Array<HideComment>;
  /**
   *
   * @type {Array<Reaction>}
   * @memberof Comment
   */
  reactions: Array<Reaction>;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface CommentCreatedAt
 */
export interface CommentCreatedAt {
  /**
   *
   * @type {string}
   * @memberof CommentCreatedAt
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface ConnectWithUserAuthRequestBody
 */
export interface ConnectWithUserAuthRequestBody {
  /**
   *
   * @type {string}
   * @memberof ConnectWithUserAuthRequestBody
   */
  sub: string;
}
/**
 *
 * @export
 * @interface CreateAdminLikeCommentRequestBody
 */
export interface CreateAdminLikeCommentRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof CreateAdminLikeCommentRequestBody
   */
  isAdminLike: boolean;
}
/**
 *
 * @export
 * @interface CreateApplyCommentRequestBody
 */
export interface CreateApplyCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateApplyCommentRequestBody
   */
  content: string;
}
/**
 *
 * @export
 * @interface CreateBranchCommentRequestBody
 */
export interface CreateBranchCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateBranchCommentRequestBody
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateBranchCommentRequestBody
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {Array<CreateFileCommentRequestBody>}
   * @memberof CreateBranchCommentRequestBody
   */
  files: Array<CreateFileCommentRequestBody> | null;
}
/**
 *
 * @export
 * @interface CreateCloseCommentRequestBody
 */
export interface CreateCloseCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateCloseCommentRequestBody
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateCloseCommentRequestBody
   */
  isHideComment: boolean;
}
/**
 *
 * @export
 * @interface CreateContactUsRequestBody
 */
export interface CreateContactUsRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateContactUsRequestBody
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateContactUsRequestBody
   */
  contactType: string;
}
/**
 *
 * @export
 * @interface CreateDirectMessageRequestBody
 */
export interface CreateDirectMessageRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateDirectMessageRequestBody
   */
  content: string;
  /**
   *
   * @type {Poster}
   * @memberof CreateDirectMessageRequestBody
   */
  poster: Poster;
}
/**
 *
 * @export
 * @interface CreateEvaluationMemoRequestBody
 */
export interface CreateEvaluationMemoRequestBody {
  /**
   *
   * @type {CiteComment}
   * @memberof CreateEvaluationMemoRequestBody
   */
  citeComment: CiteComment;
  /**
   *
   * @type {Array<MemoItem>}
   * @memberof CreateEvaluationMemoRequestBody
   */
  items: Array<MemoItem>;
  /**
   *
   * @type {string}
   * @memberof CreateEvaluationMemoRequestBody
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CreateEvaluationMemoRequestBody
   */
  branchCreatedAt: string;
}
/**
 *
 * @export
 * @interface CreateFavoriteCommentRequestBody
 */
export interface CreateFavoriteCommentRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof CreateFavoriteCommentRequestBody
   */
  isFavorite: boolean;
}
/**
 *
 * @export
 * @interface CreateFileCommentRequestBody
 */
export interface CreateFileCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateFileCommentRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateFileCommentRequestBody
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof CreateFileCommentRequestBody
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CreateFileCommentRequestBody
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface CreateHideCommentRequestBody
 */
export interface CreateHideCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateHideCommentRequestBody
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreateHideCommentRequestBody
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CreateHideCommentRequestBody
   */
  branchCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CreateHideCommentRequestBody
   */
  pointCreatedAt: string | null;
  /**
   *
   * @type {Array<CreateFileCommentRequestBody>}
   * @memberof CreateHideCommentRequestBody
   */
  files: Array<CreateFileCommentRequestBody> | null;
}
/**
 *
 * @export
 * @interface CreateOwnerEvaluationRequestBody
 */
export interface CreateOwnerEvaluationRequestBody {
  /**
   *
   * @type {Item}
   * @memberof CreateOwnerEvaluationRequestBody
   */
  Icf: Item;
  /**
   *
   * @type {Item}
   * @memberof CreateOwnerEvaluationRequestBody
   */
  CBased: Item;
  /**
   *
   * @type {Item}
   * @memberof CreateOwnerEvaluationRequestBody
   */
  PCentered: Item;
  /**
   *
   * @type {Item}
   * @memberof CreateOwnerEvaluationRequestBody
   */
  TBased: Item;
  /**
   *
   * @type {Item}
   * @memberof CreateOwnerEvaluationRequestBody
   */
  TalCycle: Item;
}
/**
 *
 * @export
 * @interface CreatePointCommentRequestBody
 */
export interface CreatePointCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreatePointCommentRequestBody
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof CreatePointCommentRequestBody
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof CreatePointCommentRequestBody
   */
  branchCreatedAt: string;
  /**
   *
   * @type {Array<CreateFileCommentRequestBody>}
   * @memberof CreatePointCommentRequestBody
   */
  files: Array<CreateFileCommentRequestBody> | null;
  /**
   *
   * @type {string}
   * @memberof CreatePointCommentRequestBody
   */
  type: string;
}
/**
 *
 * @export
 * @interface CreateReactionCommentRequestBody
 */
export interface CreateReactionCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateReactionCommentRequestBody
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateReactionCommentRequestBody
   */
  isReacted: boolean;
}
/**
 *
 * @export
 * @interface CreateReflectionAnswerRequestBody
 */
export interface CreateReflectionAnswerRequestBody {
  /**
   *
   * @type {number}
   * @memberof CreateReflectionAnswerRequestBody
   */
  reflectionId: number;
  /**
   *
   * @type {number}
   * @memberof CreateReflectionAnswerRequestBody
   */
  kaseId: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateReflectionAnswerRequestBody
   */
  notShownOnNextLogin: boolean;
  /**
   *
   * @type {Array<QuestionRequestBody>}
   * @memberof CreateReflectionAnswerRequestBody
   */
  questions: Array<QuestionRequestBody>;
}
/**
 *
 * @export
 * @interface CreateTrunkCommentRequestBody
 */
export interface CreateTrunkCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateTrunkCommentRequestBody
   */
  content: string;
  /**
   *
   * @type {Array<CreateFileCommentRequestBody>}
   * @memberof CreateTrunkCommentRequestBody
   */
  files: Array<CreateFileCommentRequestBody> | null;
}
/**
 *
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
  /**
   *
   * @type {number}
   * @memberof CurrentUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  avatarUrl: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  accountPermission: CurrentUserAccountPermissionEnum;
  /**
   *
   * @type {number}
   * @memberof CurrentUser
   */
  dmId: number;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  status: CurrentUserStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUser
   */
  dmAlert: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  reviewStatus: CurrentUserReviewStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUser
   */
  isRetryingPayment: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUser
   */
  applyCommentAlert: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  fullName: string;
  /**
   *
   * @type {CurrentUserOnboarding}
   * @memberof CurrentUser
   */
  onboarding: CurrentUserOnboarding | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUser
   */
  interviewedAt: string | null;
}

export const CurrentUserAccountPermissionEnum = {
  Challenger: 'CHALLENGER',
  SeniorChallenger: 'SENIOR_CHALLENGER',
  Admin: 'ADMIN',
} as const;

export type CurrentUserAccountPermissionEnum =
  typeof CurrentUserAccountPermissionEnum[keyof typeof CurrentUserAccountPermissionEnum];
export const CurrentUserStatusEnum = {
  Init: 'INIT',
  Onboarding: 'ONBOARDING',
  Active: 'ACTIVE',
  Left: 'LEFT',
  Suspending: 'SUSPENDING',
} as const;

export type CurrentUserStatusEnum =
  typeof CurrentUserStatusEnum[keyof typeof CurrentUserStatusEnum];
export const CurrentUserReviewStatusEnum = {
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type CurrentUserReviewStatusEnum =
  typeof CurrentUserReviewStatusEnum[keyof typeof CurrentUserReviewStatusEnum];

/**
 *
 * @export
 * @interface CurrentUserOnboarding
 */
export interface CurrentUserOnboarding {
  /**
   *
   * @type {string}
   * @memberof CurrentUserOnboarding
   */
  reviewStatus: CurrentUserOnboardingReviewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CurrentUserOnboarding
   */
  tutorialFinishedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUserOnboarding
   */
  paymentRegisteredAt: string | null;
  /**
   *
   * @type {string}
   * @memberof CurrentUserOnboarding
   */
  filledOutUserDetailAt: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserOnboarding
   */
  isSkipPayment: boolean;
  /**
   *
   * @type {string}
   * @memberof CurrentUserOnboarding
   */
  onboardingStartedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof CurrentUserOnboarding
   */
  isInterviewRequired: boolean;
}

export const CurrentUserOnboardingReviewStatusEnum = {
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type CurrentUserOnboardingReviewStatusEnum =
  typeof CurrentUserOnboardingReviewStatusEnum[keyof typeof CurrentUserOnboardingReviewStatusEnum];

/**
 *
 * @export
 * @interface Description
 */
export interface Description {
  /**
   *
   * @type {string}
   * @memberof Description
   */
  challenge: string;
  /**
   *
   * @type {string}
   * @memberof Description
   */
  background1: string;
  /**
   *
   * @type {string}
   * @memberof Description
   */
  background2: string;
  /**
   *
   * @type {string}
   * @memberof Description
   */
  background3: string;
  /**
   *
   * @type {string}
   * @memberof Description
   */
  background4: string;
  /**
   *
   * @type {string}
   * @memberof Description
   */
  background5: string;
}
/**
 *
 * @export
 * @interface DirectMessage
 */
export interface DirectMessage {
  /**
   *
   * @type {string}
   * @memberof DirectMessage
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DirectMessage
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof DirectMessage
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof DirectMessage
   */
  updatedAt: string;
  /**
   *
   * @type {DirectMessagePoster}
   * @memberof DirectMessage
   */
  poster: DirectMessagePoster;
}
/**
 *
 * @export
 * @interface DirectMessagePoster
 */
export interface DirectMessagePoster {
  /**
   *
   * @type {number}
   * @memberof DirectMessagePoster
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DirectMessagePoster
   */
  fullName: string;
}
/**
 *
 * @export
 * @interface DmUser
 */
export interface DmUser {
  /**
   *
   * @type {number}
   * @memberof DmUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DmUser
   */
  avatarUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof DmUser
   */
  dmAlert: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DmUser
   */
  dmAlertForAdmin: boolean;
  /**
   *
   * @type {number}
   * @memberof DmUser
   */
  dmId: number;
  /**
   *
   * @type {string}
   * @memberof DmUser
   */
  dmLastCommentedAt: string;
  /**
   *
   * @type {string}
   * @memberof DmUser
   */
  dmLastSeen: string;
  /**
   *
   * @type {string}
   * @memberof DmUser
   */
  fullName: string;
}
/**
 *
 * @export
 * @interface EvaluatedKase
 */
export interface EvaluatedKase {
  /**
   *
   * @type {number}
   * @memberof EvaluatedKase
   */
  id: number;
  /**
   *
   * @type {KaseClose}
   * @memberof EvaluatedKase
   */
  close: KaseClose;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  title: string;
  /**
   *
   * @type {KaseDescription}
   * @memberof EvaluatedKase
   */
  description: KaseDescription;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  status: EvaluatedKaseStatusEnum;
  /**
   *
   * @type {number}
   * @memberof EvaluatedKase
   */
  creatorId: number;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  draftTitle: string;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  appliedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  approvedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  openAt: string | null;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  coolDownAt: string | null;
  /**
   *
   * @type {string}
   * @memberof EvaluatedKase
   */
  closedAt: string | null;
}

export const EvaluatedKaseStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type EvaluatedKaseStatusEnum =
  typeof EvaluatedKaseStatusEnum[keyof typeof EvaluatedKaseStatusEnum];

/**
 *
 * @export
 * @interface EvaluationItems
 */
export interface EvaluationItems {
  /**
   *
   * @type {boolean}
   * @memberof EvaluationItems
   */
  item1: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaluationItems
   */
  item2: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EvaluationItems
   */
  item3: boolean;
}
/**
 *
 * @export
 * @interface EvaluationMemo
 */
export interface EvaluationMemo {
  /**
   *
   * @type {CiteComment}
   * @memberof EvaluationMemo
   */
  citeComment: CiteComment;
  /**
   *
   * @type {Array<EvaluationThemeMemo>}
   * @memberof EvaluationMemo
   */
  items: Array<EvaluationThemeMemo>;
  /**
   *
   * @type {string}
   * @memberof EvaluationMemo
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof EvaluationMemo
   */
  branchCreatedAt: string;
  /**
   *
   * @type {number}
   * @memberof EvaluationMemo
   */
  kaseId: number;
  /**
   *
   * @type {number}
   * @memberof EvaluationMemo
   */
  lanterId: number;
}
/**
 * @type EvaluationMemoControllerShow200Response
 * @export
 */
export type EvaluationMemoControllerShow200Response = EvaluationMemo;

/**
 *
 * @export
 * @interface EvaluationThemeMemo
 */
export interface EvaluationThemeMemo {
  /**
   *
   * @type {string}
   * @memberof EvaluationThemeMemo
   */
  evaluationTheme: EvaluationThemeMemoEvaluationThemeEnum;
  /**
   *
   * @type {string}
   * @memberof EvaluationThemeMemo
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof EvaluationThemeMemo
   */
  content: string;
}

export const EvaluationThemeMemoEvaluationThemeEnum = {
  TalCycle: 'TalCycle',
  Icf: 'Icf',
  TBased: 'TBased',
  CBased: 'CBased',
  PCentered: 'PCentered',
} as const;

export type EvaluationThemeMemoEvaluationThemeEnum =
  typeof EvaluationThemeMemoEvaluationThemeEnum[keyof typeof EvaluationThemeMemoEvaluationThemeEnum];

/**
 *
 * @export
 * @interface FileRequestBody
 */
export interface FileRequestBody {
  /**
   *
   * @type {string}
   * @memberof FileRequestBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FileRequestBody
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof FileRequestBody
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof FileRequestBody
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface HideComment
 */
export interface HideComment {
  /**
   *
   * @type {string}
   * @memberof HideComment
   */
  userName: string;
  /**
   *
   * @type {number}
   * @memberof HideComment
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof HideComment
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof HideComment
   */
  createdAt: string;
  /**
   *
   * @type {boolean}
   * @memberof HideComment
   */
  isUnread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HideComment
   */
  isFavorite: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HideComment
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface Item
 */
export interface Item {
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  item1: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  item2: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Item
   */
  item3: boolean;
}
/**
 *
 * @export
 * @interface KaseAdminQuery
 */
export interface KaseAdminQuery {
  /**
   *
   * @type {number}
   * @memberof KaseAdminQuery
   */
  id: number;
  /**
   *
   * @type {KaseClose}
   * @memberof KaseAdminQuery
   */
  close: KaseClose;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  title: string | null;
  /**
   *
   * @type {KaseDescription}
   * @memberof KaseAdminQuery
   */
  description: KaseDescription;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  updatedAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof KaseAdminQuery
   */
  creator: KaseMember;
  /**
   *
   * @type {Array<KaseMember>}
   * @memberof KaseAdminQuery
   */
  supporters: Array<KaseMember>;
  /**
   *
   * @type {KaseChallengerQueryLanter}
   * @memberof KaseAdminQuery
   */
  lanter: KaseChallengerQueryLanter | null;
  /**
   *
   * @type {number}
   * @memberof KaseAdminQuery
   */
  supporterMax: number;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  status: KaseAdminQueryStatusEnum;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  appliedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  openAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  approvedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  coolDownAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  closedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  draftTitle: string;
  /**
   *
   * @type {boolean}
   * @memberof KaseAdminQuery
   */
  isSupporterRecruiting: boolean;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  supporterRecruitExpiryDate: string | null;
  /**
   *
   * @type {boolean}
   * @memberof KaseAdminQuery
   */
  isLanterRecruiting: boolean;
  /**
   *
   * @type {string}
   * @memberof KaseAdminQuery
   */
  lanterRecruitExpiryDate: string | null;
  /**
   *
   * @type {boolean}
   * @memberof KaseAdminQuery
   */
  enableOwnerApplyComment: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KaseAdminQuery
   */
  applyCommentAlertForAdmin: boolean;
}

export const KaseAdminQueryStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type KaseAdminQueryStatusEnum =
  typeof KaseAdminQueryStatusEnum[keyof typeof KaseAdminQueryStatusEnum];

/**
 *
 * @export
 * @interface KaseChallengerQuery
 */
export interface KaseChallengerQuery {
  /**
   *
   * @type {number}
   * @memberof KaseChallengerQuery
   */
  id: number;
  /**
   *
   * @type {KaseClose}
   * @memberof KaseChallengerQuery
   */
  close: KaseClose;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  title: string | null;
  /**
   *
   * @type {KaseDescription}
   * @memberof KaseChallengerQuery
   */
  description: KaseDescription;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  updatedAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof KaseChallengerQuery
   */
  creator: KaseMember;
  /**
   *
   * @type {Array<KaseMember>}
   * @memberof KaseChallengerQuery
   */
  supporters: Array<KaseMember>;
  /**
   *
   * @type {KaseChallengerQueryLanter}
   * @memberof KaseChallengerQuery
   */
  lanter: KaseChallengerQueryLanter | null;
  /**
   *
   * @type {number}
   * @memberof KaseChallengerQuery
   */
  supporterMax: number;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  appliedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  openAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  approvedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  coolDownAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  closedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  status: KaseChallengerQueryStatusEnum;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  draftTitle: string;
  /**
   *
   * @type {boolean}
   * @memberof KaseChallengerQuery
   */
  isRecruiting: boolean;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQuery
   */
  recruitExpiryDate: string | null;
}

export const KaseChallengerQueryStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type KaseChallengerQueryStatusEnum =
  typeof KaseChallengerQueryStatusEnum[keyof typeof KaseChallengerQueryStatusEnum];

/**
 *
 * @export
 * @interface KaseChallengerQueryLanter
 */
export interface KaseChallengerQueryLanter {
  /**
   *
   * @type {number}
   * @memberof KaseChallengerQueryLanter
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  avatarUrl: string;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  accountPermission: KaseChallengerQueryLanterAccountPermissionEnum;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseChallengerQueryLanter
   */
  fullName: string;
}

export const KaseChallengerQueryLanterAccountPermissionEnum = {
  Challenger: 'CHALLENGER',
  SeniorChallenger: 'SENIOR_CHALLENGER',
  Admin: 'ADMIN',
} as const;

export type KaseChallengerQueryLanterAccountPermissionEnum =
  typeof KaseChallengerQueryLanterAccountPermissionEnum[keyof typeof KaseChallengerQueryLanterAccountPermissionEnum];

/**
 *
 * @export
 * @interface KaseClose
 */
export interface KaseClose {
  /**
   *
   * @type {number}
   * @memberof KaseClose
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof KaseClose
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof KaseClose
   */
  learn: string;
  /**
   *
   * @type {string}
   * @memberof KaseClose
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseClose
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof KaseClose
   */
  alertForOwner: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KaseClose
   */
  alertForLanter: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KaseClose
   */
  alertForManager: boolean;
}
/**
 *
 * @export
 * @interface KaseDescription
 */
export interface KaseDescription {
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  challenge: string;
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  background1: string;
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  background2: string;
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  background3: string;
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  background4: string;
  /**
   *
   * @type {string}
   * @memberof KaseDescription
   */
  background5: string;
}
/**
 *
 * @export
 * @interface KaseDetail
 */
export interface KaseDetail {
  /**
   *
   * @type {number}
   * @memberof KaseDetail
   */
  id: number;
  /**
   *
   * @type {KaseDetailClose}
   * @memberof KaseDetail
   */
  close: KaseDetailClose | null;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  title: string | null;
  /**
   *
   * @type {KaseDescription}
   * @memberof KaseDetail
   */
  description: KaseDescription;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  updatedAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof KaseDetail
   */
  creator: KaseMember;
  /**
   *
   * @type {Array<KaseMember>}
   * @memberof KaseDetail
   */
  supporters: Array<KaseMember>;
  /**
   *
   * @type {number}
   * @memberof KaseDetail
   */
  supporterMax: number;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  status: KaseDetailStatusEnum;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  participantRole: KaseDetailParticipantRoleEnum;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  draftTitle: string;
  /**
   *
   * @type {boolean}
   * @memberof KaseDetail
   */
  isRecruiting: boolean;
  /**
   *
   * @type {string}
   * @memberof KaseDetail
   */
  recruitExpiryDate: string | null;
  /**
   *
   * @type {KaseMember}
   * @memberof KaseDetail
   */
  lanter: KaseMember;
}

export const KaseDetailStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type KaseDetailStatusEnum =
  typeof KaseDetailStatusEnum[keyof typeof KaseDetailStatusEnum];
export const KaseDetailParticipantRoleEnum = {
  Owner: 'OWNER',
  Supporter: 'SUPPORTER',
  Watcher: 'WATCHER',
  Lanter: 'LANTER',
  Manager: 'MANAGER',
  Guest: 'GUEST',
} as const;

export type KaseDetailParticipantRoleEnum =
  typeof KaseDetailParticipantRoleEnum[keyof typeof KaseDetailParticipantRoleEnum];

/**
 *
 * @export
 * @interface KaseDetailClose
 */
export interface KaseDetailClose {
  /**
   *
   * @type {number}
   * @memberof KaseDetailClose
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof KaseDetailClose
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof KaseDetailClose
   */
  learn: string;
  /**
   *
   * @type {string}
   * @memberof KaseDetailClose
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseDetailClose
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof KaseDetailClose
   */
  alertForOwner: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KaseDetailClose
   */
  alertForLanter: boolean;
  /**
   *
   * @type {boolean}
   * @memberof KaseDetailClose
   */
  alertForManager: boolean;
}
/**
 *
 * @export
 * @interface KaseMember
 */
export interface KaseMember {
  /**
   *
   * @type {number}
   * @memberof KaseMember
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  avatarUrl: string;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  accountPermission: KaseMemberAccountPermissionEnum;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof KaseMember
   */
  fullName: string;
}

export const KaseMemberAccountPermissionEnum = {
  Challenger: 'CHALLENGER',
  SeniorChallenger: 'SENIOR_CHALLENGER',
  Admin: 'ADMIN',
} as const;

export type KaseMemberAccountPermissionEnum =
  typeof KaseMemberAccountPermissionEnum[keyof typeof KaseMemberAccountPermissionEnum];

/**
 *
 * @export
 * @interface KaseUnreadCount
 */
export interface KaseUnreadCount {
  /**
   *
   * @type {number}
   * @memberof KaseUnreadCount
   */
  kaseId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof KaseUnreadCount
   */
  unreadCount: Array<number>;
}
/**
 *
 * @export
 * @interface LoginWithUserAuthRequestBody
 */
export interface LoginWithUserAuthRequestBody {
  /**
   *
   * @type {string}
   * @memberof LoginWithUserAuthRequestBody
   */
  sub: string;
}
/**
 * @type MeControllerShowReflection200Response
 * @export
 */
export type MeControllerShowReflection200Response = Reflection;

/**
 *
 * @export
 * @interface MeEvaluation
 */
export interface MeEvaluation {
  /**
   *
   * @type {Array<EvaluatedKase>}
   * @memberof MeEvaluation
   */
  PCentered: Array<EvaluatedKase>;
  /**
   *
   * @type {Array<EvaluatedKase>}
   * @memberof MeEvaluation
   */
  Icf: Array<EvaluatedKase>;
  /**
   *
   * @type {Array<EvaluatedKase>}
   * @memberof MeEvaluation
   */
  TBased: Array<EvaluatedKase>;
  /**
   *
   * @type {Array<EvaluatedKase>}
   * @memberof MeEvaluation
   */
  CBased: Array<EvaluatedKase>;
  /**
   *
   * @type {Array<EvaluatedKase>}
   * @memberof MeEvaluation
   */
  TalCycle: Array<EvaluatedKase>;
}
/**
 *
 * @export
 * @interface MePerformance
 */
export interface MePerformance {
  /**
   *
   * @type {number}
   * @memberof MePerformance
   */
  supportedKases: number;
  /**
   *
   * @type {number}
   * @memberof MePerformance
   */
  closedKases: number;
}
/**
 *
 * @export
 * @interface MemoItem
 */
export interface MemoItem {
  /**
   *
   * @type {string}
   * @memberof MemoItem
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof MemoItem
   */
  evaluationTheme: MemoItemEvaluationThemeEnum;
}

export const MemoItemEvaluationThemeEnum = {
  TalCycle: 'TalCycle',
  Icf: 'Icf',
  TBased: 'TBased',
  CBased: 'CBased',
  PCentered: 'PCentered',
} as const;

export type MemoItemEvaluationThemeEnum =
  typeof MemoItemEvaluationThemeEnum[keyof typeof MemoItemEvaluationThemeEnum];

/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface MyAuthentication
 */
export interface MyAuthentication {
  /**
   *
   * @type {number}
   * @memberof MyAuthentication
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof MyAuthentication
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof MyAuthentication
   */
  refreshToken: string;
}
/**
 *
 * @export
 * @interface NewKase
 */
export interface NewKase {
  /**
   *
   * @type {number}
   * @memberof NewKase
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NewKase
   */
  title: string | null;
  /**
   *
   * @type {KaseDescription}
   * @memberof NewKase
   */
  description: KaseDescription;
  /**
   *
   * @type {string}
   * @memberof NewKase
   */
  draftTitle: string;
  /**
   *
   * @type {string}
   * @memberof NewKase
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof NewKase
   */
  updatedAt: string;
  /**
   *
   * @type {KaseMember}
   * @memberof NewKase
   */
  creator: KaseMember;
  /**
   *
   * @type {string}
   * @memberof NewKase
   */
  status: NewKaseStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof NewKase
   */
  enableOwnerApplyComment: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewKase
   */
  applyCommentAlertForAdmin: boolean;
}

export const NewKaseStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type NewKaseStatusEnum =
  typeof NewKaseStatusEnum[keyof typeof NewKaseStatusEnum];

/**
 *
 * @export
 * @interface NewKaseClose
 */
export interface NewKaseClose {
  /**
   *
   * @type {number}
   * @memberof NewKaseClose
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NewKaseClose
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseClose
   */
  learn: string;
  /**
   *
   * @type {boolean}
   * @memberof NewKaseClose
   */
  alertForOwner: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewKaseClose
   */
  alertForLanter: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewKaseClose
   */
  alertForManager: boolean;
  /**
   *
   * @type {string}
   * @memberof NewKaseClose
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseClose
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface NewKaseCloseUpdateRequestBody
 */
export interface NewKaseCloseUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof NewKaseCloseUpdateRequestBody
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseCloseUpdateRequestBody
   */
  learn: string;
  /**
   *
   * @type {number}
   * @memberof NewKaseCloseUpdateRequestBody
   */
  kaseId: number;
}
/**
 * @type NewKaseControllerShowMe200Response
 * @export
 */
export type NewKaseControllerShowMe200Response = NewKase;

/**
 *
 * @export
 * @interface NewKaseCreateRequestBody
 */
export interface NewKaseCreateRequestBody {
  /**
   *
   * @type {string}
   * @memberof NewKaseCreateRequestBody
   */
  title: string | null;
  /**
   *
   * @type {Description}
   * @memberof NewKaseCreateRequestBody
   */
  description: Description;
}
/**
 *
 * @export
 * @interface NewKaseUpdateRequestBody
 */
export interface NewKaseUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  challenge: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  background1: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  background2: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  background3: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  background4: string;
  /**
   *
   * @type {string}
   * @memberof NewKaseUpdateRequestBody
   */
  background5: string;
}
/**
 *
 * @export
 * @interface NewUser
 */
export interface NewUser {
  /**
   *
   * @type {number}
   * @memberof NewUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  jobDescription: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  reviewStatus: NewUserReviewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof NewUser
   */
  interviewedAt: string | null;
}

export const NewUserReviewStatusEnum = {
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type NewUserReviewStatusEnum =
  typeof NewUserReviewStatusEnum[keyof typeof NewUserReviewStatusEnum];

/**
 *
 * @export
 * @interface NewUserApproveRequestBody
 */
export interface NewUserApproveRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof NewUserApproveRequestBody
   */
  isSkipPayment: boolean;
}
/**
 *
 * @export
 * @interface NewUserCreateRequestBody
 */
export interface NewUserCreateRequestBody {
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  jobDescription: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof NewUserCreateRequestBody
   */
  avatarUrl: string | null;
}
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  eventType: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  url: string;
}
/**
 *
 * @export
 * @interface NotificationWithCount
 */
export interface NotificationWithCount {
  /**
   *
   * @type {Array<Notification>}
   * @memberof NotificationWithCount
   */
  notifications: Array<Notification>;
  /**
   *
   * @type {number}
   * @memberof NotificationWithCount
   */
  newCount: number;
}
/**
 *
 * @export
 * @interface OwnerEvaluation
 */
export interface OwnerEvaluation {
  /**
   *
   * @type {number}
   * @memberof OwnerEvaluation
   */
  kaseId: number;
  /**
   *
   * @type {number}
   * @memberof OwnerEvaluation
   */
  creatorId: number;
  /**
   *
   * @type {string}
   * @memberof OwnerEvaluation
   */
  evaluatedAt: string;
  /**
   *
   * @type {string}
   * @memberof OwnerEvaluation
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof OwnerEvaluation
   */
  evaluationLimitDate: string;
  /**
   *
   * @type {EvaluationItems}
   * @memberof OwnerEvaluation
   */
  Icf: EvaluationItems;
  /**
   *
   * @type {EvaluationItems}
   * @memberof OwnerEvaluation
   */
  CBased: EvaluationItems;
  /**
   *
   * @type {EvaluationItems}
   * @memberof OwnerEvaluation
   */
  PCentered: EvaluationItems;
  /**
   *
   * @type {EvaluationItems}
   * @memberof OwnerEvaluation
   */
  TBased: EvaluationItems;
  /**
   *
   * @type {EvaluationItems}
   * @memberof OwnerEvaluation
   */
  TalCycle: EvaluationItems;
}
/**
 * @type OwnerEvaluationsControllerIndex200Response
 * @export
 */
export type OwnerEvaluationsControllerIndex200Response = OwnerEvaluation;

/**
 * @type PaymentControllerCurrentInformation200Response
 * @export
 */
export type PaymentControllerCurrentInformation200Response = PaymentCurrent;

/**
 *
 * @export
 * @interface PaymentCreateSubscriptionRequestBody
 */
export interface PaymentCreateSubscriptionRequestBody {
  /**
   *
   * @type {string}
   * @memberof PaymentCreateSubscriptionRequestBody
   */
  priceId: string;
}
/**
 *
 * @export
 * @interface PaymentCurrent
 */
export interface PaymentCurrent {
  /**
   *
   * @type {string}
   * @memberof PaymentCurrent
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentCurrent
   */
  brand: string;
  /**
   *
   * @type {string}
   * @memberof PaymentCurrent
   */
  last4: string;
  /**
   *
   * @type {string}
   * @memberof PaymentCurrent
   */
  billingCycleAnchor: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentCurrent
   */
  isRetryingPayment: boolean;
}
/**
 *
 * @export
 * @interface PaymentHistory
 */
export interface PaymentHistory {
  /**
   *
   * @type {string}
   * @memberof PaymentHistory
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistory
   */
  brand: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistory
   */
  last4: string;
  /**
   *
   * @type {string}
   * @memberof PaymentHistory
   */
  processDate: string;
  /**
   *
   * @type {boolean}
   * @memberof PaymentHistory
   */
  processResult: boolean;
  /**
   *
   * @type {string}
   * @memberof PaymentHistory
   */
  processError: string | null;
  /**
   *
   * @type {number}
   * @memberof PaymentHistory
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof PaymentHistory
   */
  tax: number;
}
/**
 *
 * @export
 * @interface PaymentSubscription
 */
export interface PaymentSubscription {
  /**
   *
   * @type {string}
   * @memberof PaymentSubscription
   */
  subscriptionId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSubscription
   */
  clientSecret: string | null;
}
/**
 *
 * @export
 * @interface PaymentUpdateRequestBody
 */
export interface PaymentUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequestBody
   */
  paymentMethodId: string;
}
/**
 *
 * @export
 * @interface PointComment
 */
export interface PointComment {
  /**
   *
   * @type {string}
   * @memberof PointComment
   */
  userName: string;
  /**
   *
   * @type {number}
   * @memberof PointComment
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof PointComment
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof PointComment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof PointComment
   */
  type: PointCommentTypeEnum;
  /**
   *
   * @type {Array<HideComment>}
   * @memberof PointComment
   */
  hideComments: Array<HideComment>;
  /**
   *
   * @type {boolean}
   * @memberof PointComment
   */
  isUnread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PointComment
   */
  isFavorite: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PointComment
   */
  isDeleted: boolean;
}

export const PointCommentTypeEnum = {
  Good: 'good',
  Think: 'think',
} as const;

export type PointCommentTypeEnum =
  typeof PointCommentTypeEnum[keyof typeof PointCommentTypeEnum];

/**
 *
 * @export
 * @interface Poster
 */
export interface Poster {
  /**
   *
   * @type {string}
   * @memberof Poster
   */
  fullName: string;
  /**
   *
   * @type {number}
   * @memberof Poster
   */
  id: number;
}
/**
 *
 * @export
 * @interface PresignedUrl
 */
export interface PresignedUrl {
  /**
   *
   * @type {string}
   * @memberof PresignedUrl
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof PresignedUrl
   */
  key: string;
}
/**
 *
 * @export
 * @interface PrivateKaseUpdateRequestBody
 */
export interface PrivateKaseUpdateRequestBody {
  /**
   *
   * @type {Description}
   * @memberof PrivateKaseUpdateRequestBody
   */
  description: Description;
  /**
   *
   * @type {string}
   * @memberof PrivateKaseUpdateRequestBody
   */
  status: PrivateKaseUpdateRequestBodyStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PrivateKaseUpdateRequestBody
   */
  supporterMax: number;
}

export const PrivateKaseUpdateRequestBodyStatusEnum = {
  Draft: 'DRAFT',
  Applying: 'APPLYING',
  LanterWanted: 'LANTER_WANTED',
  Open: 'OPEN',
  CloseApplying: 'CLOSE_APPLYING',
  Pending: 'PENDING',
  Closed: 'CLOSED',
  CoolDown: 'COOL_DOWN',
  OwnerGone: 'OWNER_GONE',
} as const;

export type PrivateKaseUpdateRequestBodyStatusEnum =
  typeof PrivateKaseUpdateRequestBodyStatusEnum[keyof typeof PrivateKaseUpdateRequestBodyStatusEnum];

/**
 *
 * @export
 * @interface ProductKeys
 */
export interface ProductKeys {
  /**
   *
   * @type {string}
   * @memberof ProductKeys
   */
  publishableKey: string;
  /**
   *
   * @type {Array<StripePrice>}
   * @memberof ProductKeys
   */
  prices: Array<StripePrice>;
}
/**
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   *
   * @type {string}
   * @memberof Question
   */
  content: string;
  /**
   *
   * @type {string}
   * @memberof Question
   */
  answer: string;
  /**
   *
   * @type {number}
   * @memberof Question
   */
  questionId: number;
}
/**
 *
 * @export
 * @interface QuestionRequestBody
 */
export interface QuestionRequestBody {
  /**
   *
   * @type {number}
   * @memberof QuestionRequestBody
   */
  questionId: number;
  /**
   *
   * @type {string}
   * @memberof QuestionRequestBody
   */
  answer: string;
}
/**
 *
 * @export
 * @interface RawComment
 */
export interface RawComment {
  /**
   *
   * @type {number}
   * @memberof RawComment
   */
  kaseId: number;
  /**
   *
   * @type {string}
   * @memberof RawComment
   */
  content: string;
  /**
   *
   * @type {boolean}
   * @memberof RawComment
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RawComment
   */
  isAdminLike: boolean;
  /**
   *
   * @type {Array<any>}
   * @memberof RawComment
   */
  files: Array<any>;
  /**
   *
   * @type {Array<Reaction>}
   * @memberof RawComment
   */
  reactions: Array<Reaction>;
  /**
   *
   * @type {string}
   * @memberof RawComment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RawComment
   */
  userName: string;
  /**
   *
   * @type {number}
   * @memberof RawComment
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof RawComment
   */
  commentType: RawCommentCommentTypeEnum;
}

export const RawCommentCommentTypeEnum = {
  Trunk: 'trunk',
  Branch: 'branch',
  Point: 'point',
  Hide: 'hide',
} as const;

export type RawCommentCommentTypeEnum =
  typeof RawCommentCommentTypeEnum[keyof typeof RawCommentCommentTypeEnum];

/**
 *
 * @export
 * @interface Reaction
 */
export interface Reaction {
  /**
   *
   * @type {string}
   * @memberof Reaction
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Reaction
   */
  userIds: Array<number>;
  /**
   *
   * @type {number}
   * @memberof Reaction
   */
  count: number;
}
/**
 *
 * @export
 * @interface ReadCommentRequestBody
 */
export interface ReadCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof ReadCommentRequestBody
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface Reflection
 */
export interface Reflection {
  /**
   *
   * @type {number}
   * @memberof Reflection
   */
  kaseId: number;
  /**
   *
   * @type {number}
   * @memberof Reflection
   */
  draftTitleNumber: number;
  /**
   *
   * @type {KaseClose}
   * @memberof Reflection
   */
  close: KaseClose;
  /**
   *
   * @type {Array<Question>}
   * @memberof Reflection
   */
  questions: Array<Question>;
  /**
   *
   * @type {string}
   * @memberof Reflection
   */
  closedAt: string;
  /**
   *
   * @type {string}
   * @memberof Reflection
   */
  challenge: string;
  /**
   *
   * @type {number}
   * @memberof Reflection
   */
  reflectionId: number;
}
/**
 *
 * @export
 * @interface RegisterCardForE2E
 */
export interface RegisterCardForE2E {
  /**
   *
   * @type {string}
   * @memberof RegisterCardForE2E
   */
  paymentIntentStatus: RegisterCardForE2EPaymentIntentStatusEnum;
}

export const RegisterCardForE2EPaymentIntentStatusEnum = {
  Canceled: 'canceled',
  Processing: 'processing',
  RequiresAction: 'requires_action',
  RequiresCapture: 'requires_capture',
  RequiresConfirmation: 'requires_confirmation',
  RequiresPaymentMethod: 'requires_payment_method',
  Succeeded: 'succeeded',
} as const;

export type RegisterCardForE2EPaymentIntentStatusEnum =
  typeof RegisterCardForE2EPaymentIntentStatusEnum[keyof typeof RegisterCardForE2EPaymentIntentStatusEnum];

/**
 *
 * @export
 * @interface RegisterParticipantRequestBody
 */
export interface RegisterParticipantRequestBody {
  /**
   *
   * @type {string}
   * @memberof RegisterParticipantRequestBody
   */
  type: RegisterParticipantRequestBodyTypeEnum;
}

export const RegisterParticipantRequestBodyTypeEnum = {
  Supporter: 'SUPPORTER',
  Watcher: 'WATCHER',
  Lanter: 'LANTER',
  Manager: 'MANAGER',
} as const;

export type RegisterParticipantRequestBodyTypeEnum =
  typeof RegisterParticipantRequestBodyTypeEnum[keyof typeof RegisterParticipantRequestBodyTypeEnum];

/**
 *
 * @export
 * @interface SetupIntent
 */
export interface SetupIntent {
  /**
   *
   * @type {string}
   * @memberof SetupIntent
   */
  clientSecret: string | null;
}
/**
 *
 * @export
 * @interface SignupWithE2ERequestBody
 */
export interface SignupWithE2ERequestBody {
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  sub: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  jobDescription: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  accountPermission: string;
  /**
   *
   * @type {string}
   * @memberof SignupWithE2ERequestBody
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof SignupWithE2ERequestBody
   */
  createOnlyUserTable: boolean;
}
/**
 *
 * @export
 * @interface StripePrice
 */
export interface StripePrice {
  /**
   *
   * @type {boolean}
   * @memberof StripePrice
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  billing_scheme: string;
  /**
   *
   * @type {number}
   * @memberof StripePrice
   */
  created: number;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof StripePrice
   */
  livemode: boolean;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  lookup_key: string | null;
  /**
   *
   * @type {object}
   * @memberof StripePrice
   */
  metadata: object;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  nickname: string | null;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  object: string;
  /**
   *
   * @type {object}
   * @memberof StripePrice
   */
  product: object;
  /**
   *
   * @type {object}
   * @memberof StripePrice
   */
  recurring: object;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  tax_behavior: string | null;
  /**
   *
   * @type {Array<StripePriceTier>}
   * @memberof StripePrice
   */
  tiers: Array<StripePriceTier> | null;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  tiers_mode: string | null;
  /**
   *
   * @type {StripePriceTransformQuantity}
   * @memberof StripePrice
   */
  transform_quantity: StripePriceTransformQuantity | null;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof StripePrice
   */
  unit_amount: number | null;
  /**
   *
   * @type {string}
   * @memberof StripePrice
   */
  unit_amount_decimal: string | null;
}
/**
 *
 * @export
 * @interface StripePriceTier
 */
export interface StripePriceTier {
  /**
   *
   * @type {number}
   * @memberof StripePriceTier
   */
  flat_amount: number | null;
  /**
   *
   * @type {string}
   * @memberof StripePriceTier
   */
  flat_amount_decimal: string | null;
  /**
   *
   * @type {number}
   * @memberof StripePriceTier
   */
  unit_amount: number | null;
  /**
   *
   * @type {string}
   * @memberof StripePriceTier
   */
  unit_amount_decimal: string | null;
  /**
   *
   * @type {number}
   * @memberof StripePriceTier
   */
  up_to: number | null;
}
/**
 *
 * @export
 * @interface StripePriceTransformQuantity
 */
export interface StripePriceTransformQuantity {
  /**
   *
   * @type {number}
   * @memberof StripePriceTransformQuantity
   */
  divide_by: number;
  /**
   *
   * @type {string}
   * @memberof StripePriceTransformQuantity
   */
  round: string;
}
/**
 *
 * @export
 * @interface StripeQuantity
 */
export interface StripeQuantity {
  /**
   *
   * @type {number}
   * @memberof StripeQuantity
   */
  divide_by: number;
  /**
   *
   * @type {string}
   * @memberof StripeQuantity
   */
  round: string;
}
/**
 *
 * @export
 * @interface TrunkComment
 */
export interface TrunkComment {
  /**
   *
   * @type {Array<Comment>}
   * @memberof TrunkComment
   */
  branch: Array<Comment>;
  /**
   *
   * @type {string}
   * @memberof TrunkComment
   */
  content: string;
  /**
   *
   * @type {KaseMember}
   * @memberof TrunkComment
   */
  poster: KaseMember;
  /**
   *
   * @type {number}
   * @memberof TrunkComment
   */
  kaseId: number;
  /**
   *
   * @type {boolean}
   * @memberof TrunkComment
   */
  isDeleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrunkComment
   */
  isAdminLike: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrunkComment
   */
  isUnread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrunkComment
   */
  isFavorite: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TrunkComment
   */
  hasEvaluationMemo: boolean;
  /**
   *
   * @type {Array<any>}
   * @memberof TrunkComment
   */
  files: Array<any>;
  /**
   *
   * @type {Array<PointComment>}
   * @memberof TrunkComment
   */
  points: Array<PointComment>;
  /**
   *
   * @type {Array<HideComment>}
   * @memberof TrunkComment
   */
  hideComments: Array<HideComment>;
  /**
   *
   * @type {Array<Reaction>}
   * @memberof TrunkComment
   */
  reactions: Array<Reaction>;
  /**
   *
   * @type {string}
   * @memberof TrunkComment
   */
  trunkCreatedAt: string;
  /**
   *
   * @type {string}
   * @memberof TrunkComment
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface UpdateAvatarAccountRequestBody
 */
export interface UpdateAvatarAccountRequestBody {
  /**
   *
   * @type {FileRequestBody}
   * @memberof UpdateAvatarAccountRequestBody
   */
  file: FileRequestBody;
}
/**
 *
 * @export
 * @interface UpdateCloseCommentRequestBody
 */
export interface UpdateCloseCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCloseCommentRequestBody
   */
  content: string;
}
/**
 *
 * @export
 * @interface UpdateCommentRequestBody
 */
export interface UpdateCommentRequestBody {
  /**
   *
   * @type {string}
   * @memberof UpdateCommentRequestBody
   */
  content: string;
}
/**
 *
 * @export
 * @interface UserAdminQuery
 */
export interface UserAdminQuery {
  /**
   *
   * @type {number}
   * @memberof UserAdminQuery
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  avatarUrl: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  accountPermission: UserAdminQueryAccountPermissionEnum;
  /**
   *
   * @type {number}
   * @memberof UserAdminQuery
   */
  dmId: number;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  status: UserAdminQueryStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  phoneNumber: string | null;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  jobDescription: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof UserAdminQuery
   */
  reviewStatus: UserAdminQueryReviewStatusEnum;
}

export const UserAdminQueryAccountPermissionEnum = {
  Challenger: 'CHALLENGER',
  SeniorChallenger: 'SENIOR_CHALLENGER',
  Admin: 'ADMIN',
} as const;

export type UserAdminQueryAccountPermissionEnum =
  typeof UserAdminQueryAccountPermissionEnum[keyof typeof UserAdminQueryAccountPermissionEnum];
export const UserAdminQueryStatusEnum = {
  Init: 'INIT',
  Onboarding: 'ONBOARDING',
  Active: 'ACTIVE',
  Left: 'LEFT',
  Suspending: 'SUSPENDING',
} as const;

export type UserAdminQueryStatusEnum =
  typeof UserAdminQueryStatusEnum[keyof typeof UserAdminQueryStatusEnum];
export const UserAdminQueryReviewStatusEnum = {
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type UserAdminQueryReviewStatusEnum =
  typeof UserAdminQueryReviewStatusEnum[keyof typeof UserAdminQueryReviewStatusEnum];

/**
 *
 * @export
 * @interface UserOnboarding
 */
export interface UserOnboarding {
  /**
   *
   * @type {string}
   * @memberof UserOnboarding
   */
  reviewStatus: UserOnboardingReviewStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserOnboarding
   */
  tutorialFinishedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof UserOnboarding
   */
  paymentRegisteredAt: string | null;
  /**
   *
   * @type {string}
   * @memberof UserOnboarding
   */
  filledOutUserDetailAt: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserOnboarding
   */
  isSkipPayment: boolean;
  /**
   *
   * @type {string}
   * @memberof UserOnboarding
   */
  onboardingStartedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof UserOnboarding
   */
  isInterviewRequired: boolean;
}

export const UserOnboardingReviewStatusEnum = {
  UnderReview: 'UNDER_REVIEW',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
} as const;

export type UserOnboardingReviewStatusEnum =
  typeof UserOnboardingReviewStatusEnum[keyof typeof UserOnboardingReviewStatusEnum];

/**
 *
 * @export
 * @interface UserUpdateRequestBody
 */
export interface UserUpdateRequestBody {
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  sirName: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  givenName: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  state: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  motivation: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  affiliation: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  occupation: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  jobDescription: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequestBody
   */
  accountPermission: string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateReflectionAnswerRequestBody} createReflectionAnswerRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerCreateReflectionAnswer: async (
      createReflectionAnswerRequestBody: CreateReflectionAnswerRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createReflectionAnswerRequestBody' is not null or undefined
      assertParamExists(
        'meControllerCreateReflectionAnswer',
        'createReflectionAnswerRequestBody',
        createReflectionAnswerRequestBody,
      );
      const localVarPath = `/me/reflection/answer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReflectionAnswerRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerIndexEvaluations: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/evaluations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowAccount: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowCurrentUser: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowPerformance: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/performance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowReflection: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/me/reflection`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AccountUpdateRequestBody} accountUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerUpdateAccount: async (
      accountUpdateRequestBody: AccountUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'accountUpdateRequestBody' is not null or undefined
      assertParamExists(
        'meControllerUpdateAccount',
        'accountUpdateRequestBody',
        accountUpdateRequestBody,
      );
      const localVarPath = `/me/account`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateAvatarAccountRequestBody} updateAvatarAccountRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerUpdateAvatar: async (
      updateAvatarAccountRequestBody: UpdateAvatarAccountRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAvatarAccountRequestBody' is not null or undefined
      assertParamExists(
        'meControllerUpdateAvatar',
        'updateAvatarAccountRequestBody',
        updateAvatarAccountRequestBody,
      );
      const localVarPath = `/me/avatar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAvatarAccountRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {NewUserApproveRequestBody} newUserApproveRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerApprove: async (
      userId: number,
      newUserApproveRequestBody: NewUserApproveRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('newUserControllerApprove', 'userId', userId);
      // verify required parameter 'newUserApproveRequestBody' is not null or undefined
      assertParamExists(
        'newUserControllerApprove',
        'newUserApproveRequestBody',
        newUserApproveRequestBody,
      );
      const localVarPath = `/new-users/{userId}/approve`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserApproveRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NewUserCreateRequestBody} newUserCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerCreate: async (
      newUserCreateRequestBody: NewUserCreateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'newUserCreateRequestBody' is not null or undefined
      assertParamExists(
        'newUserControllerCreate',
        'newUserCreateRequestBody',
        newUserCreateRequestBody,
      );
      const localVarPath = `/new-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserCreateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerDoneInterview: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('newUserControllerDoneInterview', 'userId', userId);
      const localVarPath = `/new-users/{userId}/done-interview`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerIndex: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/new-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerNeedInterview: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('newUserControllerNeedInterview', 'userId', userId);
      const localVarPath = `/new-users/{userId}/require-interview`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerReject: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('newUserControllerReject', 'userId', userId);
      const localVarPath = `/new-users/{userId}/reject`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<SignupWithE2ERequestBody>} signupWithE2ERequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerSignupWithE2E: async (
      signupWithE2ERequestBody: Array<SignupWithE2ERequestBody>,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'signupWithE2ERequestBody' is not null or undefined
      assertParamExists(
        'newUserControllerSignupWithE2E',
        'signupWithE2ERequestBody',
        signupWithE2ERequestBody,
      );
      const localVarPath = `/new-users/signup-with-e2e`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        signupWithE2ERequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerActivate: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('privateUserControllerActivate', 'userId', userId);
      const localVarPath = `/private-users/{userId}/activate`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerIndex: async (
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/private-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerShowEvaluations: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        'privateUserControllerShowEvaluations',
        'userId',
        userId,
      );
      const localVarPath = `/private-users/{userId}/evaluations`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdateRequestBody} userUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerUpdate: async (
      userId: number,
      userUpdateRequestBody: UserUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('privateUserControllerUpdate', 'userId', userId);
      // verify required parameter 'userUpdateRequestBody' is not null or undefined
      assertParamExists(
        'privateUserControllerUpdate',
        'userUpdateRequestBody',
        userUpdateRequestBody,
      );
      const localVarPath = `/private-users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithCognito: async (
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerConnectWithCognito',
        'connectWithUserAuthRequestBody',
        connectWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/connect-with-cognito`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithGoogle: async (
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerConnectWithGoogle',
        'connectWithUserAuthRequestBody',
        connectWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/connect-with-google`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithLine: async (
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'connectWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerConnectWithLine',
        'connectWithUserAuthRequestBody',
        connectWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/connect-with-line`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        connectWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithCognito: async (
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerLoginWithCognito',
        'loginWithUserAuthRequestBody',
        loginWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/login-with-cognito`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithE2E: async (
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerLoginWithE2E',
        'loginWithUserAuthRequestBody',
        loginWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/login-with-e2e`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithGoogle: async (
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerLoginWithGoogle',
        'loginWithUserAuthRequestBody',
        loginWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/login-with-google`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithLine: async (
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'loginWithUserAuthRequestBody' is not null or undefined
      assertParamExists(
        'userAuthControllerLoginWithLine',
        'loginWithUserAuthRequestBody',
        loginWithUserAuthRequestBody,
      );
      const localVarPath = `/user-auth/login-with-line`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginWithUserAuthRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLogout: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerRefresh: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/user-auth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateReflectionAnswerRequestBody} createReflectionAnswerRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerCreateReflectionAnswer(
      createReflectionAnswerRequestBody: CreateReflectionAnswerRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerCreateReflectionAnswer(
          createReflectionAnswerRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerIndexEvaluations(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeEvaluation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerIndexEvaluations(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerShowAccount(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerShowAccount(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerShowCurrentUser(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerShowCurrentUser(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerShowPerformance(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MePerformance>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerShowPerformance(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerShowReflection(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MeControllerShowReflection200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerShowReflection(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {AccountUpdateRequestBody} accountUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerUpdateAccount(
      accountUpdateRequestBody: AccountUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerUpdateAccount(
          accountUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UpdateAvatarAccountRequestBody} updateAvatarAccountRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meControllerUpdateAvatar(
      updateAvatarAccountRequestBody: UpdateAvatarAccountRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.meControllerUpdateAvatar(
          updateAvatarAccountRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {NewUserApproveRequestBody} newUserApproveRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerApprove(
      userId: number,
      newUserApproveRequestBody: NewUserApproveRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerApprove(
          userId,
          newUserApproveRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {NewUserCreateRequestBody} newUserCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerCreate(
      newUserCreateRequestBody: NewUserCreateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerCreate(
          newUserCreateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerDoneInterview(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerDoneInterview(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerIndex(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewUser>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerIndex(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerNeedInterview(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerNeedInterview(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerReject(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerReject(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<SignupWithE2ERequestBody>} signupWithE2ERequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newUserControllerSignupWithE2E(
      signupWithE2ERequestBody: Array<SignupWithE2ERequestBody>,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newUserControllerSignupWithE2E(
          signupWithE2ERequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateUserControllerActivate(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateUserControllerActivate(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateUserControllerIndex(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserAdminQuery>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateUserControllerIndex(
          xRequestId,
          keyword,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateUserControllerShowEvaluations(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeEvaluation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateUserControllerShowEvaluations(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdateRequestBody} userUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateUserControllerUpdate(
      userId: number,
      userUpdateRequestBody: UserUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateUserControllerUpdate(
          userId,
          userUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerConnectWithCognito(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerConnectWithCognito(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerConnectWithGoogle(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerConnectWithGoogle(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerConnectWithLine(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerConnectWithLine(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerLoginWithCognito(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MyAuthentication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerLoginWithCognito(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerLoginWithE2E(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MyAuthentication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerLoginWithE2E(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerLoginWithGoogle(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MyAuthentication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerLoginWithGoogle(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerLoginWithLine(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MyAuthentication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerLoginWithLine(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerLogout(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerLogout(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthControllerRefresh(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MyAuthentication>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userAuthControllerRefresh(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AccountsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateReflectionAnswerRequestBody} createReflectionAnswerRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerCreateReflectionAnswer(
      createReflectionAnswerRequestBody: CreateReflectionAnswerRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .meControllerCreateReflectionAnswer(
          createReflectionAnswerRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerIndexEvaluations(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MeEvaluation> {
      return localVarFp
        .meControllerIndexEvaluations(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowAccount(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Account> {
      return localVarFp
        .meControllerShowAccount(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowCurrentUser(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CurrentUser> {
      return localVarFp
        .meControllerShowCurrentUser(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowPerformance(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MePerformance> {
      return localVarFp
        .meControllerShowPerformance(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerShowReflection(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MeControllerShowReflection200Response> {
      return localVarFp
        .meControllerShowReflection(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {AccountUpdateRequestBody} accountUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerUpdateAccount(
      accountUpdateRequestBody: AccountUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .meControllerUpdateAccount(
          accountUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateAvatarAccountRequestBody} updateAvatarAccountRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meControllerUpdateAvatar(
      updateAvatarAccountRequestBody: UpdateAvatarAccountRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .meControllerUpdateAvatar(
          updateAvatarAccountRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {NewUserApproveRequestBody} newUserApproveRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerApprove(
      userId: number,
      newUserApproveRequestBody: NewUserApproveRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerApprove(
          userId,
          newUserApproveRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewUserCreateRequestBody} newUserCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerCreate(
      newUserCreateRequestBody: NewUserCreateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerCreate(newUserCreateRequestBody, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerDoneInterview(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerDoneInterview(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerIndex(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<NewUser>> {
      return localVarFp
        .newUserControllerIndex(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerNeedInterview(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerNeedInterview(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerReject(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerReject(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<SignupWithE2ERequestBody>} signupWithE2ERequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newUserControllerSignupWithE2E(
      signupWithE2ERequestBody: Array<SignupWithE2ERequestBody>,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newUserControllerSignupWithE2E(
          signupWithE2ERequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerActivate(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .privateUserControllerActivate(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerIndex(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<Array<UserAdminQuery>> {
      return localVarFp
        .privateUserControllerIndex(xRequestId, keyword, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerShowEvaluations(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MeEvaluation> {
      return localVarFp
        .privateUserControllerShowEvaluations(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {UserUpdateRequestBody} userUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateUserControllerUpdate(
      userId: number,
      userUpdateRequestBody: UserUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .privateUserControllerUpdate(
          userId,
          userUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithCognito(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .userAuthControllerConnectWithCognito(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithGoogle(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .userAuthControllerConnectWithGoogle(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerConnectWithLine(
      connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .userAuthControllerConnectWithLine(
          connectWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithCognito(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MyAuthentication> {
      return localVarFp
        .userAuthControllerLoginWithCognito(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithE2E(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MyAuthentication> {
      return localVarFp
        .userAuthControllerLoginWithE2E(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithGoogle(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MyAuthentication> {
      return localVarFp
        .userAuthControllerLoginWithGoogle(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLoginWithLine(
      loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MyAuthentication> {
      return localVarFp
        .userAuthControllerLoginWithLine(
          loginWithUserAuthRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerLogout(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .userAuthControllerLogout(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthControllerRefresh(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<MyAuthentication> {
      return localVarFp
        .userAuthControllerRefresh(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
  /**
   *
   * @param {CreateReflectionAnswerRequestBody} createReflectionAnswerRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerCreateReflectionAnswer(
    createReflectionAnswerRequestBody: CreateReflectionAnswerRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerCreateReflectionAnswer(
        createReflectionAnswerRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerIndexEvaluations(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerIndexEvaluations(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerShowAccount(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerShowAccount(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerShowCurrentUser(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerShowCurrentUser(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerShowPerformance(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerShowPerformance(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerShowReflection(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerShowReflection(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AccountUpdateRequestBody} accountUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerUpdateAccount(
    accountUpdateRequestBody: AccountUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerUpdateAccount(accountUpdateRequestBody, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateAvatarAccountRequestBody} updateAvatarAccountRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public meControllerUpdateAvatar(
    updateAvatarAccountRequestBody: UpdateAvatarAccountRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .meControllerUpdateAvatar(
        updateAvatarAccountRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {NewUserApproveRequestBody} newUserApproveRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerApprove(
    userId: number,
    newUserApproveRequestBody: NewUserApproveRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerApprove(
        userId,
        newUserApproveRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewUserCreateRequestBody} newUserCreateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerCreate(
    newUserCreateRequestBody: NewUserCreateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerCreate(newUserCreateRequestBody, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerDoneInterview(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerDoneInterview(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerIndex(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerIndex(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerNeedInterview(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerNeedInterview(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerReject(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerReject(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<SignupWithE2ERequestBody>} signupWithE2ERequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public newUserControllerSignupWithE2E(
    signupWithE2ERequestBody: Array<SignupWithE2ERequestBody>,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .newUserControllerSignupWithE2E(
        signupWithE2ERequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public privateUserControllerActivate(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .privateUserControllerActivate(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {string} [keyword]
   * @param {string} [offset]
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public privateUserControllerIndex(
    xRequestId?: string,
    keyword?: string,
    offset?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .privateUserControllerIndex(xRequestId, keyword, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public privateUserControllerShowEvaluations(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .privateUserControllerShowEvaluations(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {UserUpdateRequestBody} userUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public privateUserControllerUpdate(
    userId: number,
    userUpdateRequestBody: UserUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .privateUserControllerUpdate(
        userId,
        userUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerConnectWithCognito(
    connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerConnectWithCognito(
        connectWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerConnectWithGoogle(
    connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerConnectWithGoogle(
        connectWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ConnectWithUserAuthRequestBody} connectWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerConnectWithLine(
    connectWithUserAuthRequestBody: ConnectWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerConnectWithLine(
        connectWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerLoginWithCognito(
    loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerLoginWithCognito(
        loginWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerLoginWithE2E(
    loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerLoginWithE2E(
        loginWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerLoginWithGoogle(
    loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerLoginWithGoogle(
        loginWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginWithUserAuthRequestBody} loginWithUserAuthRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerLoginWithLine(
    loginWithUserAuthRequestBody: LoginWithUserAuthRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerLoginWithLine(
        loginWithUserAuthRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerLogout(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerLogout(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public userAuthControllerRefresh(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AccountsApiFp(this.configuration)
      .userAuthControllerRefresh(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckHealth: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/healthcheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckLiveness: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/liveness`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckReadiness: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/readiness`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestDelete: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestGet: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPost: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPostError400: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test-error-400`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPostError500: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test-error-500`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPut: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerCheckHealth(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerCheckHealth(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerCheckLiveness(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerCheckLiveness(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerCheckReadiness(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerCheckReadiness(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestDelete(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestDelete(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestGet(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestGet(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestPost(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestPost(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestPostError400(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestPostError400(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestPostError500(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestPostError500(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerTestPut(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.appControllerTestPut(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckHealth(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerCheckHealth(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckLiveness(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerCheckLiveness(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerCheckReadiness(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerCheckReadiness(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestDelete(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestDelete(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestGet(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestGet(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPost(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestPost(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPostError400(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestPostError400(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPostError500(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestPostError500(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerTestPut(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .appControllerTestPut(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerCheckHealth(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerCheckHealth(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerCheckLiveness(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerCheckLiveness(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerCheckReadiness(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerCheckReadiness(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestDelete(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestDelete(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestGet(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestGet(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestPost(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestPost(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestPostError400(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestPostError400(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestPostError500(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestPostError500(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppsApi
   */
  public appControllerTestPut(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AppsApiFp(this.configuration)
      .appControllerTestPut(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchControllerCloseKases: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/batch/cases/archive`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchControllerCreateReflections: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/batch/reflections/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchControllerCloseKases(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.batchControllerCloseKases(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchControllerCreateReflections(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.batchControllerCreateReflections(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BatchApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchControllerCloseKases(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .batchControllerCloseKases(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchControllerCreateReflections(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .batchControllerCreateReflections(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchApi
   */
  public batchControllerCloseKases(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return BatchApiFp(this.configuration)
      .batchControllerCloseKases(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchApi
   */
  public batchControllerCreateReflections(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return BatchApiFp(this.configuration)
      .batchControllerCreateReflections(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CasesApi - axios parameter creator
 * @export
 */
export const CasesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerAdminCreate: async (
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerAdminCreate', 'kaseId', kaseId);
      // verify required parameter 'createApplyCommentRequestBody' is not null or undefined
      assertParamExists(
        'applyCommentControllerAdminCreate',
        'createApplyCommentRequestBody',
        createApplyCommentRequestBody,
      );
      const localVarPath = `/new-cases/{kaseId}/admin-apply-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplyCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerAdminIndex: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerAdminIndex', 'kaseId', kaseId);
      const localVarPath = `/new-cases/{kaseId}/admin-apply-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerCreate: async (
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerCreate', 'kaseId', kaseId);
      // verify required parameter 'createApplyCommentRequestBody' is not null or undefined
      assertParamExists(
        'applyCommentControllerCreate',
        'createApplyCommentRequestBody',
        createApplyCommentRequestBody,
      );
      const localVarPath = `/new-cases/{kaseId}/apply-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplyCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerDelete: async (
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerDelete', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists('applyCommentControllerDelete', 'createdAt', createdAt);
      const localVarPath = `/new-cases/{kaseId}/apply-comments/{createdAt}`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerIndex: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerIndex', 'kaseId', kaseId);
      const localVarPath = `/new-cases/{kaseId}/apply-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerReadByAdmin: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerReadByAdmin', 'kaseId', kaseId);
      const localVarPath =
        `/new-cases/{kaseId}/apply-comments/readby-admin`.replace(
          `{${'kaseId'}}`,
          encodeURIComponent(String(kaseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerReadByOwner: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerReadByOwner', 'kaseId', kaseId);
      const localVarPath =
        `/new-cases/{kaseId}/apply-comments/readby-owner`.replace(
          `{${'kaseId'}}`,
          encodeURIComponent(String(kaseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerUpdate: async (
      kaseId: number,
      createdAt: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('applyCommentControllerUpdate', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists('applyCommentControllerUpdate', 'createdAt', createdAt);
      // verify required parameter 'createApplyCommentRequestBody' is not null or undefined
      assertParamExists(
        'applyCommentControllerUpdate',
        'createApplyCommentRequestBody',
        createApplyCommentRequestBody,
      );
      const localVarPath = `/new-cases/{kaseId}/apply-comments/{createdAt}`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createApplyCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateCloseCommentRequestBody} createCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerCreate: async (
      kaseId: number,
      createCloseCommentRequestBody: CreateCloseCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('closeCommentControllerCreate', 'kaseId', kaseId);
      // verify required parameter 'createCloseCommentRequestBody' is not null or undefined
      assertParamExists(
        'closeCommentControllerCreate',
        'createCloseCommentRequestBody',
        createCloseCommentRequestBody,
      );
      const localVarPath = `/new-case-closes/{kaseId}/close-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCloseCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerDelete: async (
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('closeCommentControllerDelete', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists('closeCommentControllerDelete', 'createdAt', createdAt);
      const localVarPath =
        `/new-case-closes/{kaseId}/close-comments/{createdAt}`
          .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
          .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerIndex: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('closeCommentControllerIndex', 'kaseId', kaseId);
      const localVarPath = `/new-case-closes/{kaseId}/close-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerReadCloseComments: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'closeCommentControllerReadCloseComments',
        'kaseId',
        kaseId,
      );
      const localVarPath =
        `/new-case-closes/{kaseId}/close-comments/read`.replace(
          `{${'kaseId'}}`,
          encodeURIComponent(String(kaseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCloseCommentRequestBody} updateCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerUpdate: async (
      kaseId: number,
      createdAt: number,
      updateCloseCommentRequestBody: UpdateCloseCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('closeCommentControllerUpdate', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists('closeCommentControllerUpdate', 'createdAt', createdAt);
      // verify required parameter 'updateCloseCommentRequestBody' is not null or undefined
      assertParamExists(
        'closeCommentControllerUpdate',
        'updateCloseCommentRequestBody',
        updateCloseCommentRequestBody,
      );
      const localVarPath =
        `/new-case-closes/{kaseId}/close-comments/{createdAt}`
          .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
          .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCloseCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateAdminLikeCommentRequestBody} createAdminLikeCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveAdminLike: async (
      kaseId: number,
      createdAt: number,
      createAdminLikeCommentRequestBody: CreateAdminLikeCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveAdminLike',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveAdminLike',
        'createdAt',
        createdAt,
      );
      // verify required parameter 'createAdminLikeCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveAdminLike',
        'createAdminLikeCommentRequestBody',
        createAdminLikeCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/comments/{createdAt}/admin-like`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAdminLikeCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateFavoriteCommentRequestBody} createFavoriteCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveFavorite: async (
      kaseId: number,
      createdAt: number,
      createFavoriteCommentRequestBody: CreateFavoriteCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveFavorite',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveFavorite',
        'createdAt',
        createdAt,
      );
      // verify required parameter 'createFavoriteCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveFavorite',
        'createFavoriteCommentRequestBody',
        createFavoriteCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/comments/{createdAt}/favorite`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFavoriteCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateReactionCommentRequestBody} createReactionCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveReaction: async (
      kaseId: number,
      createdAt: number,
      createReactionCommentRequestBody: CreateReactionCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveReaction',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveReaction',
        'createdAt',
        createdAt,
      );
      // verify required parameter 'createReactionCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerAddOrRemoveReaction',
        'createReactionCommentRequestBody',
        createReactionCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/comments/{createdAt}/reaction`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReactionCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerClearAllUnreadComments: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerClearAllUnreadComments',
        'kaseId',
        kaseId,
      );
      const localVarPath = `/cases/{kaseId}/unread-comments/clear`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateBranchCommentRequestBody} createBranchCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateBranchComment: async (
      kaseId: number,
      createBranchCommentRequestBody: CreateBranchCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerCreateBranchComment',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createBranchCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerCreateBranchComment',
        'createBranchCommentRequestBody',
        createBranchCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/branch-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createBranchCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateHideCommentRequestBody} createHideCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateHideComment: async (
      kaseId: number,
      createHideCommentRequestBody: CreateHideCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('commentControllerCreateHideComment', 'kaseId', kaseId);
      // verify required parameter 'createHideCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerCreateHideComment',
        'createHideCommentRequestBody',
        createHideCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/hide-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createHideCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreatePointCommentRequestBody} createPointCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreatePointComment: async (
      kaseId: number,
      createPointCommentRequestBody: CreatePointCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerCreatePointComment',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createPointCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerCreatePointComment',
        'createPointCommentRequestBody',
        createPointCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/point-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPointCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateTrunkCommentRequestBody} createTrunkCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateTrunkComment: async (
      kaseId: number,
      createTrunkCommentRequestBody: CreateTrunkCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerCreateTrunkComment',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createTrunkCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerCreateTrunkComment',
        'createTrunkCommentRequestBody',
        createTrunkCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/trunk-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTrunkCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerDelete: async (
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('commentControllerDelete', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists('commentControllerDelete', 'createdAt', createdAt);
      const localVarPath = `/cases/{kaseId}/comments/{createdAt}`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerDeleteTrunkComment: async (
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerDeleteTrunkComment',
        'kaseId',
        kaseId,
      );
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists(
        'commentControllerDeleteTrunkComment',
        'createdAt',
        createdAt,
      );
      const localVarPath = `/cases/{kaseId}/trunk-comments/{createdAt}`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerIndex: async (
      kaseId: number,
      xRequestId?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('commentControllerIndex', 'kaseId', kaseId);
      const localVarPath = `/cases/{kaseId}/trunks`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {Array<ReadCommentRequestBody>} readCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerMarkAsRead: async (
      kaseId: number,
      readCommentRequestBody: Array<ReadCommentRequestBody>,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('commentControllerMarkAsRead', 'kaseId', kaseId);
      // verify required parameter 'readCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerMarkAsRead',
        'readCommentRequestBody',
        readCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/comments/read`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        readCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerShowFavoriteComments: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerShowFavoriteComments',
        'kaseId',
        kaseId,
      );
      const localVarPath = `/cases/{kaseId}/favorite-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerShowUnreadComments: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists(
        'commentControllerShowUnreadComments',
        'kaseId',
        kaseId,
      );
      const localVarPath = `/cases/{kaseId}/unread-comments`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCommentRequestBody} updateCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerUpdateContent: async (
      kaseId: number,
      createdAt: number,
      updateCommentRequestBody: UpdateCommentRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('commentControllerUpdateContent', 'kaseId', kaseId);
      // verify required parameter 'createdAt' is not null or undefined
      assertParamExists(
        'commentControllerUpdateContent',
        'createdAt',
        createdAt,
      );
      // verify required parameter 'updateCommentRequestBody' is not null or undefined
      assertParamExists(
        'commentControllerUpdateContent',
        'updateCommentRequestBody',
        updateCommentRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/comments/{createdAt}`
        .replace(`{${'kaseId'}}`, encodeURIComponent(String(kaseId)))
        .replace(`{${'createdAt'}}`, encodeURIComponent(String(createdAt)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCommentRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateEvaluationMemoRequestBody} createEvaluationMemoRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerCreate: async (
      kaseId: number,
      createEvaluationMemoRequestBody: CreateEvaluationMemoRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('evaluationMemoControllerCreate', 'kaseId', kaseId);
      // verify required parameter 'createEvaluationMemoRequestBody' is not null or undefined
      assertParamExists(
        'evaluationMemoControllerCreate',
        'createEvaluationMemoRequestBody',
        createEvaluationMemoRequestBody,
      );
      const localVarPath =
        `/cases/{kaseId}/block-comments/evaluation-memos`.replace(
          `{${'kaseId'}}`,
          encodeURIComponent(String(kaseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEvaluationMemoRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerIndex: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('evaluationMemoControllerIndex', 'kaseId', kaseId);
      const localVarPath = `/cases/{kaseId}/all-evaluation-memos`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} trunkCreatedAt
     * @param {string} branchCreatedAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerShow: async (
      kaseId: number,
      trunkCreatedAt: string,
      branchCreatedAt: string,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('evaluationMemoControllerShow', 'kaseId', kaseId);
      // verify required parameter 'trunkCreatedAt' is not null or undefined
      assertParamExists(
        'evaluationMemoControllerShow',
        'trunkCreatedAt',
        trunkCreatedAt,
      );
      // verify required parameter 'branchCreatedAt' is not null or undefined
      assertParamExists(
        'evaluationMemoControllerShow',
        'branchCreatedAt',
        branchCreatedAt,
      );
      const localVarPath =
        `/cases/{kaseId}/block-comments/evaluation-memos`.replace(
          `{${'kaseId'}}`,
          encodeURIComponent(String(kaseId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (trunkCreatedAt !== undefined) {
        localVarQueryParameter['trunkCreatedAt'] =
          (trunkCreatedAt as any) instanceof Date
            ? (trunkCreatedAt as any).toISOString()
            : trunkCreatedAt;
      }

      if (branchCreatedAt !== undefined) {
        localVarQueryParameter['branchCreatedAt'] =
          (branchCreatedAt as any) instanceof Date
            ? (branchCreatedAt as any).toISOString()
            : branchCreatedAt;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerSearch: async (
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cases/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword;
      }

      if (participantRoles) {
        localVarQueryParameter['participant-roles[]'] = participantRoles;
      }

      if (statuses) {
        localVarQueryParameter['statuses[]'] = statuses;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShow: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('kaseControllerShow', 'kaseId', kaseId);
      const localVarPath = `/cases/{kaseId}`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowCc: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cases/cc`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowDevelopClub: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cases/develop-club`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowSaseboCommunity: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cases/sasebo-community`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<string>} kaseIds
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseUnreadCountControllerGetKaseUnreadCounts: async (
      kaseIds: Array<string>,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseIds' is not null or undefined
      assertParamExists(
        'kaseUnreadCountControllerGetKaseUnreadCounts',
        'kaseIds',
        kaseIds,
      );
      const localVarPath = `/case-unread-counts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (kaseIds) {
        localVarQueryParameter['kase-ids[]'] = kaseIds;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lanterKaseControllerIndex: async (
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/lanter-cases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lanterKaseControllerSearch: async (
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/lanter-cases/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerApprove: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('newKaseClosesControllerApprove', 'kaseId', kaseId);
      const localVarPath = `/new-case-closes/{kaseId}/approve`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerUpsert: async (
      kaseId: number,
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('newKaseClosesControllerUpsert', 'kaseId', kaseId);
      // verify required parameter 'newKaseCloseUpdateRequestBody' is not null or undefined
      assertParamExists(
        'newKaseClosesControllerUpsert',
        'newKaseCloseUpdateRequestBody',
        newKaseCloseUpdateRequestBody,
      );
      const localVarPath = `/new-case-closes/{kaseId}`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newKaseCloseUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerUpsertOld: async (
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'newKaseCloseUpdateRequestBody' is not null or undefined
      assertParamExists(
        'newKaseClosesControllerUpsertOld',
        'newKaseCloseUpdateRequestBody',
        newKaseCloseUpdateRequestBody,
      );
      const localVarPath = `/new-case-closes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newKaseCloseUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerApprove: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('newKaseControllerApprove', 'kaseId', kaseId);
      const localVarPath = `/new-cases/{kaseId}/approve`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {NewKaseCreateRequestBody} newKaseCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerCreate: async (
      newKaseCreateRequestBody: NewKaseCreateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'newKaseCreateRequestBody' is not null or undefined
      assertParamExists(
        'newKaseControllerCreate',
        'newKaseCreateRequestBody',
        newKaseCreateRequestBody,
      );
      const localVarPath = `/new-cases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newKaseCreateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerIndex: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/new-cases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerShowMe: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/new-cases/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseUpdateRequestBody} newKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerUpdate: async (
      kaseId: number,
      newKaseUpdateRequestBody: NewKaseUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('newKaseControllerUpdate', 'kaseId', kaseId);
      // verify required parameter 'newKaseUpdateRequestBody' is not null or undefined
      assertParamExists(
        'newKaseControllerUpdate',
        'newKaseUpdateRequestBody',
        newKaseUpdateRequestBody,
      );
      const localVarPath = `/new-cases/{kaseId}`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newKaseUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateOwnerEvaluationRequestBody} createOwnerEvaluationRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ownerEvaluationsControllerCreate: async (
      kaseId: number,
      createOwnerEvaluationRequestBody: CreateOwnerEvaluationRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('ownerEvaluationsControllerCreate', 'kaseId', kaseId);
      // verify required parameter 'createOwnerEvaluationRequestBody' is not null or undefined
      assertParamExists(
        'ownerEvaluationsControllerCreate',
        'createOwnerEvaluationRequestBody',
        createOwnerEvaluationRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/owner-evaluations`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOwnerEvaluationRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ownerEvaluationsControllerIndex: async (
      kaseId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('ownerEvaluationsControllerIndex', 'kaseId', kaseId);
      const localVarPath = `/cases/{kaseId}/owner-evaluations`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {RegisterParticipantRequestBody} registerParticipantRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantListsControllerRegister: async (
      kaseId: number,
      registerParticipantRequestBody: RegisterParticipantRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('participantListsControllerRegister', 'kaseId', kaseId);
      // verify required parameter 'registerParticipantRequestBody' is not null or undefined
      assertParamExists(
        'participantListsControllerRegister',
        'registerParticipantRequestBody',
        registerParticipantRequestBody,
      );
      const localVarPath = `/cases/{kaseId}/register`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerParticipantRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateKaseControllerSearch: async (
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/private-cases/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword;
      }

      if (participantRoles) {
        localVarQueryParameter['participant-roles[]'] = participantRoles;
      }

      if (statuses) {
        localVarQueryParameter['statuses[]'] = statuses;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} kaseId
     * @param {PrivateKaseUpdateRequestBody} privateKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateKaseControllerUpdate: async (
      kaseId: number,
      privateKaseUpdateRequestBody: PrivateKaseUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kaseId' is not null or undefined
      assertParamExists('privateKaseControllerUpdate', 'kaseId', kaseId);
      // verify required parameter 'privateKaseUpdateRequestBody' is not null or undefined
      assertParamExists(
        'privateKaseControllerUpdate',
        'privateKaseUpdateRequestBody',
        privateKaseUpdateRequestBody,
      );
      const localVarPath = `/private-cases/{kaseId}`.replace(
        `{${'kaseId'}}`,
        encodeURIComponent(String(kaseId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        privateKaseUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CasesApi - functional programming interface
 * @export
 */
export const CasesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CasesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerAdminCreate(
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplyCommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerAdminCreate(
          kaseId,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerAdminIndex(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplyComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerAdminIndex(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerCreate(
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplyCommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerCreate(
          kaseId,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerDelete(
          kaseId,
          createdAt,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ApplyComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerIndex(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerReadByAdmin(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerReadByAdmin(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerReadByOwner(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerReadByOwner(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyCommentControllerUpdate(
      kaseId: number,
      createdAt: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyCommentControllerUpdate(
          kaseId,
          createdAt,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateCloseCommentRequestBody} createCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeCommentControllerCreate(
      kaseId: number,
      createCloseCommentRequestBody: CreateCloseCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CloseCommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeCommentControllerCreate(
          kaseId,
          createCloseCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeCommentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeCommentControllerDelete(
          kaseId,
          createdAt,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeCommentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CloseComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeCommentControllerIndex(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeCommentControllerReadCloseComments(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeCommentControllerReadCloseComments(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCloseCommentRequestBody} updateCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeCommentControllerUpdate(
      kaseId: number,
      createdAt: number,
      updateCloseCommentRequestBody: UpdateCloseCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.closeCommentControllerUpdate(
          kaseId,
          createdAt,
          updateCloseCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateAdminLikeCommentRequestBody} createAdminLikeCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerAddOrRemoveAdminLike(
      kaseId: number,
      createdAt: number,
      createAdminLikeCommentRequestBody: CreateAdminLikeCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerAddOrRemoveAdminLike(
          kaseId,
          createdAt,
          createAdminLikeCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateFavoriteCommentRequestBody} createFavoriteCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerAddOrRemoveFavorite(
      kaseId: number,
      createdAt: number,
      createFavoriteCommentRequestBody: CreateFavoriteCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerAddOrRemoveFavorite(
          kaseId,
          createdAt,
          createFavoriteCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateReactionCommentRequestBody} createReactionCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerAddOrRemoveReaction(
      kaseId: number,
      createdAt: number,
      createReactionCommentRequestBody: CreateReactionCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerAddOrRemoveReaction(
          kaseId,
          createdAt,
          createReactionCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerClearAllUnreadComments(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerClearAllUnreadComments(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateBranchCommentRequestBody} createBranchCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerCreateBranchComment(
      kaseId: number,
      createBranchCommentRequestBody: CreateBranchCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerCreateBranchComment(
          kaseId,
          createBranchCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateHideCommentRequestBody} createHideCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerCreateHideComment(
      kaseId: number,
      createHideCommentRequestBody: CreateHideCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerCreateHideComment(
          kaseId,
          createHideCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreatePointCommentRequestBody} createPointCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerCreatePointComment(
      kaseId: number,
      createPointCommentRequestBody: CreatePointCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerCreatePointComment(
          kaseId,
          createPointCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateTrunkCommentRequestBody} createTrunkCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerCreateTrunkComment(
      kaseId: number,
      createTrunkCommentRequestBody: CreateTrunkCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CommentCreatedAt>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerCreateTrunkComment(
          kaseId,
          createTrunkCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerDelete(
          kaseId,
          createdAt,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerDeleteTrunkComment(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerDeleteTrunkComment(
          kaseId,
          createdAt,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TrunkComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerIndex(
          kaseId,
          xRequestId,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {Array<ReadCommentRequestBody>} readCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerMarkAsRead(
      kaseId: number,
      readCommentRequestBody: Array<ReadCommentRequestBody>,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerMarkAsRead(
          kaseId,
          readCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerShowFavoriteComments(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RawComment>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerShowFavoriteComments(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerShowUnreadComments(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerShowUnreadComments(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCommentRequestBody} updateCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentControllerUpdateContent(
      kaseId: number,
      createdAt: number,
      updateCommentRequestBody: UpdateCommentRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commentControllerUpdateContent(
          kaseId,
          createdAt,
          updateCommentRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateEvaluationMemoRequestBody} createEvaluationMemoRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evaluationMemoControllerCreate(
      kaseId: number,
      createEvaluationMemoRequestBody: CreateEvaluationMemoRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.evaluationMemoControllerCreate(
          kaseId,
          createEvaluationMemoRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evaluationMemoControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EvaluationMemo>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.evaluationMemoControllerIndex(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} trunkCreatedAt
     * @param {string} branchCreatedAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async evaluationMemoControllerShow(
      kaseId: number,
      trunkCreatedAt: string,
      branchCreatedAt: string,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EvaluationMemoControllerShow200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.evaluationMemoControllerShow(
          kaseId,
          trunkCreatedAt,
          branchCreatedAt,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<KaseChallengerQuery>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseControllerSearch(
          xRequestId,
          keyword,
          participantRoles,
          statuses,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseControllerShow(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseControllerShow(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseControllerShowCc(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseControllerShowCc(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseControllerShowDevelopClub(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseControllerShowDevelopClub(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseControllerShowSaseboCommunity(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseControllerShowSaseboCommunity(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<string>} kaseIds
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async kaseUnreadCountControllerGetKaseUnreadCounts(
      kaseIds: Array<string>,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<KaseUnreadCount>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.kaseUnreadCountControllerGetKaseUnreadCounts(
          kaseIds,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lanterKaseControllerIndex(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseAdminQuery>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.lanterKaseControllerIndex(
          xRequestId,
          keyword,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lanterKaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<KaseAdminQuery>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.lanterKaseControllerSearch(
          xRequestId,
          keyword,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseClosesControllerApprove(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseClosesControllerApprove(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseClosesControllerUpsert(
      kaseId: number,
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewKaseClose>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseClosesControllerUpsert(
          kaseId,
          newKaseCloseUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseClosesControllerUpsertOld(
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseClosesControllerUpsertOld(
          newKaseCloseUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseControllerApprove(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseControllerApprove(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {NewKaseCreateRequestBody} newKaseCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseControllerCreate(
      newKaseCreateRequestBody: NewKaseCreateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseControllerCreate(
          newKaseCreateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseControllerIndex(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewKase>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseControllerIndex(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseControllerShowMe(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NewKaseControllerShowMe200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseControllerShowMe(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseUpdateRequestBody} newKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newKaseControllerUpdate(
      kaseId: number,
      newKaseUpdateRequestBody: NewKaseUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.newKaseControllerUpdate(
          kaseId,
          newKaseUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateOwnerEvaluationRequestBody} createOwnerEvaluationRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ownerEvaluationsControllerCreate(
      kaseId: number,
      createOwnerEvaluationRequestBody: CreateOwnerEvaluationRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ownerEvaluationsControllerCreate(
          kaseId,
          createOwnerEvaluationRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ownerEvaluationsControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<OwnerEvaluationsControllerIndex200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.ownerEvaluationsControllerIndex(
          kaseId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {RegisterParticipantRequestBody} registerParticipantRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantListsControllerRegister(
      kaseId: number,
      registerParticipantRequestBody: RegisterParticipantRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.participantListsControllerRegister(
          kaseId,
          registerParticipantRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateKaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<KaseAdminQuery>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateKaseControllerSearch(
          xRequestId,
          keyword,
          participantRoles,
          statuses,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} kaseId
     * @param {PrivateKaseUpdateRequestBody} privateKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async privateKaseControllerUpdate(
      kaseId: number,
      privateKaseUpdateRequestBody: PrivateKaseUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.privateKaseControllerUpdate(
          kaseId,
          privateKaseUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CasesApi - factory interface
 * @export
 */
export const CasesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CasesApiFp(configuration);
  return {
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerAdminCreate(
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<ApplyCommentCreatedAt> {
      return localVarFp
        .applyCommentControllerAdminCreate(
          kaseId,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerAdminIndex(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<ApplyComment>> {
      return localVarFp
        .applyCommentControllerAdminIndex(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerCreate(
      kaseId: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<ApplyCommentCreatedAt> {
      return localVarFp
        .applyCommentControllerCreate(
          kaseId,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applyCommentControllerDelete(kaseId, createdAt, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<ApplyComment>> {
      return localVarFp
        .applyCommentControllerIndex(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerReadByAdmin(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applyCommentControllerReadByAdmin(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerReadByOwner(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applyCommentControllerReadByOwner(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyCommentControllerUpdate(
      kaseId: number,
      createdAt: number,
      createApplyCommentRequestBody: CreateApplyCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applyCommentControllerUpdate(
          kaseId,
          createdAt,
          createApplyCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateCloseCommentRequestBody} createCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerCreate(
      kaseId: number,
      createCloseCommentRequestBody: CreateCloseCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CloseCommentCreatedAt> {
      return localVarFp
        .closeCommentControllerCreate(
          kaseId,
          createCloseCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .closeCommentControllerDelete(kaseId, createdAt, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<CloseComment>> {
      return localVarFp
        .closeCommentControllerIndex(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerReadCloseComments(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .closeCommentControllerReadCloseComments(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCloseCommentRequestBody} updateCloseCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeCommentControllerUpdate(
      kaseId: number,
      createdAt: number,
      updateCloseCommentRequestBody: UpdateCloseCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .closeCommentControllerUpdate(
          kaseId,
          createdAt,
          updateCloseCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateAdminLikeCommentRequestBody} createAdminLikeCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveAdminLike(
      kaseId: number,
      createdAt: number,
      createAdminLikeCommentRequestBody: CreateAdminLikeCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerAddOrRemoveAdminLike(
          kaseId,
          createdAt,
          createAdminLikeCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateFavoriteCommentRequestBody} createFavoriteCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveFavorite(
      kaseId: number,
      createdAt: number,
      createFavoriteCommentRequestBody: CreateFavoriteCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerAddOrRemoveFavorite(
          kaseId,
          createdAt,
          createFavoriteCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {CreateReactionCommentRequestBody} createReactionCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerAddOrRemoveReaction(
      kaseId: number,
      createdAt: number,
      createReactionCommentRequestBody: CreateReactionCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerAddOrRemoveReaction(
          kaseId,
          createdAt,
          createReactionCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerClearAllUnreadComments(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerClearAllUnreadComments(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateBranchCommentRequestBody} createBranchCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateBranchComment(
      kaseId: number,
      createBranchCommentRequestBody: CreateBranchCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CommentCreatedAt> {
      return localVarFp
        .commentControllerCreateBranchComment(
          kaseId,
          createBranchCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateHideCommentRequestBody} createHideCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateHideComment(
      kaseId: number,
      createHideCommentRequestBody: CreateHideCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CommentCreatedAt> {
      return localVarFp
        .commentControllerCreateHideComment(
          kaseId,
          createHideCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreatePointCommentRequestBody} createPointCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreatePointComment(
      kaseId: number,
      createPointCommentRequestBody: CreatePointCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CommentCreatedAt> {
      return localVarFp
        .commentControllerCreatePointComment(
          kaseId,
          createPointCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateTrunkCommentRequestBody} createTrunkCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerCreateTrunkComment(
      kaseId: number,
      createTrunkCommentRequestBody: CreateTrunkCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<CommentCreatedAt> {
      return localVarFp
        .commentControllerCreateTrunkComment(
          kaseId,
          createTrunkCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerDelete(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerDelete(kaseId, createdAt, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerDeleteTrunkComment(
      kaseId: number,
      createdAt: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerDeleteTrunkComment(
          kaseId,
          createdAt,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerIndex(
      kaseId: number,
      xRequestId?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<Array<TrunkComment>> {
      return localVarFp
        .commentControllerIndex(kaseId, xRequestId, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {Array<ReadCommentRequestBody>} readCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerMarkAsRead(
      kaseId: number,
      readCommentRequestBody: Array<ReadCommentRequestBody>,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerMarkAsRead(
          kaseId,
          readCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerShowFavoriteComments(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<RawComment>> {
      return localVarFp
        .commentControllerShowFavoriteComments(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerShowUnreadComments(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .commentControllerShowUnreadComments(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {number} createdAt
     * @param {UpdateCommentRequestBody} updateCommentRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentControllerUpdateContent(
      kaseId: number,
      createdAt: number,
      updateCommentRequestBody: UpdateCommentRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .commentControllerUpdateContent(
          kaseId,
          createdAt,
          updateCommentRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateEvaluationMemoRequestBody} createEvaluationMemoRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerCreate(
      kaseId: number,
      createEvaluationMemoRequestBody: CreateEvaluationMemoRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .evaluationMemoControllerCreate(
          kaseId,
          createEvaluationMemoRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<EvaluationMemo>> {
      return localVarFp
        .evaluationMemoControllerIndex(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} trunkCreatedAt
     * @param {string} branchCreatedAt
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    evaluationMemoControllerShow(
      kaseId: number,
      trunkCreatedAt: string,
      branchCreatedAt: string,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<EvaluationMemoControllerShow200Response> {
      return localVarFp
        .evaluationMemoControllerShow(
          kaseId,
          trunkCreatedAt,
          branchCreatedAt,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<Array<KaseChallengerQuery>> {
      return localVarFp
        .kaseControllerSearch(
          xRequestId,
          keyword,
          participantRoles,
          statuses,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShow(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<KaseDetail> {
      return localVarFp
        .kaseControllerShow(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowCc(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<KaseDetail> {
      return localVarFp
        .kaseControllerShowCc(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowDevelopClub(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<KaseDetail> {
      return localVarFp
        .kaseControllerShowDevelopClub(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseControllerShowSaseboCommunity(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<KaseDetail> {
      return localVarFp
        .kaseControllerShowSaseboCommunity(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<string>} kaseIds
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    kaseUnreadCountControllerGetKaseUnreadCounts(
      kaseIds: Array<string>,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<KaseUnreadCount>> {
      return localVarFp
        .kaseUnreadCountControllerGetKaseUnreadCounts(
          kaseIds,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lanterKaseControllerIndex(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<KaseAdminQuery> {
      return localVarFp
        .lanterKaseControllerIndex(xRequestId, keyword, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lanterKaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      offset?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<KaseAdminQuery> {
      return localVarFp
        .lanterKaseControllerSearch(xRequestId, keyword, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerApprove(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newKaseClosesControllerApprove(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerUpsert(
      kaseId: number,
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<NewKaseClose> {
      return localVarFp
        .newKaseClosesControllerUpsert(
          kaseId,
          newKaseCloseUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseClosesControllerUpsertOld(
      newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newKaseClosesControllerUpsertOld(
          newKaseCloseUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerApprove(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newKaseControllerApprove(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {NewKaseCreateRequestBody} newKaseCreateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerCreate(
      newKaseCreateRequestBody: NewKaseCreateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newKaseControllerCreate(newKaseCreateRequestBody, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerIndex(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<NewKase>> {
      return localVarFp
        .newKaseControllerIndex(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerShowMe(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<NewKaseControllerShowMe200Response> {
      return localVarFp
        .newKaseControllerShowMe(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {NewKaseUpdateRequestBody} newKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newKaseControllerUpdate(
      kaseId: number,
      newKaseUpdateRequestBody: NewKaseUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .newKaseControllerUpdate(
          kaseId,
          newKaseUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {CreateOwnerEvaluationRequestBody} createOwnerEvaluationRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ownerEvaluationsControllerCreate(
      kaseId: number,
      createOwnerEvaluationRequestBody: CreateOwnerEvaluationRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .ownerEvaluationsControllerCreate(
          kaseId,
          createOwnerEvaluationRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ownerEvaluationsControllerIndex(
      kaseId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<OwnerEvaluationsControllerIndex200Response> {
      return localVarFp
        .ownerEvaluationsControllerIndex(kaseId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {RegisterParticipantRequestBody} registerParticipantRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantListsControllerRegister(
      kaseId: number,
      registerParticipantRequestBody: RegisterParticipantRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .participantListsControllerRegister(
          kaseId,
          registerParticipantRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {string} [keyword]
     * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
     * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
     * @param {string} [offset]
     * @param {string} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateKaseControllerSearch(
      xRequestId?: string,
      keyword?: string,
      participantRoles?: Array<
        'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
      >,
      statuses?: Array<
        | 'DRAFT'
        | 'APPLYING'
        | 'LANTER_WANTED'
        | 'OPEN'
        | 'CLOSE_APPLYING'
        | 'PENDING'
        | 'CLOSED'
        | 'COOL_DOWN'
        | 'OWNER_GONE'
      >,
      offset?: string,
      limit?: string,
      options?: any,
    ): AxiosPromise<Array<KaseAdminQuery>> {
      return localVarFp
        .privateKaseControllerSearch(
          xRequestId,
          keyword,
          participantRoles,
          statuses,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} kaseId
     * @param {PrivateKaseUpdateRequestBody} privateKaseUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    privateKaseControllerUpdate(
      kaseId: number,
      privateKaseUpdateRequestBody: PrivateKaseUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .privateKaseControllerUpdate(
          kaseId,
          privateKaseUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CasesApi - object-oriented interface
 * @export
 * @class CasesApi
 * @extends {BaseAPI}
 */
export class CasesApi extends BaseAPI {
  /**
   *
   * @param {number} kaseId
   * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerAdminCreate(
    kaseId: number,
    createApplyCommentRequestBody: CreateApplyCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerAdminCreate(
        kaseId,
        createApplyCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerAdminIndex(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerAdminIndex(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerCreate(
    kaseId: number,
    createApplyCommentRequestBody: CreateApplyCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerCreate(
        kaseId,
        createApplyCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerDelete(
    kaseId: number,
    createdAt: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerDelete(kaseId, createdAt, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerIndex(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerIndex(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerReadByAdmin(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerReadByAdmin(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerReadByOwner(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerReadByOwner(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {CreateApplyCommentRequestBody} createApplyCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public applyCommentControllerUpdate(
    kaseId: number,
    createdAt: number,
    createApplyCommentRequestBody: CreateApplyCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .applyCommentControllerUpdate(
        kaseId,
        createdAt,
        createApplyCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateCloseCommentRequestBody} createCloseCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public closeCommentControllerCreate(
    kaseId: number,
    createCloseCommentRequestBody: CreateCloseCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .closeCommentControllerCreate(
        kaseId,
        createCloseCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public closeCommentControllerDelete(
    kaseId: number,
    createdAt: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .closeCommentControllerDelete(kaseId, createdAt, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public closeCommentControllerIndex(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .closeCommentControllerIndex(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public closeCommentControllerReadCloseComments(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .closeCommentControllerReadCloseComments(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {UpdateCloseCommentRequestBody} updateCloseCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public closeCommentControllerUpdate(
    kaseId: number,
    createdAt: number,
    updateCloseCommentRequestBody: UpdateCloseCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .closeCommentControllerUpdate(
        kaseId,
        createdAt,
        updateCloseCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {CreateAdminLikeCommentRequestBody} createAdminLikeCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerAddOrRemoveAdminLike(
    kaseId: number,
    createdAt: number,
    createAdminLikeCommentRequestBody: CreateAdminLikeCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerAddOrRemoveAdminLike(
        kaseId,
        createdAt,
        createAdminLikeCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {CreateFavoriteCommentRequestBody} createFavoriteCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerAddOrRemoveFavorite(
    kaseId: number,
    createdAt: number,
    createFavoriteCommentRequestBody: CreateFavoriteCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerAddOrRemoveFavorite(
        kaseId,
        createdAt,
        createFavoriteCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {CreateReactionCommentRequestBody} createReactionCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerAddOrRemoveReaction(
    kaseId: number,
    createdAt: number,
    createReactionCommentRequestBody: CreateReactionCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerAddOrRemoveReaction(
        kaseId,
        createdAt,
        createReactionCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerClearAllUnreadComments(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerClearAllUnreadComments(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateBranchCommentRequestBody} createBranchCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerCreateBranchComment(
    kaseId: number,
    createBranchCommentRequestBody: CreateBranchCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerCreateBranchComment(
        kaseId,
        createBranchCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateHideCommentRequestBody} createHideCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerCreateHideComment(
    kaseId: number,
    createHideCommentRequestBody: CreateHideCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerCreateHideComment(
        kaseId,
        createHideCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreatePointCommentRequestBody} createPointCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerCreatePointComment(
    kaseId: number,
    createPointCommentRequestBody: CreatePointCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerCreatePointComment(
        kaseId,
        createPointCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateTrunkCommentRequestBody} createTrunkCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerCreateTrunkComment(
    kaseId: number,
    createTrunkCommentRequestBody: CreateTrunkCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerCreateTrunkComment(
        kaseId,
        createTrunkCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerDelete(
    kaseId: number,
    createdAt: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerDelete(kaseId, createdAt, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerDeleteTrunkComment(
    kaseId: number,
    createdAt: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerDeleteTrunkComment(
        kaseId,
        createdAt,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerIndex(
    kaseId: number,
    xRequestId?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerIndex(kaseId, xRequestId, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {Array<ReadCommentRequestBody>} readCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerMarkAsRead(
    kaseId: number,
    readCommentRequestBody: Array<ReadCommentRequestBody>,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerMarkAsRead(
        kaseId,
        readCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerShowFavoriteComments(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerShowFavoriteComments(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerShowUnreadComments(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerShowUnreadComments(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {number} createdAt
   * @param {UpdateCommentRequestBody} updateCommentRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public commentControllerUpdateContent(
    kaseId: number,
    createdAt: number,
    updateCommentRequestBody: UpdateCommentRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .commentControllerUpdateContent(
        kaseId,
        createdAt,
        updateCommentRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateEvaluationMemoRequestBody} createEvaluationMemoRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public evaluationMemoControllerCreate(
    kaseId: number,
    createEvaluationMemoRequestBody: CreateEvaluationMemoRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .evaluationMemoControllerCreate(
        kaseId,
        createEvaluationMemoRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public evaluationMemoControllerIndex(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .evaluationMemoControllerIndex(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} trunkCreatedAt
   * @param {string} branchCreatedAt
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public evaluationMemoControllerShow(
    kaseId: number,
    trunkCreatedAt: string,
    branchCreatedAt: string,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .evaluationMemoControllerShow(
        kaseId,
        trunkCreatedAt,
        branchCreatedAt,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {string} [keyword]
   * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
   * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
   * @param {string} [offset]
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseControllerSearch(
    xRequestId?: string,
    keyword?: string,
    participantRoles?: Array<
      'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
    >,
    statuses?: Array<
      | 'DRAFT'
      | 'APPLYING'
      | 'LANTER_WANTED'
      | 'OPEN'
      | 'CLOSE_APPLYING'
      | 'PENDING'
      | 'CLOSED'
      | 'COOL_DOWN'
      | 'OWNER_GONE'
    >,
    offset?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseControllerSearch(
        xRequestId,
        keyword,
        participantRoles,
        statuses,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseControllerShow(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseControllerShow(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseControllerShowCc(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseControllerShowCc(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseControllerShowDevelopClub(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseControllerShowDevelopClub(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseControllerShowSaseboCommunity(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseControllerShowSaseboCommunity(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {Array<string>} kaseIds
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public kaseUnreadCountControllerGetKaseUnreadCounts(
    kaseIds: Array<string>,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .kaseUnreadCountControllerGetKaseUnreadCounts(
        kaseIds,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {string} [keyword]
   * @param {string} [offset]
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public lanterKaseControllerIndex(
    xRequestId?: string,
    keyword?: string,
    offset?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .lanterKaseControllerIndex(xRequestId, keyword, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {string} [keyword]
   * @param {string} [offset]
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public lanterKaseControllerSearch(
    xRequestId?: string,
    keyword?: string,
    offset?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .lanterKaseControllerSearch(xRequestId, keyword, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseClosesControllerApprove(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseClosesControllerApprove(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseClosesControllerUpsert(
    kaseId: number,
    newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseClosesControllerUpsert(
        kaseId,
        newKaseCloseUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewKaseCloseUpdateRequestBody} newKaseCloseUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseClosesControllerUpsertOld(
    newKaseCloseUpdateRequestBody: NewKaseCloseUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseClosesControllerUpsertOld(
        newKaseCloseUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseControllerApprove(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseControllerApprove(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NewKaseCreateRequestBody} newKaseCreateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseControllerCreate(
    newKaseCreateRequestBody: NewKaseCreateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseControllerCreate(newKaseCreateRequestBody, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseControllerIndex(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseControllerIndex(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseControllerShowMe(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseControllerShowMe(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {NewKaseUpdateRequestBody} newKaseUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public newKaseControllerUpdate(
    kaseId: number,
    newKaseUpdateRequestBody: NewKaseUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .newKaseControllerUpdate(
        kaseId,
        newKaseUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {CreateOwnerEvaluationRequestBody} createOwnerEvaluationRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public ownerEvaluationsControllerCreate(
    kaseId: number,
    createOwnerEvaluationRequestBody: CreateOwnerEvaluationRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .ownerEvaluationsControllerCreate(
        kaseId,
        createOwnerEvaluationRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public ownerEvaluationsControllerIndex(
    kaseId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .ownerEvaluationsControllerIndex(kaseId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {RegisterParticipantRequestBody} registerParticipantRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public participantListsControllerRegister(
    kaseId: number,
    registerParticipantRequestBody: RegisterParticipantRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .participantListsControllerRegister(
        kaseId,
        registerParticipantRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {string} [keyword]
   * @param {Array<'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'>} [participantRoles]
   * @param {Array<'DRAFT' | 'APPLYING' | 'LANTER_WANTED' | 'OPEN' | 'CLOSE_APPLYING' | 'PENDING' | 'CLOSED' | 'COOL_DOWN' | 'OWNER_GONE'>} [statuses]
   * @param {string} [offset]
   * @param {string} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public privateKaseControllerSearch(
    xRequestId?: string,
    keyword?: string,
    participantRoles?: Array<
      'OWNER' | 'SUPPORTER' | 'WATCHER' | 'LANTER' | 'MANAGER'
    >,
    statuses?: Array<
      | 'DRAFT'
      | 'APPLYING'
      | 'LANTER_WANTED'
      | 'OPEN'
      | 'CLOSE_APPLYING'
      | 'PENDING'
      | 'CLOSED'
      | 'COOL_DOWN'
      | 'OWNER_GONE'
    >,
    offset?: string,
    limit?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .privateKaseControllerSearch(
        xRequestId,
        keyword,
        participantRoles,
        statuses,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} kaseId
   * @param {PrivateKaseUpdateRequestBody} privateKaseUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CasesApi
   */
  public privateKaseControllerUpdate(
    kaseId: number,
    privateKaseUpdateRequestBody: PrivateKaseUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return CasesApiFp(this.configuration)
      .privateKaseControllerUpdate(
        kaseId,
        privateKaseUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactusApi - axios parameter creator
 * @export
 */
export const ContactusApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateContactUsRequestBody} createContactUsRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactUsControllerCreate: async (
      createContactUsRequestBody: CreateContactUsRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createContactUsRequestBody' is not null or undefined
      assertParamExists(
        'contactUsControllerCreate',
        'createContactUsRequestBody',
        createContactUsRequestBody,
      );
      const localVarPath = `/contact-us`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createContactUsRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactusApi - functional programming interface
 * @export
 */
export const ContactusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ContactusApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateContactUsRequestBody} createContactUsRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactUsControllerCreate(
      createContactUsRequestBody: CreateContactUsRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contactUsControllerCreate(
          createContactUsRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ContactusApi - factory interface
 * @export
 */
export const ContactusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ContactusApiFp(configuration);
  return {
    /**
     *
     * @param {CreateContactUsRequestBody} createContactUsRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactUsControllerCreate(
      createContactUsRequestBody: CreateContactUsRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .contactUsControllerCreate(
          createContactUsRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactusApi - object-oriented interface
 * @export
 * @class ContactusApi
 * @extends {BaseAPI}
 */
export class ContactusApi extends BaseAPI {
  /**
   *
   * @param {CreateContactUsRequestBody} createContactUsRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactusApi
   */
  public contactUsControllerCreate(
    createContactUsRequestBody: CreateContactUsRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return ContactusApiFp(this.configuration)
      .contactUsControllerCreate(
        createContactUsRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DmsApi - axios parameter creator
 * @export
 */
export const DmsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} dmId
     * @param {CreateDirectMessageRequestBody} createDirectMessageRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directMessageControllerCreate: async (
      dmId: number,
      createDirectMessageRequestBody: CreateDirectMessageRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dmId' is not null or undefined
      assertParamExists('directMessageControllerCreate', 'dmId', dmId);
      // verify required parameter 'createDirectMessageRequestBody' is not null or undefined
      assertParamExists(
        'directMessageControllerCreate',
        'createDirectMessageRequestBody',
        createDirectMessageRequestBody,
      );
      const localVarPath = `/dms/{dmId}`.replace(
        `{${'dmId'}}`,
        encodeURIComponent(String(dmId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDirectMessageRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} dmId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directMessageControllerIndex: async (
      dmId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'dmId' is not null or undefined
      assertParamExists('directMessageControllerIndex', 'dmId', dmId);
      const localVarPath = `/dms/{dmId}`.replace(
        `{${'dmId'}}`,
        encodeURIComponent(String(dmId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dmUserControllerIndex: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dm-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DmsApi - functional programming interface
 * @export
 */
export const DmsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DmsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} dmId
     * @param {CreateDirectMessageRequestBody} createDirectMessageRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async directMessageControllerCreate(
      dmId: number,
      createDirectMessageRequestBody: CreateDirectMessageRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectMessage>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.directMessageControllerCreate(
          dmId,
          createDirectMessageRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} dmId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async directMessageControllerIndex(
      dmId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DirectMessage>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.directMessageControllerIndex(
          dmId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dmUserControllerIndex(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DmUser>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dmUserControllerIndex(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DmsApi - factory interface
 * @export
 */
export const DmsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DmsApiFp(configuration);
  return {
    /**
     *
     * @param {number} dmId
     * @param {CreateDirectMessageRequestBody} createDirectMessageRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directMessageControllerCreate(
      dmId: number,
      createDirectMessageRequestBody: CreateDirectMessageRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<DirectMessage> {
      return localVarFp
        .directMessageControllerCreate(
          dmId,
          createDirectMessageRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} dmId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    directMessageControllerIndex(
      dmId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<DirectMessage>> {
      return localVarFp
        .directMessageControllerIndex(dmId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dmUserControllerIndex(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<DmUser>> {
      return localVarFp
        .dmUserControllerIndex(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DmsApi - object-oriented interface
 * @export
 * @class DmsApi
 * @extends {BaseAPI}
 */
export class DmsApi extends BaseAPI {
  /**
   *
   * @param {number} dmId
   * @param {CreateDirectMessageRequestBody} createDirectMessageRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DmsApi
   */
  public directMessageControllerCreate(
    dmId: number,
    createDirectMessageRequestBody: CreateDirectMessageRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return DmsApiFp(this.configuration)
      .directMessageControllerCreate(
        dmId,
        createDirectMessageRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} dmId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DmsApi
   */
  public directMessageControllerIndex(
    dmId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return DmsApiFp(this.configuration)
      .directMessageControllerIndex(dmId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DmsApi
   */
  public dmUserControllerIndex(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return DmsApiFp(this.configuration)
      .dmUserControllerIndex(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * E2eApi - axios parameter creator
 * @export
 */
export const E2eApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dangerControllerCreateDefaultCommunities: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/danger/create-communities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dangerControllerDeleteAllTableDate: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/danger/delete-all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2eApi - functional programming interface
 * @export
 */
export const E2eApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = E2eApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dangerControllerCreateDefaultCommunities(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dangerControllerCreateDefaultCommunities(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dangerControllerDeleteAllTableDate(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.dangerControllerDeleteAllTableDate(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * E2eApi - factory interface
 * @export
 */
export const E2eApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = E2eApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dangerControllerCreateDefaultCommunities(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dangerControllerCreateDefaultCommunities(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dangerControllerDeleteAllTableDate(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .dangerControllerDeleteAllTableDate(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * E2eApi - object-oriented interface
 * @export
 * @class E2eApi
 * @extends {BaseAPI}
 */
export class E2eApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2eApi
   */
  public dangerControllerCreateDefaultCommunities(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return E2eApiFp(this.configuration)
      .dangerControllerCreateDefaultCommunities(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2eApi
   */
  public dangerControllerDeleteAllTableDate(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return E2eApiFp(this.configuration)
      .dangerControllerDeleteAllTableDate(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerFinishTutorial: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/events/finish-tutorial`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerReadNotifications: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/events/read-notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerSeeDm: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/events/see-dm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerSeeDmForAdmin: async (
      userId: number,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('frontEventControllerSeeDmForAdmin', 'userId', userId);
      const localVarPath = `/events/see-dm-for-admin/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async frontEventControllerFinishTutorial(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.frontEventControllerFinishTutorial(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async frontEventControllerReadNotifications(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.frontEventControllerReadNotifications(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async frontEventControllerSeeDm(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.frontEventControllerSeeDm(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async frontEventControllerSeeDmForAdmin(
      userId: number,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.frontEventControllerSeeDmForAdmin(
          userId,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EventsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerFinishTutorial(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .frontEventControllerFinishTutorial(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerReadNotifications(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .frontEventControllerReadNotifications(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerSeeDm(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .frontEventControllerSeeDm(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    frontEventControllerSeeDmForAdmin(
      userId: number,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .frontEventControllerSeeDmForAdmin(userId, xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public frontEventControllerFinishTutorial(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .frontEventControllerFinishTutorial(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public frontEventControllerReadNotifications(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .frontEventControllerReadNotifications(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public frontEventControllerSeeDm(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .frontEventControllerSeeDm(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public frontEventControllerSeeDmForAdmin(
    userId: number,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return EventsApiFp(this.configuration)
      .frontEventControllerSeeDmForAdmin(userId, xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} folderName
     * @param {string} fileName
     * @param {string} fileType
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileControllerShowPresignedUrl: async (
      folderName: string,
      fileName: string,
      fileType: string,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'folderName' is not null or undefined
      assertParamExists(
        'fileControllerShowPresignedUrl',
        'folderName',
        folderName,
      );
      // verify required parameter 'fileName' is not null or undefined
      assertParamExists('fileControllerShowPresignedUrl', 'fileName', fileName);
      // verify required parameter 'fileType' is not null or undefined
      assertParamExists('fileControllerShowPresignedUrl', 'fileType', fileType);
      const localVarPath = `/files/presigned-url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (folderName !== undefined) {
        localVarQueryParameter['folderName'] = folderName;
      }

      if (fileName !== undefined) {
        localVarQueryParameter['fileName'] = fileName;
      }

      if (fileType !== undefined) {
        localVarQueryParameter['fileType'] = fileType;
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} folderName
     * @param {string} fileName
     * @param {string} fileType
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fileControllerShowPresignedUrl(
      folderName: string,
      fileName: string,
      fileType: string,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrl>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fileControllerShowPresignedUrl(
          folderName,
          fileName,
          fileType,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     *
     * @param {string} folderName
     * @param {string} fileName
     * @param {string} fileType
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fileControllerShowPresignedUrl(
      folderName: string,
      fileName: string,
      fileType: string,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<PresignedUrl> {
      return localVarFp
        .fileControllerShowPresignedUrl(
          folderName,
          fileName,
          fileType,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @param {string} folderName
   * @param {string} fileName
   * @param {string} fileType
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public fileControllerShowPresignedUrl(
    folderName: string,
    fileName: string,
    fileType: string,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .fileControllerShowPresignedUrl(
        folderName,
        fileName,
        fileType,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerIndex: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationControllerIndex(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationWithCount>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationControllerIndex(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationControllerIndex(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<NotificationWithCount> {
      return localVarFp
        .notificationControllerIndex(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationControllerIndex(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .notificationControllerIndex(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateCustomer: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/customer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateSetupIntent: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/setup-intents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PaymentCreateSubscriptionRequestBody} paymentCreateSubscriptionRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateSubscription: async (
      paymentCreateSubscriptionRequestBody: PaymentCreateSubscriptionRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'paymentCreateSubscriptionRequestBody' is not null or undefined
      assertParamExists(
        'paymentControllerCreateSubscription',
        'paymentCreateSubscriptionRequestBody',
        paymentCreateSubscriptionRequestBody,
      );
      const localVarPath = `/payment/subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentCreateSubscriptionRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCurrentInformation: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/current`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerHistories: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/histories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerIndexProductKeys: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/product-keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerRegisterCardForE2E: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/register-card-for-e2e`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PaymentUpdateRequestBody} paymentUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerUpdateCustomerDefaultPayment: async (
      paymentUpdateRequestBody: PaymentUpdateRequestBody,
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'paymentUpdateRequestBody' is not null or undefined
      assertParamExists(
        'paymentControllerUpdateCustomerDefaultPayment',
        'paymentUpdateRequestBody',
        paymentUpdateRequestBody,
      );
      const localVarPath = `/payment/customer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentUpdateRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stripeWebhookControllerHandleWebhook: async (
      xRequestId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/payment/hooks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['x-request-id'] = String(xRequestId);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerCreateCustomer(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerCreateCustomer(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerCreateSetupIntent(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupIntent>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerCreateSetupIntent(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {PaymentCreateSubscriptionRequestBody} paymentCreateSubscriptionRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerCreateSubscription(
      paymentCreateSubscriptionRequestBody: PaymentCreateSubscriptionRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentSubscription>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerCreateSubscription(
          paymentCreateSubscriptionRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerCurrentInformation(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentControllerCurrentInformation200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerCurrentInformation(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerHistories(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<PaymentHistory>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerHistories(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerIndexProductKeys(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductKeys>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerIndexProductKeys(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerRegisterCardForE2E(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RegisterCardForE2E>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerRegisterCardForE2E(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {PaymentUpdateRequestBody} paymentUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentControllerUpdateCustomerDefaultPayment(
      paymentUpdateRequestBody: PaymentUpdateRequestBody,
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentControllerUpdateCustomerDefaultPayment(
          paymentUpdateRequestBody,
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stripeWebhookControllerHandleWebhook(
      xRequestId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.stripeWebhookControllerHandleWebhook(
          xRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateCustomer(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .paymentControllerCreateCustomer(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateSetupIntent(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<SetupIntent> {
      return localVarFp
        .paymentControllerCreateSetupIntent(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PaymentCreateSubscriptionRequestBody} paymentCreateSubscriptionRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCreateSubscription(
      paymentCreateSubscriptionRequestBody: PaymentCreateSubscriptionRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<PaymentSubscription> {
      return localVarFp
        .paymentControllerCreateSubscription(
          paymentCreateSubscriptionRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerCurrentInformation(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<PaymentControllerCurrentInformation200Response> {
      return localVarFp
        .paymentControllerCurrentInformation(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerHistories(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<Array<PaymentHistory>> {
      return localVarFp
        .paymentControllerHistories(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerIndexProductKeys(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<ProductKeys> {
      return localVarFp
        .paymentControllerIndexProductKeys(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerRegisterCardForE2E(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<RegisterCardForE2E> {
      return localVarFp
        .paymentControllerRegisterCardForE2E(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PaymentUpdateRequestBody} paymentUpdateRequestBody
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentControllerUpdateCustomerDefaultPayment(
      paymentUpdateRequestBody: PaymentUpdateRequestBody,
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .paymentControllerUpdateCustomerDefaultPayment(
          paymentUpdateRequestBody,
          xRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [xRequestId] リクエストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stripeWebhookControllerHandleWebhook(
      xRequestId?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .stripeWebhookControllerHandleWebhook(xRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerCreateCustomer(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerCreateCustomer(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerCreateSetupIntent(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerCreateSetupIntent(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PaymentCreateSubscriptionRequestBody} paymentCreateSubscriptionRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerCreateSubscription(
    paymentCreateSubscriptionRequestBody: PaymentCreateSubscriptionRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerCreateSubscription(
        paymentCreateSubscriptionRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerCurrentInformation(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerCurrentInformation(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerHistories(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerHistories(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerIndexProductKeys(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerIndexProductKeys(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerRegisterCardForE2E(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerRegisterCardForE2E(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PaymentUpdateRequestBody} paymentUpdateRequestBody
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentControllerUpdateCustomerDefaultPayment(
    paymentUpdateRequestBody: PaymentUpdateRequestBody,
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentControllerUpdateCustomerDefaultPayment(
        paymentUpdateRequestBody,
        xRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [xRequestId] リクエストID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public stripeWebhookControllerHandleWebhook(
    xRequestId?: string,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .stripeWebhookControllerHandleWebhook(xRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
