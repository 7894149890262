/* eslint-disable react/jsx-props-no-spreading */
import { Logger } from '@aws-amplify/core';
import relativeTime from 'dayjs/plugin/relativeTime';
import { VFC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import { TextRenderer } from '@client/components/designSystems/TextRenderer/TextRenderer';
import { TrappySays } from '@client/components/designSystems/TrappySays/TrappySays';
import dayjs from '@client/helpers/dayjsSpecializedInJp';
import { useLogout } from '@client/helpers/hooks/useLogout';
import notify from '@client/helpers/notify';
import { notifyRequestError } from '@client/helpers/notifyRequestError';
import { MeRequest } from '@client/helpers/requests/meRequest';
import { MeReflection } from '@client/helpers/types';

import classes from './ReflectionPrompt.module.scss';

dayjs.extend(relativeTime);

const logger = new Logger('ReflectionPrompt');

type ReflectionPromptProps = {
  reflection: MeReflection | null;
  onSend?: () => void;
};

type InputType = {
  なつかしぃ: boolean;
  意外とできてるな自分: boolean;
  なんかやる気湧いてきた: boolean;
  ふつうに忘れてた: boolean;
  できてないかも: boolean;
  ハッとした: boolean;
  特に何にも: boolean;
  もっとつよつよになりたい: boolean;
  いまは通常運転でいいかな: boolean;
  いまはちょっと余裕ないな: boolean;
  超絶wellbeing: boolean;
  あっぱれ: boolean;
};

/**
 * 役割
 * - 振り返り表示
 * - 質問回答
 * - 回答キャンセル
 */
export const ReflectionPrompt: VFC<ReflectionPromptProps> = ({
  reflection,
  onSend = () => {
    // do nothing
  },
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const toggle = () => {
    setIsShowModal((prev) => !prev);
  };

  const logout = useLogout();

  const { handleSubmit, control } = useForm<InputType>();

  const onSubmit: SubmitHandler<InputType> = async (data) => {
    if (!reflection) return;

    const res = await MeRequest.saveReflection({
      reflectionId: reflection.reflectionId,
      kaseId: reflection.kaseId,
      notShownOnNextLogin: true,
      questions: [
        {
          questionId: reflection.questions[0].questionId,
          answer: JSON.stringify(data),
        },
      ],
    });

    onSend();

    if (!res.isSuccess) {
      notifyRequestError({ status: res.notify, message: res.error, logout });

      return;
    }

    notify({ status: 'success', message: '振り返り完了しました！' });
    toggle();
  };

  const cancel = async () => {
    if (!reflection) return;

    const res = await MeRequest.saveReflection({
      reflectionId: reflection.reflectionId,
      kaseId: reflection.kaseId,
      notShownOnNextLogin: true,
      questions: [
        {
          questionId: reflection.questions[0].questionId,
          answer: reflection.questions[0].answer,
        },
      ],
    });

    onSend();

    if (!res.isSuccess) {
      notifyRequestError({ status: res.notify, message: res.error, logout });
    }
  };

  if (!reflection) return null;

  let parsedAnswer: Partial<InputType> = {
    なつかしぃ: false,
    意外とできてるな自分: false,
    なんかやる気湧いてきた: false,
    ふつうに忘れてた: false,
    できてないかも: false,
    ハッとした: false,
    特に何にも: false,
    もっとつよつよになりたい: false,
    いまは通常運転でいいかな: false,
    いまはちょっと余裕ないな: false,
    超絶wellbeing: false,
    あっぱれ: false,
  };

  try {
    if (reflection.questions[0].answer !== '') {
      parsedAnswer = JSON.parse(reflection.questions[0].answer);
    }
  } catch (err) {
    logger.warn(`JSON.parse error! ${reflection.questions[0].answer}`);
  }

  return (
    <Container>
      <Modal isOpen={isShowModal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
        >{`#${reflection.draftTitleNumber}の振り返りッピ`}</ModalHeader>
        <ModalBody>
          <div className={classes.ReflectionPrompt}>
            <h3>ケースもやっと</h3>
            <span>{reflection.challenge}</span>
          </div>
          <div className={classes.ReflectionPrompt}>
            <h3>スッキリした理由ッピ</h3>
            <TextRenderer text={reflection.close.reason} />
          </div>
          <div className={classes.ReflectionPrompt}>
            <h3>ケースで得た学びッピ</h3>
            <TextRenderer text={reflection.close.learn} />
          </div>

          <div>
            <h3>これをみてなんか感じたッピ？</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup check>
                <Controller
                  name="なつかしぃ"
                  control={control}
                  defaultValue={parsedAnswer['なつかしぃ'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check1"
                      onChange={onChange}
                      innerRef={ref}
                      type="checkbox"
                      checked={value}
                    />
                  )}
                />
                <Label for="check1" check>
                  なつかしぃ😳
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="意外とできてるな自分"
                  control={control}
                  defaultValue={parsedAnswer['意外とできてるな自分'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check2"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check2" check>
                  そういえば、意外とできてるな自分😆
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="なんかやる気湧いてきた"
                  control={control}
                  defaultValue={parsedAnswer['なんかやる気湧いてきた'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check6"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check6" check>
                  なんかやる気湧いてきた😃
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="ふつうに忘れてた"
                  control={control}
                  defaultValue={parsedAnswer['ふつうに忘れてた'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check5"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check5" check>
                  ふつうに忘れてた🥴
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="できてないかも"
                  control={control}
                  defaultValue={parsedAnswer['できてないかも'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check4"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check4" check>
                  できてないかも...😵
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="ハッとした"
                  control={control}
                  defaultValue={parsedAnswer['ハッとした'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check3"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check3" check>
                  ハッとした😲
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="特に何にも"
                  control={control}
                  defaultValue={parsedAnswer['特に何にも'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check7"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check7" check>
                  特に何にも🙂
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="もっとつよつよになりたい"
                  control={control}
                  defaultValue={
                    parsedAnswer['もっとつよつよになりたい'] ?? false
                  }
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check8"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check8" check>
                  もっとつよつよになりたい🥺
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="いまは通常運転でいいかな"
                  control={control}
                  defaultValue={
                    parsedAnswer['いまは通常運転でいいかな'] ?? false
                  }
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check9"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check9" check>
                  いまは通常運転でいいかな😌
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="いまはちょっと余裕ないな"
                  control={control}
                  defaultValue={
                    parsedAnswer['いまはちょっと余裕ないな'] ?? false
                  }
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check10"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check10" check>
                  いまはちょっと余裕ないな😓
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="超絶wellbeing"
                  control={control}
                  defaultValue={parsedAnswer['超絶wellbeing'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check11"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check11" check>
                  超絶well-being!!🤩
                </Label>
              </FormGroup>
              <FormGroup check>
                <Controller
                  name="あっぱれ"
                  control={control}
                  defaultValue={parsedAnswer['あっぱれ'] ?? false}
                  render={({ field: { onChange, ref, value } }) => (
                    <Input
                      id="check12"
                      innerRef={ref}
                      type="checkbox"
                      onChange={onChange}
                      checked={value}
                    />
                  )}
                />
                <Label for="check12" check>
                  あっぱれ🥳
                </Label>
              </FormGroup>
              <Button type="submit">振り返り完了！</Button>
              <Link to={`/cases/${reflection.kaseId}`}>ケースをのぞく</Link>
            </Form>
          </div>
        </ModalBody>
      </Modal>
      <TrappySays direction="top">
        <div>
          {`${dayjs(reflection.closedAt).tz().fromNow()}に投稿したモヤっと「${
            reflection.challenge
          }」のケース良かったッピね。少しだけ覗いて見るッピ？`}
        </div>
        <div>
          <Button onClick={cancel} size="sm">
            やめとくッピ
          </Button>
          <Button size="sm" color="primary" onClick={toggle}>
            見たいッピ
          </Button>
        </div>
      </TrappySays>
    </Container>
  );
};
