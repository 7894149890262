import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppDispatch } from '@client/helpers/configureAppStore';
import { fetchNotifications as fetchNotificationsAction } from '@client/store/notification/slice';

export const useFetchNotification = (
  pathname: string,
  ignoreValidatePaths: (pathName: string) => boolean,
  authenticated: boolean,
): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authenticated && !ignoreValidatePaths(pathname)) {
      dispatch(fetchNotificationsAction());
    }
  }, [dispatch, pathname, authenticated, ignoreValidatePaths]);
};
