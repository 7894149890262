import { Auth } from '@aws-amplify/auth';
import { I18n, Logger } from '@aws-amplify/core';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
// global css
import { FC } from 'react';
import ReactGA from 'react-ga';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Store } from 'redux';

import '@client/assets/plugins/nucleo/css/nucleo.css';
// fortawesomeであってます。
import '@client/assets/scss/argon-dashboard-react.scss';
import { ErrorBoundary } from '@client/components/designSystems/ErrorBoundary/ErrorBoundary';
// components
import { COGNITO_ERROR } from '@client/constants/i18n';
import notify from '@client/helpers/notify';
import { ApplicationState } from '@client/store/index';

import './App.css';
import { Routes } from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const trackingId = 'UA-165275515-1';
if (process.env.NX_USE_GOOGLE_ANALYTICS === 'true') {
  ReactGA.initialize(trackingId);
}

// cognito エラーの日本語化
I18n.putVocabularies(COGNITO_ERROR);
I18n.setLanguage('ja');

if (process.env.NX_IS_SKIP_COGNITO_SETUP !== 'true') {
  Auth.configure({
    userPoolId: process.env.NX_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.NX_AWS_USER_POOLS_CLIENT_ID,
    region: process.env.NX_AWS_COGNITO_REGION,
    authenticationFlowType: process.env.NX_AWS_COGNITO_FLOW_TYPE,
  });
}

Logger.LOG_LEVEL = process.env.NX_LOG_LEVEL;

interface MainProps {
  store: Store<ApplicationState>;
}

// NOTE: 再生成を避けるためコンポーネントの宣言。
const stripePromise = loadStripe(process.env.NX_STRIPE_PUBLISHABLE_KEY || '', {
  locale: 'ja',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const App: FC<MainProps> = ({ store }) => {
  /* ------------------------ */
  /*        新しいバージョン通知        */
  /* ------------------------ */
  // 参考: https://dev.to/daniellycosta/showing-new-version-available-notification-on-create-react-app-pwas-3jc9
  // const [serviceWorkerState, setServiceWorkerState] = useState<{
  //   waitingWorker: ServiceWorker | null;
  //   isNewVersionAvailable: boolean;
  // }>({
  //   waitingWorker: null,
  //   isNewVersionAvailable: false,
  // });

  // useEffect(() => {
  //   const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
  //     setServiceWorkerState({
  //       waitingWorker: registration && registration.waiting,
  //       isNewVersionAvailable: true,
  //     });
  //   };

  //   serviceWorkerRegistration.register({
  //     onUpdate: onServiceWorkerUpdate,
  //   });
  // }, []);

  // useEffect(() => {
  //   const updateAction = () => {
  //     serviceWorkerState.waitingWorker?.postMessage({
  //       type: 'SKIP_WAITING',
  //     });
  //     setServiceWorkerState((prev) => ({
  //       ...prev,
  //       isNewVersionAvailable: false,
  //     }));
  //     window.location.reload();
  //   };

  //   const renderNotification = (onClose: () => void) => {
  //     return (
  //       <Button
  //         color="primary"
  //         size="sm"
  //         onClick={() => {
  //           updateAction();
  //           onClose();
  //         }}
  //       >
  //         リフレッシュ
  //       </Button>
  //     );
  //   };

  //   if (serviceWorkerState.isNewVersionAvailable) {
  //     notify({
  //       status: 'custom',
  //       message: '新しいバージョンがリリースされました',
  //       renderCustom: renderNotification,
  //       preventDuplicateId: 'newVersionAvailable',
  //     });
  //   }
  // }, [serviceWorkerState]);

  // serviceWorkerRegistration.register({
  //   // onUpdate: onServiceWorkerUpdate,
  // });

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <Routes />
          </Provider>
        </Elements>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
