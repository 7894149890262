import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from '@client/store/index';

const rawSelector = (state: ApplicationState) => state.currentUser;

export const getCurrentUser = createSelector(
  [rawSelector],
  (currentUser) => currentUser.data,
);
