import { Logger } from '@aws-amplify/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Branch,
  HideComment as HideCommentDto,
  Point as PointDto,
  Trunk,
} from '@client/helpers/types';

import { CommentsState } from './types';

const logger = new Logger('CommentSlice');

export const initialState: CommentsState = {
  data: [],
  unreadComments: [],
};

type FavoriteActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  makeFavorite: boolean;
};

type AdminLikeActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  makeAdminLike: boolean;
};

type UpdateContentActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  content: string;
};

type DeleteCommentActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
};
type PointAppendActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  point: PointDto;
};
type HideCommentAppendActionPayload = {
  trunkCreatedAt: string;
  branchCreatedAt: string;
  hideComment: HideCommentDto;
  pointCreatedAt?: string;
};
type HideCommentUpdateActionPayload = {
  content: string;
  trunkCreatedAt: string;
  createdAt: string;
  pointCreatedAt?: string;
  hideCommentCreatedAt: string;
};
type HideCommentAppendDeletePayload = {
  trunkCreatedAt: string;
  createdAt: string;
  pointCreatedAt?: string;
  hideCommentCreatedAt: string;
};
type PointAppendUpdatePayload = {
  content: string;
  trunkCreatedAt: string;
  createdAt: string;
  pointCreatedAt: string;
};
type PointAppendDeletePayload = {
  trunkCreatedAt: string;
  createdAt: string;
  pointCreatedAt: string;
};
type ReactionActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  userId: number;
  name: string;
};
type RemoveUnreadCommentActionPayload = {
  createdAt: string;
};

type HasEvaluationMemoActionPayload = {
  trunkCreatedAt: string;
  createdAt: string;
  hasEvaluationMemo: boolean;
};

/**
 * NOTE: In createSlice, you can mutate state because of immutable.js.
 */
const trunkCommentSlice = createSlice({
  name: '@@threadComment',
  initialState,
  reducers: {
    deleteComment: (
      state,
      action: PayloadAction<DeleteCommentActionPayload>,
    ) => {
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. deleteComment(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // 削除
        state.data[trunkIndex].content = 'コメントは削除されました。';
        state.data[trunkIndex].isDeleted = true;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. deleteComment(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // 削除
      state.data[trunkIndex].branch[branchIndex].content =
        'コメントは削除されました。';
      state.data[trunkIndex].branch[branchIndex].isDeleted = true;

      return state;
    },
    updateContent: (
      state,
      action: PayloadAction<UpdateContentActionPayload>,
    ) => {
      const { content } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. updateContent(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // isAdminLikeを操作
        state.data[trunkIndex].content = content;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. updateContent(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // isAdminLikeを操作
      state.data[trunkIndex].branch[branchIndex].content = content;

      return state;
    },
    appendTrunkComment: (state, action: PayloadAction<Trunk>) => {
      state.data.push(action.payload);

      return state;
    },
    appendBranchComment: (state, action: PayloadAction<Branch>) => {
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() ===
          new Date(action.payload.trunkCreatedAt).getTime(), // dateはJSONになるとき、stringに変換される
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. appendBranchComment(). trunkCreatedAtが存在しない. trunkCreatedAt: ${new Date(
            action.payload.trunkCreatedAt,
          ).getTime()}`,
        );

        return state;
      }
      state.data[trunkIndex].branch.push(action.payload);

      return state;
    },
    loadComments: (state, action: PayloadAction<Trunk[]>) => {
      // apiを通して取得したDateはstring型になるので、Date型に戻す。
      state.data = action.payload;

      return state;
    },
    markAdminLike: (state, action: PayloadAction<AdminLikeActionPayload>) => {
      const { makeAdminLike } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. markAdminLike(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // isAdminLikeを操作
        state.data[trunkIndex].isAdminLike = makeAdminLike;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. markAdminLike(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // isAdminLikeを操作
      state.data[trunkIndex].branch[branchIndex].isAdminLike = makeAdminLike;

      return state;
    },
    markFavorite: (state, action: PayloadAction<FavoriteActionPayload>) => {
      const { makeFavorite } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. markFavorite(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // isFavoriteを操作
        state.data[trunkIndex].isFavorite = makeFavorite;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. markFavorite(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // isFavoriteを操作
      state.data[trunkIndex].branch[branchIndex].isFavorite = makeFavorite;

      return state;
    },
    loadUnreadComments: (state, action: PayloadAction<string[]>) => {
      state.unreadComments = action.payload.sort((a, b) => {
        // trunk_branch順(表示順)に並び替える
        if (new Date(a).getTime() > new Date(b).getTime()) return 1;

        return -1;
      });

      return state;
    },
    clearAllUnreadComments: (state) => {
      state.unreadComments = [];

      return state;
    },
    /**
     * 未読リストから対象の未読を削除。また既存のコメント一覧からから対象のコメントを既読にしておく。
     * @param state
     * @param action
     */
    removeUnreadComments: (
      state,
      action: PayloadAction<RemoveUnreadCommentActionPayload>,
    ) => {
      // 未読リストから削除
      state.unreadComments = state.unreadComments.filter(
        (createdAt) =>
          new Date(createdAt).getTime() !==
          new Date(action.payload.createdAt).getTime(),
      );

      // NOTE: コメントに紐づくisUnreadはそのままにしておく
      // 理由は、hideCommentのisUnreadなどはネストが深く、未読マークの除去が大変だから
      // 未読表示を消さない

      return state;
    },
    appendPoint: (state, action: PayloadAction<PointAppendActionPayload>) => {
      const { point } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // pointを追加
        state.data[trunkIndex].points.push(point);

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // pointを追加
      state.data[trunkIndex].branch[branchIndex].points.push(point);

      return state;
    },
    updatePoint: (state, action: PayloadAction<PointAppendUpdatePayload>) => {
      const { content } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);
      const pointCreatedAt = new Date(action.payload.pointCreatedAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        const pointIndex = state.data[trunkIndex].points.findIndex(
          (point) =>
            new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
        );
        // pointのテキストを更新
        state.data[trunkIndex].points[pointIndex].content = content;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      const pointIndex = state.data[trunkIndex].branch[
        branchIndex
      ].points.findIndex(
        (point) =>
          new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
      );
      // pointを更新
      state.data[trunkIndex].branch[branchIndex].points[pointIndex].content =
        content;

      return state;
    },
    deletePoint: (state, action: PayloadAction<PointAppendDeletePayload>) => {
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);
      const pointCreatedAt = new Date(action.payload.pointCreatedAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // pointをdrop
        const pointIndex = state.data[trunkIndex].points.findIndex(
          (point) =>
            new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
        );

        state.data[trunkIndex].points[pointIndex].isDeleted = true;
        state.data[trunkIndex].points[pointIndex].content =
          'コメントは削除されました。';

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // pointをdrop
      const pointIndex = state.data[trunkIndex].branch[
        branchIndex
      ].points.findIndex(
        (point) =>
          new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
      );

      state.data[trunkIndex].branch[branchIndex].points[pointIndex].isDeleted =
        true;
      state.data[trunkIndex].branch[branchIndex].points[pointIndex].content =
        'コメントは削除されました。';

      return state;
    },
    appendHideComment: (
      state,
      action: PayloadAction<HideCommentAppendActionPayload>,
    ) => {
      const { hideComment } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const branchCreatedAt = new Date(action.payload.branchCreatedAt);
      const pointCreatedAt = action.payload.pointCreatedAt
        ? new Date(action.payload.pointCreatedAt)
        : null;

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === branchCreatedAt.getTime()) {
        if (pointCreatedAt) {
          // pointコメントのindexを探す
          const pointIndex = state.data[trunkIndex].points.findIndex(
            (point) =>
              new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
          );

          // 存在しない
          if (pointIndex < 0) {
            logger.error(
              `comment.slice. appendPoint(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
            );

            return state;
          }

          state.data[trunkIndex].points[pointIndex].hideComments.push(
            hideComment,
          );

          return state;
        }
        // hideCommentを追加
        state.data[trunkIndex].hideComments.push(hideComment);

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === branchCreatedAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendHideComment(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${branchCreatedAt.getTime()}`,
        );

        return state;
      }

      if (pointCreatedAt) {
        // pointコメントのindexを探す
        const pointIndex = state.data[trunkIndex].branch[
          branchIndex
        ].points.findIndex(
          (point) =>
            new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
        );

        // 存在しない
        if (pointIndex < 0) {
          logger.error(
            `comment.slice. appendHideComment(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
          );

          return state;
        }

        state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments.push(hideComment);

        return state;
      }

      // hideCommentを追加
      state.data[trunkIndex].branch[branchIndex].hideComments.push(hideComment);

      return state;
    },
    updateHideComment: (
      state,
      action: PayloadAction<HideCommentUpdateActionPayload>,
    ) => {
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);
      const pointCreatedAt = action.payload.pointCreatedAt
        ? new Date(action.payload.pointCreatedAt)
        : null;
      const hideCommentCreatedAt = new Date(
        action.payload.hideCommentCreatedAt,
      );

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. updateHideComment(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        if (pointCreatedAt) {
          // pointコメントのindexを探す
          const pointIndex = state.data[trunkIndex].points.findIndex(
            (point) =>
              new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
          );

          // 存在しない
          if (pointIndex < 0) {
            logger.error(
              `comment.slice. trunk > point. updateHideComment(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
            );

            return state;
          }

          const hideIndex = state.data[trunkIndex].points[
            pointIndex
          ].hideComments.findIndex(
            (hide) =>
              new Date(hide.createdAt).getTime() ===
              hideCommentCreatedAt.getTime(),
          );

          // 存在しない
          if (hideIndex < 0) {
            logger.error(
              `comment.slice. updateHideComment(). hideCommentCreatedAt が存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
            );

            return state;
          }

          state.data[trunkIndex].points[pointIndex].hideComments[
            hideIndex
          ].content = action.payload.content;

          return state;
        }

        const hideIndex = state.data[trunkIndex].hideComments.findIndex(
          (hide) =>
            new Date(hide.createdAt).getTime() ===
            hideCommentCreatedAt.getTime(),
        );

        // 存在しない
        if (hideIndex < 0) {
          logger.error(
            `comment.slice. updateHideComment(). hideCommentCreatedAt が存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
          );

          return state;
        }

        state.data[trunkIndex].hideComments[hideIndex].content =
          action.payload.content;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      if (pointCreatedAt) {
        // pointコメントのindexを探す
        const pointIndex = state.data[trunkIndex].branch[
          branchIndex
        ].points.findIndex(
          (point) =>
            new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
        );

        // 存在しない
        if (pointIndex < 0) {
          logger.error(
            `comment.slice. updateHideComment(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
          );

          return state;
        }

        const hideIndex = state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments.findIndex(
          (hide) =>
            new Date(hide.createdAt).getTime() ===
            hideCommentCreatedAt.getTime(),
        );

        // 存在しない
        if (hideIndex < 0) {
          logger.error(
            `comment.slice. updateHideComment(). hideCommentCreatedAt が存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
          );

          return state;
        }

        state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments[hideIndex].content = action.payload.content;

        return state;
      }

      const hideIndex = state.data[trunkIndex].branch[
        branchIndex
      ].hideComments.findIndex(
        (hide) =>
          new Date(hide.createdAt).getTime() === hideCommentCreatedAt.getTime(),
      );

      // 存在しない
      if (hideIndex < 0) {
        logger.error(
          `comment.slice. updateHideComment(). hideCommentCreatedAt が存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
        );

        return state;
      }

      state.data[trunkIndex].branch[branchIndex].hideComments[
        hideIndex
      ].content = action.payload.content;

      return state;
    },
    deleteHideComment: (
      state,
      action: PayloadAction<HideCommentAppendDeletePayload>,
    ) => {
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);
      const pointCreatedAt = action.payload.pointCreatedAt
        ? new Date(action.payload.pointCreatedAt)
        : null;
      const hideCommentCreatedAt = new Date(
        action.payload.hideCommentCreatedAt,
      );

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. trunk. deleteHideComment(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        if (pointCreatedAt) {
          // pointコメントのindexを探す
          const pointIndex = state.data[trunkIndex].points.findIndex(
            (point) =>
              new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
          );

          // 存在しない
          if (pointIndex < 0) {
            logger.error(
              `comment.slice. trunk > point. deleteHideComment(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
            );

            return state;
          }

          // hideCommentをdrop
          const hideIndex = state.data[trunkIndex].points[
            pointIndex
          ].hideComments.findIndex(
            (hide) =>
              new Date(hide.createdAt).getTime() ===
              hideCommentCreatedAt.getTime(),
          );

          if (hideIndex < 0) {
            logger.error(
              `comment.slice. trunk > point > hide. deleteHideComment(). trunk > point > hide hideIndexが存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
            );

            return state;
          }

          state.data[trunkIndex].points[pointIndex].hideComments[
            hideIndex
          ].isDeleted = true;

          state.data[trunkIndex].points[pointIndex].hideComments[
            hideIndex
          ].content = 'コメントは削除されました。';

          return state;
        }

        // hideCommentをdrop
        const hideIndex = state.data[trunkIndex].hideComments.findIndex(
          (hide) =>
            new Date(hide.createdAt).getTime() ===
            hideCommentCreatedAt.getTime(),
        );

        if (hideIndex < 0) {
          logger.error(
            `comment.slice. trunk > hide. deleteHideComment(). hideIndexが存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
          );

          return state;
        }

        state.data[trunkIndex].hideComments[hideIndex].isDeleted = true;
        state.data[trunkIndex].hideComments[hideIndex].content =
          'コメントは削除されました。';

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      if (pointCreatedAt) {
        // pointコメントのindexを探す
        const pointIndex = state.data[trunkIndex].branch[
          branchIndex
        ].points.findIndex(
          (point) =>
            new Date(point.createdAt).getTime() === pointCreatedAt.getTime(),
        );

        // 存在しない
        if (pointIndex < 0) {
          logger.error(
            `comment.slice. deleteHideComment(). pointCreatedAtが存在しない. pointCreatedAt: ${pointCreatedAt.getTime()}`,
          );

          return state;
        }

        // hideCommentをdrop
        const hideIndex = state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments.findIndex(
          (hide) =>
            new Date(hide.createdAt).getTime() ===
            hideCommentCreatedAt.getTime(),
        );

        if (hideIndex < 0) {
          logger.error(
            `comment.slice. deleteHideComment(). hideIndexが存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
          );

          return state;
        }

        state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments[hideIndex].isDeleted = true;
        state.data[trunkIndex].branch[branchIndex].points[
          pointIndex
        ].hideComments[hideIndex].content = 'コメントは削除されました。';

        return state;
      }

      // hideCommentをdrop
      const hideIndex = state.data[trunkIndex].branch[
        branchIndex
      ].hideComments.findIndex(
        (hide) =>
          new Date(hide.createdAt).getTime() === hideCommentCreatedAt.getTime(),
      );

      if (hideIndex < 0) {
        logger.error(
          `comment.slice. deleteHideComment(). hideIndexが存在しない. hideCommentCreatedAt: ${hideCommentCreatedAt.getTime()}`,
        );

        return state;
      }

      state.data[trunkIndex].branch[branchIndex].hideComments[
        hideIndex
      ].isDeleted = true;

      state.data[trunkIndex].branch[branchIndex].hideComments[
        hideIndex
      ].content = 'コメントは削除されました。';

      return state;
    },
    addReaction: (state, action: PayloadAction<ReactionActionPayload>) => {
      const { name, userId } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. addReaction(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // pointを追加
        const reactionIndex = state.data[trunkIndex].reactions.findIndex(
          (reaction) => reaction.name === name,
        );

        // なければ追加
        if (reactionIndex < 0) {
          state.data[trunkIndex].reactions.push({
            name,
            userIds: [userId],
            count: 1,
          });
        }

        // あればuserIdを追加しカウントアップ
        if (reactionIndex >= 0) {
          state.data[trunkIndex].reactions[reactionIndex] = {
            name,
            userIds: [
              ...(state.data[trunkIndex].reactions[reactionIndex].userIds ||
                []),
              userId,
            ],
            count:
              state.data[trunkIndex].reactions[reactionIndex].count + 1 || 0,
          };
        }

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // pointを追加
      const reactionIndex = state.data[trunkIndex].branch[
        branchIndex
      ].reactions.findIndex((reaction) => reaction.name === name);

      // なければ追加
      if (reactionIndex < 0) {
        state.data[trunkIndex].branch[branchIndex].reactions.push({
          name,
          userIds: [userId],
          count: 1,
        });
      }

      // あればuserIdを追加しカウントアップ
      if (reactionIndex >= 0) {
        state.data[trunkIndex].branch[branchIndex].reactions[reactionIndex] = {
          name,
          userIds: [
            ...(state.data[trunkIndex].branch[branchIndex].reactions[
              reactionIndex
            ].userIds || []),
            userId,
          ],
          count:
            state.data[trunkIndex].branch[branchIndex].reactions[reactionIndex]
              .count + 1 || 0,
        };
      }

      return state;
    },
    removeReaction: (state, action: PayloadAction<ReactionActionPayload>) => {
      const { name, userId } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. addReaction(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // reaction index
        const reactionIndex = state.data[trunkIndex].reactions.findIndex(
          (reaction) => reaction.name === name,
        );

        // なければ何もしない
        if (reactionIndex < 0) {
          return state;
        }

        if (reactionIndex >= 0) {
          // カウントが1ならば完全に削除
          if (state.data[trunkIndex].reactions[reactionIndex].count === 1) {
            state.data[trunkIndex].reactions = state.data[
              trunkIndex
            ].reactions.filter((reaction) => reaction.name !== name);

            return state;
          }

          // それ以外であればuserIdを削除し、カウントダウン
          state.data[trunkIndex].reactions[reactionIndex] = {
            name,
            userIds:
              state.data[trunkIndex].reactions[reactionIndex].userIds?.filter(
                (id) => id !== userId,
              ) || [],
            count:
              state.data[trunkIndex].reactions[reactionIndex].count - 1 || 0,
          };
        }

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. appendPoint(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      const reactionIndex = state.data[trunkIndex].branch[
        branchIndex
      ].reactions.findIndex((reaction) => reaction.name === name);

      // なければなにもしない
      if (reactionIndex < 0) {
        return state;
      }

      // あれば
      if (reactionIndex >= 0) {
        // カウントが残り１のとき、完全削除
        if (
          state.data[trunkIndex].branch[branchIndex].reactions[reactionIndex]
            .count === 1
        ) {
          state.data[trunkIndex].branch[branchIndex].reactions = state.data[
            trunkIndex
          ].branch[branchIndex].reactions.filter(
            (reaction) => reaction.name !== name,
          );

          return state;
        }

        // それ以外であればuserIdを削除し、カウントダウン
        state.data[trunkIndex].branch[branchIndex].reactions[reactionIndex] = {
          name,
          userIds:
            state.data[trunkIndex].branch[branchIndex].reactions[
              reactionIndex
            ].userIds?.filter((id) => id !== userId) || [],
          count:
            state.data[trunkIndex].branch[branchIndex].reactions[reactionIndex]
              .count - 1 || 0,
        };
      }

      return state;
    },
    resetComments: (state) => {
      state.data = [];

      return state;
    },
    markEvaluationMemo: (
      state,
      action: PayloadAction<HasEvaluationMemoActionPayload>,
    ) => {
      const { hasEvaluationMemo } = action.payload;
      const trunkCreatedAt = new Date(action.payload.trunkCreatedAt);
      const createdAt = new Date(action.payload.createdAt);

      // trunkコメントのindexを探す
      const trunkIndex = state.data.findIndex(
        (trunk) =>
          new Date(trunk.createdAt).getTime() === trunkCreatedAt.getTime(),
      );

      // 存在しない
      if (trunkIndex < 0) {
        logger.error(
          `comment.slice. markEvaluationMemo(). trunkCreatedAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}`,
        );

        return state;
      }

      // trunkとbranchの作成日時が同じであればそれはtrunk本体。
      if (trunkCreatedAt.getTime() === createdAt.getTime()) {
        // hasEvaluationMemoを操作
        state.data[trunkIndex].hasEvaluationMemo = hasEvaluationMemo;

        return state;
      }

      // branchコメントのindexを探す
      const branchIndex = state.data[trunkIndex].branch.findIndex(
        (branch) =>
          new Date(branch.createdAt).getTime() === createdAt.getTime(),
      );

      // 存在しない
      if (branchIndex < 0) {
        logger.error(
          `comment.slice. markEvaluationMemo(). createdAtが存在しない. trunkCreatedAt: ${trunkCreatedAt.getTime()}, branch: ${createdAt.getTime()}`,
        );

        return state;
      }

      // hasEvaluationMemoを操作
      state.data[trunkIndex].branch[branchIndex].hasEvaluationMemo =
        hasEvaluationMemo;

      return state;
    },
  },
});

export const {
  deleteComment,
  updateContent,
  appendTrunkComment,
  appendBranchComment,
  loadComments,
  loadUnreadComments,
  markFavorite,
  markAdminLike,
  removeUnreadComments,
  clearAllUnreadComments,
  appendPoint,
  updatePoint,
  deletePoint,
  appendHideComment,
  updateHideComment,
  deleteHideComment,
  addReaction,
  removeReaction,
  resetComments,
  markEvaluationMemo,
} = trunkCommentSlice.actions;

export default trunkCommentSlice.reducer;
