import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { allClearLoading as allClearLoadingAction } from '@client/store/loadingOverlay/slice';

export const useClearLoadingOverlay = (pathname: string): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allClearLoadingAction());
  }, [dispatch, pathname]);
};
