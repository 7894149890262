import { Logger } from '@aws-amplify/core';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '@client/helpers/configureAppStore';
import notify from '@client/helpers/notify';
import { notifyRequestError } from '@client/helpers/notifyRequestError';
import { EventRequest } from '@client/helpers/requests/eventRequest';
import { NotificationRequest } from '@client/helpers/requests/notificationRequest';
import { Notification } from '@client/helpers/types';

import { NotificationState } from './types';

const logger = new Logger('notification/slice');

export const fetchNotifications = createAsyncThunk<
  // Return type of the payload creator
  { notifications: Notification[]; newCount: number },
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    dispatch: AppDispatch;
    rejectValue: {
      message: string;
    };
  }
>('@@notification/fetchNotifications', async (_: void, thunkAPI) => {
  const res = await NotificationRequest.fetch();

  if (!res.isSuccess) {
    // NOTE: pull通知のエラーは伝えないことにする。通知しても対応できないため。また ログイン画面リダイレクト前にエラーが出てくるため。
    // notify({ status: 'error', message: '通知の取得に失敗しました。' });

    return thunkAPI.rejectWithValue({
      message: '通知の取得に失敗しました。',
    });
  }

  return res.body;
});

export const readNotificationsEvent = createAsyncThunk<
  // Return type of the payload creator
  unknown,
  // First argument to the payload creator
  void,
  // Types for ThunkAPI
  {
    dispatch: AppDispatch;
    rejectValue: {
      message: string;
    };
  }
>('@@notification/removeNotifications', async (_: void, thunkAPI) => {
  const res = await EventRequest.readNotifications();

  if (!res.isSuccess) {
    // これは本当にnotifyする必要があるかどうか
    notifyRequestError({ status: res.notify, message: res.error });
    logger.error(res.error);

    return thunkAPI.rejectWithValue({
      message: '通知の削除に失敗しました。',
    });
  }

  return {};
});

export const initialState: NotificationState = {
  data: [],
  newCount: 0,
};

const notificationSlice = createSlice({
  name: '@@notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add some
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.data = action.payload.notifications;
      state.newCount = action.payload.newCount;

      return state;
    });
    builder.addCase(readNotificationsEvent.fulfilled, (state) => {
      state.newCount = 0;

      return state;
    });
  },
});

export default notificationSlice.reducer;
