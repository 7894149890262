
import { animated, useSpring } from 'react-spring';

import { FCC } from '@client/helpers/typeutil';

type FadeInProps = {
  className?: string;
};

export const FadeIn: FCC<FadeInProps> = ({ children, className }) => {
  const styles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  return (
    <animated.div style={styles} className={className}>
      {children}
    </animated.div>
  );
};
