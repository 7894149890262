import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LoadingOverlayState } from './types';

export const initialState: LoadingOverlayState = {
  showLoadingProgress: false,
  texts: [],
};

export interface LoadingOverlayPayload {
  id: symbol;
  text: string;
}

const loadingOverlaySlice = createSlice({
  name: '@@loadingOverlay',
  initialState,
  reducers: {
    showLoading: (state, action: PayloadAction<LoadingOverlayPayload>) => {
      state.showLoadingProgress = true;
      const index = state.texts.findIndex((t) => t.id === action.payload.id);
      // すでになければ追加
      if (index === -1) {
        state.texts.push(action.payload);
      }

      return state;
    },
    hideLoading: (state, action: PayloadAction<{ id: symbol }>) => {
      state.texts = state.texts.filter((t) => t.id !== action.payload.id);
      if (state.texts.length === 0) {
        state.showLoadingProgress = false;
      }

      return state;
    },
    allClearLoading: (state) => {
      state.showLoadingProgress = false;
      state.texts = [];

      return state;
    },
  },
});

export const { showLoading, hideLoading, allClearLoading } =
  loadingOverlaySlice.actions;

export default loadingOverlaySlice.reducer;
