import classnames from 'classnames/bind';

import { Card, CardBody } from 'reactstrap';

import { Trappy } from '@client/components/designSystems/Trappy/Trappy';

import classes from './TrappySays.module.scss';

type TrappySaysProps = React.HTMLAttributes<HTMLDivElement> & {
  size?: 'large';
  direction?: 'right' | 'top';
};

export const TrappySays: React.FC<TrappySaysProps> = ({
  className = '',
  children,
  size,
  direction = 'right',
}) => {
  const cx = classnames.bind(classes);

  if (direction === 'right') {
    return (
      <div className={cx('TrappySays', className)}>
        <Trappy size={size} />
        <Card className={classes.Balloon}>
          <CardBody>
            <span>{children}</span>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <div className={cx({ directionTop: true }, 'TrappySays', className)}>
      <Card className={classes.Balloon}>
        <CardBody>
          <span>{children}</span>
        </CardBody>
      </Card>
      <Trappy size={size} />
    </div>
  );
};
