import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { reset as resetAction } from '@client/store/currentUser/slice';

import { notifyRequestError } from '../notifyRequestError';
import { AuthRequest } from '../requests/authRequest';

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const resetCurrentUser = useCallback(() => {
    dispatch(resetAction());
  }, [dispatch]);

  const logout = useCallback(async () => {
    const res = await AuthRequest.logout(resetCurrentUser);
    if (!res.isSuccess) {
      notifyRequestError({ status: res.notify, message: res.error });

      return;
    }

    history.push('/login');
  }, []);

  return logout;
};
