import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from '@client/store/index';

const rawLoadingOverlaySelector = (state: ApplicationState) => state.loading;

export const getLoading = createSelector(
  [rawLoadingOverlaySelector],
  (loading) => loading,
);
