import { ReactNode, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Button } from 'reactstrap';

/**
 * react toastify. invoke alert message at display top right
 * ex)
 * notify({ status: 'success', message: message });
 * @param {String} status One of ['success', 'error']
 * @param {String} message notify message
 */
export const notifyRequestError = ({
  status,
  message,
  preventDuplicateId,
  logout,
}: {
  status: '<=500' | '<=400' | '401' | 'token' | 'network' | 'unknown';
  message: string;
  preventDuplicateId?: string;
  logout?: () => void;
}): void => {
  switch (status) {
    case '401':
      toast.error(
        (t) => (
          <span>
            <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
            {logout && (
              <Button
                color="primary"
                className="ml-2 mr-0"
                size="sm"
                role="presentation"
                onClick={() => {
                  logout();
                  toast.dismiss(t.id);
                }}
              >
                ログアウト
              </Button>
            )}
          </span>
        ),
        {
          id: status,
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive',
          },
        },
      );
      break;
    case '<=400':
    case '<=500':
    case 'token':
    case 'network':
    case 'unknown':
      toast.error(
        (t) => (
          <span>
            <span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
            <Button
              color="primary"
              className="ml-2 mr-0"
              size="sm"
              role="presentation"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              閉じる
            </Button>
          </span>
        ),
        {
          id: preventDuplicateId,
          ariaProps: {
            role: 'alert',
            'aria-live': 'assertive',
          },
        },
      );
      break;
    default:
      throw new Error('Notify Error: Given status is not supported.');
  }
};
